import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

import SendFriendRequestTable from "components/DataTables/SendFriendRequestTable"
import { loginUserData, userId } from "helpers/userId"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Container } from "reactstrap"

const initialFormDetails = {
  type: 0,
  searchFilter: "",
  userData: [],
  user_id: 0,
  userFullName: "",
  city: "",
  country: "",
  relationship: "Friend",
}

const SendFriendRequest = () => {
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })
  const history = useNavigate()

  const fetchUserInfo = async () => {
    try {
      let queryParams = ""
      switch (form.type) {
        case "1":
          queryParams = `user_name=${form.searchFilter}`
          break
        case "2":
          queryParams = `user_email=${form.searchFilter}`
          break
        case "3":
          queryParams = `user_key=${form.searchFilter}`
          break
        default:
          return []
      }

      let url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/user_info?${queryParams}&user_id=${userId}`
      const response = await fetch(url)
      const data = await response.json()

      return data?.data
    } catch (e) {
      showMessage(e, "error", false)
      return []
    }
  }

  const handleForm = e => {
    let { name, value } = e.target
    // setForm({...form, searchFilter: fasdfsa })
    setForm({ ...form, [name]: value })
  }

  const fetchUserRelations = async () => {
    try {
      let url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list?type=1`
      const response = await fetch(url)
      const data = await response.json()

      return data?.data || []
    } catch (e) {
      showMessage(e, "error", false)
      return []
    }
  }

  const handleFormSubmit = async () => {
    if (form.type) {
      const userData = await fetchUserInfo()
      setForm({
        ...form,
        userData,
        userFullName: "",
        city: "",
        country: "",
        user_id: 0,
      })
    }
  }

  const handleSelectedUser = async each => {
    setForm({
      ...form,
      user_id: each.user_id,
      userFullName: each.full_name,
      city: each.city,
      country: each.user_country,
      userData: [],
      type: 0,
      searchFilter: "",
      relationship: "Friend",
    })
  }

  const handleSendRequest = async () => {
    try {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/send_friend_request`

      const data = {
        by_uid: userId,
        to_uid: form.user_id,
        notify_relation: form.relationship,
        notify_type: "Friend Request",
        user_phone: form.user_phone,
        notify_name: "",
        notify_status: "No",
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})

      const sendNofication = await response.json()

      if (sendNofication?.success === true) {
        showMessage(sendNofication?.message, "success")
        setTimeout(() => {
          history("/friends-information")
        }, 3000)
      } else {
        showMessage(sendNofication?.message, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    } catch (e) {
      showMessage(e, "error", false)
      setApiStatus({ ...apiStatus, inProgress: false })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">Send Friend Request</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Send Friend Request</a>
              </li>
            </ol>
          </div>

          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <Link to="/friends-information" className="btn btn-danger my-2">
                  Back to Friend Info
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="h4 card-title">Search Friend Member</div>
                <div className="row d-flex align-items-center">
                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label htmlFor="type" className="form-label">
                        Search
                      </label>
                      <select
                        className="form-select"
                        name="type"
                        id="type"
                        required
                        onChange={handleForm}
                        value={form.type}
                      >
                        <option value={"0"}>Select Search</option>
                        <option value={"1"}>by First Name and Last Name</option>
                        <option value={"2"}>by User email</option>
                        <option value={"3"}>by User Key</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label className="form-label" htmlFor="searchFilter">
                        Search By Type
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="searchFilter"
                        id="searchFilter"
                        required
                        onChange={handleForm}
                        value={form.searchFilter}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mt-3"
                      onClick={handleFormSubmit}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {form.userFullName ? (
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="h4 card-title">Send Friend Member</div>
                  <div className="row d-flex align-items-center">
                    <div className="col-md-3">
                      <div className="mb-3 position-relative">
                        <label className="form-label" htmlFor="searchFilter">
                          Request To
                        </label>
                        <p>{form.userFullName || ""}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3 position-relative">
                        <label className="form-label" htmlFor="searchFilter">
                          Request By:
                        </label>
                        <p>
                          {loginUserData.user_fname} {loginUserData.user_lname}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mt-3"
                        onClick={handleSendRequest}
                      >
                        send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="h4 card-title m-4">List of names</div>
                  <SendFriendRequestTable
                    userData={form.userData}
                    handleSelectedUser={handleSelectedUser}
                  />
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SendFriendRequest
