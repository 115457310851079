import React from "react";
import classNames from "clsx";
import default_pic from "../../../assets/images/users/default.jpg";


import styles from "./FamilyNode.module.css";

export default function FamilyNode({
  node,
  isRoot,
  style,
}) {
  return (
    <div className={styles.root} style={style}>
      <div
        className={classNames(
          styles[node.DeathType],
          styles.inner,
          styles[node.gender],
          isRoot && styles.isRoot,
        )}
      >
        <div className={styles.content}>
          <div className={styles.name}> {node.id} </div>
        </div>
      </div>
    </div>
  );
}

