import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const ServiceListofStepsTable = ({vsl_id}) => {
  const [ServiceListofStepsTable, setServiceListofStepsTable] = useState({ data: [] })
  const searchParams = new URLSearchParams(location.search)
  const Reqid = searchParams.get("Reqid")
  

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_service_steps?vsl_id=${vsl_id}`
      )
      const data = await response.json()
      setServiceListofStepsTable({ ...ServiceListofStepsTable, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_service_steps`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        rsf_id: Reqid,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setServiceListofStepsTable({
          ...ServiceListofStepsTable,
          data: ServiceListofStepsTable?.data?.filter(each => each.rsf_id !== Reqid),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Step Name",
        field: "stepname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Service Description",
        field: "description",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ServiceListofStepsTable?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,       
        stepname: each?.step_name || "",  
        description: each?.ss_sdesc || "",     
       
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ServiceListofStepsTable
