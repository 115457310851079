import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Container, Spinner } from "reactstrap"
import "../../../../src//styles/errorfield.scss"
import { FileUpload } from "helpers/file_uploads/upload_handler"
import { loginUserData, userId } from "helpers/userId"


const initialFormDetails = {
  typeofservicelist: "",
  typeofservicelist: [],
  imagesList: [],
  ReqNo: "",
  ReqDate: "",
  Reqservice_type: "",
  ReadStatus: "Read",
  ReqMessage: "",
  ReqStatus: "Open",
  ReqSubject: "",
  ResReadStatus: "Unread",

   errors: {
    Reqservice_type: "",    
  },

}

const AddRequestService = () => {
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [errors, setErrors] = useState({})

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")

  // -- Fetch Type of Service list start -- //

  
  const handleForm = e => {
    let { name, value } = e.target       
      setForm({ ...form, [name]: value })     
  }

  {/* 
  // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, [name]: result.files?.[0]?.url })
  }
*/}

async function fetchTypeofService() {
  try {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/typeofservice_list`
  )
  const data = await response.json();
  console.log("types",form);
  // setForm({ ...form, typeofservicelist: data?.data || [] })
  console.log("types",form);
  return data?.data
  } catch (error) {
  console.log(error)
  }
}

async function getNextRequestSerivceNumber() {
  try{
    const response = await fetch( `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/last-request-serivce-number`);
    const data = await response.json();
    console.log("number",form);
    // setForm({...form, ReqNo: data?.data });
    console.log("number",form);
    return data?.data;
  }
  catch (error) {
    console.log(error)
  }
}

useEffect(() => {
  async function fetchData() {
    const [types, nextRecordNumber] = await Promise.all([fetchTypeofService(), getNextRequestSerivceNumber()]);
    setForm({ ...form, typeofservicelist: types|| [], ReqNo: nextRecordNumber || 1000 });
  }
  fetchData();
}, []);


  const handleImage = async (e, action, key) => {
    const { name, files } = e?.target || {}

    switch (action) {
      case "images":
        const fileurls = await FileUpload(files);
        const urlsList = fileurls.files.map(each => { return { file: each.url }; } )
        const list = form.imagesList.concat(urlsList);
        console.log("files");
        form.imagesList = list;
        setForm({
          ...form,
        })
        break
      default:
        null
    }
  }

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    if (!form.Reqservice_type.trim()) {
      newErrors.Reqservice_type = "Select Service Type"
      isValid = false
    }  

    setErrors(newErrors)
    // console.log(errors);
    // console.log(isValid);
    return isValid
  }

  const handleFormSubmit = async () => {
    validateForm()
    if (!!form.Reqservice_type) {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })

        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_request_service`;

        let haserrors = false            

        if (!form.Reqservice_type) {
          haserrors = true
          setErrors(prev => {
            return {
              ...prev,
              Reqservice_type: "Please Select Reqservice Type"
            }
          })
        }                 

        if (haserrors) {
          return
        }


        const data = {
          Reqid: id || 0,
          user_id: userId,
        ReqNo: form.ReqNo,
        ReqDate: form.ReqDate,
        Reqservice_type: form.Reqservice_type,
        ReadStatus: form.ReadStatus,
        ReqMessage: form.ReqMessage,
        ReqStatus: form.ReqStatus,
        ReqSubject: form.ReqSubject,
        ResReadStatus: form.ResReadStatus,
        imagesList: form.imagesList.map(each => {
          return { ...each, user_id: userId }
        }),       
                  
        }
          
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const addOrUpdateRequestService = await response.json()

        if (addOrUpdateRequestService?.success === true) {
          showMessage(addOrUpdateRequestService.message, "success")
          setTimeout(() => {              
              history("/request_services")
          }, 3000)
        } else {
          showMessage(addOrUpdateRequestService?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(e, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
          <h4 className="font-size-18">ADD REQUEST SERVICE</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Workspace</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Add Request Service</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="user_fname" className="form-label">
                    Name of the Person
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_fname"
                      id=""
                      placeholder="First Name"                      
                      onChange={handleForm}
                      value={`${loginUserData?.user_fname} ${loginUserData?.user_lname}`}
                      disabled
                    />
                   
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="ReqDate" className="form-label">
                    Date
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="ReqDate"
                      id="ReqDate"
                      Value={new Date().toISOString().split('T')[0]}                         
                      onChange={handleForm}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="Reqservice_type" className="form-label">
                    Type of Service <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="Reqservice_type"
                      name="Reqservice_type"
                      required
                      onChange={handleForm}
                      value={form.Reqservice_type}
                    >
                      <option value="" selected="">
                        Select Type of Service
                      </option>
                      {form.typeofservicelist.map(each => (
                      <option key={each.type} value={each.type}>
                        {each.type}
                      </option> 
                    ))}
                    </select>
                    {errors.Reqservice_type && (
                      <div className="invalid-input">
                        {errors.Reqservice_type}
                      </div>
                    )}
                  </div>
                </div> 


                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="ReqNo" className="form-label">
                    Request #
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="ReqNo"
                      id="ReqNo"
                      placeholder=""                      
                      onChange={handleForm}
                      value={form.ReqNo}
                      disabled
                    />
                   
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="ReadStatus" className="form-label">
                    Read Status
                    </label>
                    <select
                      className="form-select"
                      id="ReadStatus"
                      name="ReadStatus"                      
                      disabled
                      onChange={handleForm}
                      value={form.ReadStatus}                      >                        
                      <option value="Read" selected>Read</option>                       
                    </select>                     
                  </div>
                </div> 

                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="ReqStatus" className="form-label">
                    Request Status
                    </label>
                    <select
                      className="form-select"
                      id="ReqStatus"
                      name="ReqStatus"                      
                      disabled
                      onChange={handleForm}
                      value={form.ReqStatus}                      >                        
                      <option value="Open" >Open</option>  
                      <option value="Business Assigned" >Business Assigned</option>
                      <option value="Customer Rejected" >Customer Rejected</option>
                      <option value="Amount Paid" >Amount Paid</option>
                      <option value="Business Accepted" >Business Accepted</option>
                      <option value="Business Rejected">Business Rejected</option>
                      <option value="Business Reassigned" >Business Reassigned</option> 
                      <option value="In Processing" >In Processing</option> 
                      <option value="Delivered">Delivered</option> 
                      <option value="Invoiced" >Invoiced</option>                      
                    </select>                     
                  </div>
                </div> 

                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label htmlFor="ReqSubject" className="form-label">
                    Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="ReqSubject"
                      id="validationTooltip02"
                      placeholder="Subject"
                      onChange={handleForm}
                      value={form.ReqSubject}                        
                    />
                    <span className="help-inline col-xs-12 col-sm-7"></span>
                  </div>
                </div>


                <div className="col-md-12">
                  <div className="mb-3 position-relative">
                    <label htmlFor="ReqMessage" className="form-label">
                    Message
                    </label>
                    <textarea
                    className="form-control"
                    rows="5"
                    for="validationTooltip16"
                    name="ReqMessage"
                    onkeypress="check_length(this.form);"
                    onkeydown="check_length(this.form);"
                    onChange={handleForm}
                      value={form.ReqMessage}   
                    cols="30"
                  ></textarea>
                    <span className="help-inline col-xs-12 col-sm-7"></span>
                  </div>
                </div>

                <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">
                                Upload Multiple Images
                              </h4>
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="file"
                                    name="upload_images"
                                    accept="image/*"
                                    className="upload"                                                                      
                                    multiple
                                    // style={{ display: "none" }}
                                    onChange={e => handleImage(e, "images")}
                                  />                                    
                                </div>
                              </div>
                            </div>

                           
                          </div>
               

                
              </div>               
            </div>
          </div>
         

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={
                       "/request_services"
                    }
                    className="btn btn-secondary waves-effect"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddRequestService
