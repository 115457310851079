import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import "../../../../src//styles/errorfield.scss"
  import GenerateListofAssets from "components/DataTables/GenerateListofAssets"
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes

  
  
  const ListofExecutor = lazy(() =>
    import("components/Customers/GenerateWill/ListofExecutor")
  )

  const ListofGuardian = lazy(() =>
    import("components/Customers/GenerateWill/ListofGuardian")
  )
  
  const ListofSpecialRequests = lazy(() =>
    import("components/Customers/GenerateWill/ListofSpecialRequests")
  )
  
  const ListofWishes = lazy(() =>
    import("components/Customers/GenerateWill/ListofWishes")
  )
  
  const ListofHealthcare = lazy(() =>
    import("components/Customers/GenerateWill/ListofHealthcare")
  )
  
  const initialFormDetails = {    
  
    user_fname: "",
    user_mname: "",
    user_lname: "",
    user_email: "",
    user_phone: "",
    user_address: "",
    is_address: "Yes",
    is_alive: "Yes",
    user_country: "",
    user_zip: "",
    user_birthdate: "",
    relationship: "",
    relationsList: [],
    regionsList: [],
    addres1: "",
    addres2: "",
    city: "",
    region: "",
    ac_image: "",

    relationsList: [],
    regionsList: [],      
    ListofExecutor: [],
    ListofGuardian: [],
    ListofHealthcare : [],
    ListofSpecialRequests: [],
    ListofWishes: [],
    ListOfAssets:[],

    ListOfExistingExecutor: [],
    ListOfExistingGuardian: [],
    ListofSelectedAssets: [],
    
    errors: {
      pro_asset_name: "",
      pro_property_type: "",
      pro_asset_value: "",
      pro_county_asset: "",
      pro_asset_value_currency: "",
    },
  }
  
  const AddGenerateWill = () => { 
  
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
  
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const relationsList = JSON.parse(localStorage.getItem("relationsList"))
  
    const validateForm = () => {
      var isValid = true
      const newErrors = {}
  
      // Validation logic for each input
      if (!form.user_fname.trim()) {
        newErrors.user_fname = "First name is required"
        isValid = false
      }
  
      if (!form.user_lname.trim()) {
        newErrors.user_lname = "Last name is required"
        isValid = false
      }
  
      setErrors(newErrors)
      // console.log(errors);
      // console.log(isValid);
      return isValid
    }
  
     // -- Fetch relationships list start -- //
    async function fetchRelationsList() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
        )
        const data = await response.json()
  
        if (data?.data) {
          localStorage.setItem("relationsList", JSON.stringify(data?.data))
        }
  
        return data?.data
      } catch (error) {
        console.log(error)
      }
    }
  
    async function fetchRegionsList(countryName, type) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        )
  
        const data = await response.json()
        if (type != 1) {
          setForm({
            ...form,
            realEstateRegionList: data?.data || [],
            pre_region: data.data[0]?.region || "",
            pre_country: countryName,
          })
        }
  
        return data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
     
  
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
    // const GId = parseInt(JSON.parse(localStorage.getItem("GId") || ""));
    const GIdString = localStorage.getItem("GId");
 
    const GId = GIdString ? parseInt(GIdString) : undefined;
    
  
    async function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 7) {
          setactiveTab(tab)
          localStorage.setItem("tab", tab)
  
          if (activeTab == 5) {
            await fetchListOfWishes()
          }
        }
      }
    }
  
   {/* */}
  
    useEffect(() => {    
        if (activeTab === 5) {
          setTimeout(() => {
            fetchListOfWishes()
          }, [500])
        }
  
        if (activeTab === 6) {
          setTimeout(() => {
            fetchListOfHealthcare()
          }, [500])
        }
      
    }, [activeTab])   
  
    useEffect(() => {
      fetchRelationsList()
    }, []);

    useEffect(() => {

      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_generate_asset_info?user_id=${userId}`
        )
  
        const data = await response.json();
        setForm({
          ...form,
          ListOfAssets: data?.data || [],
        })
      }
      if(activeTab == 1){
        fetchData();
      }
    },[activeTab])
   
   
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "assetInformation":
          setForm({
            ...form,
            [name]: type == "checkbox" ? checked : value,
            errors: { ...form.errors, [name]: value?.trim() ? "" : "Required" },
          })
  
          break
       
        case "addListofGuardian":
        
          let latestNumber = 2
          if (form.ListofGuardian.length) {
            let numberList = form.ListofGuardian.map(each => each.number)
  
            latestNumber = Math.max(...numberList) + 1
          }
          form.ListofGuardian.push({
            number: form.ListofGuardian.length ? latestNumber : 1,
            relationsList: relationsList,
          })
          setForm({ ...form, ListofGuardian: form.ListofGuardian })
          break 
        case "removeListofGuardian":
          const modifiedList = form.ListofGuardian.filter(
            (each, idx) => idx !== key
          )
          setForm({ ...form, ListofGuardian: modifiedList })
          break
        case "guardianList":
          const modfiedListOfTestator = []
  
          for (let i = 0; i < form.ListofGuardian.length; i++) {
            const Testator = {
              ...form.ListofGuardian[i],
            }
  
            if (i === key) {
              let regionsList = Testator.regionsList || []
              if (name === "gcil_country") {
                regionsList =  await fetchRegionsList(value) 
                Testator.gcil_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfTestator.push({
                ...Testator,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfTestator.push(Testator)
            }
          }
  
          setForm({
            ...form,
            ListofGuardian: modfiedListOfTestator,
          })
          break  
            
        case "addListofExecutor":
          
          let latestNumberassetex = 2
          if (form.ListofExecutor.length) {
            let numberList = form.ListofExecutor.map(each => each.number)
  
            latestNumberassetex = Math.max(...numberList) + 1
          }
          form.ListofExecutor.push({
            number: form.ListofExecutor.length ? latestNumberassetex : 1,
            relationsList: relationsList,
          })
          setForm({ ...form, ListofExecutor: form.ListofExecutor })
          break 
        case "removeListofExecutor":
          const modifiedListassetex = form.ListofExecutor.filter(
            (each, idx) => idx !== key
          )
          setForm({ ...form, ListofExecutor: modifiedListassetex })
          break
        case "executerList":
          const modfiedListOfExecutor = []
  
          for (let i = 0; i < form.ListofExecutor.length; i++) {
            const Testator = {
              ...form.ListofExecutor[i],
            }
  
            if (i === key) {
              let regionsList = Testator.regionsList || []
              if (name === "gcil_country") {
                regionsList =  await fetchRegionsList(value) 
                Testator.gcil_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfExecutor.push({
                ...Testator,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfExecutor.push(Testator)
            }
          }
  
          setForm({
            ...form,
            ListofExecutor: modfiedListOfExecutor,
          })
        break  
    
        case "addListofSpecialRequests":
            let latestNumbers = 2
            if (form.ListofSpecialRequests.length) {
              let numberList = form.ListofSpecialRequests.map(each => each.number)
    
              latestNumbers = Math.max(...numberList) + 1
            }
            form.ListofSpecialRequests.push({
              number: form.ListofSpecialRequests.length ? latestNumbers : 1,
              relationsList: relationsList,
            })
            setForm({ ...form, ListofSpecialRequests: form.ListofSpecialRequests })
            break
        case "removeListofSpecialRequests":
            const modifiedLists = form.ListofSpecialRequests.find(
              (each, idx) => idx === key
            )
            handleRemoveSpecialRequests(modifiedLists?.gsrl_id || 0, key)
            break
        case "specialrequests":
          setForm({
            ...form,
            ListofSpecialRequests: form.ListofSpecialRequests.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: value,
                }
              }
  
              return each
            }),
          })
          break


        case "addListofWishes":
            let latestNumberss = 2
            if (form.ListofWishes.length) {
              let numberWList = form.ListofWishes.map(each => each.number)
    
              latestNumberss = Math.max(...numberWList) + 1
            }
            form.ListofWishes.push({
              number: form.ListofWishes.length ? latestNumberss : 1,
            })
            setForm({ ...form, ListofWishes: form.ListofWishes })
            break
        case "removeListofWishes":
            const modifiedListss = form.ListofWishes.filter(
              (each, idx) => idx === key
            )
            setForm({ ...form, ListofWishes: ListofWishes })
            handleRemoveWishes(modifiedListss?.gf_id || 0, key);
            break
        case "wisheslist":
          setForm({
            ...form,
            ListofWishes: form.ListofWishes.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: value,
                }
              }
  
              return each
            }),
          })
          break

        case "addListofHealthcare":
            let latestNumberscare = 2
            if (form.ListofHealthcare.length) {
            let numberList = form.ListofHealthcare.map(each => each.number)
    
            latestNumberscare = Math.max(...numberList) + 1
            }
            form.ListofHealthcare.push({
            number: form.ListofHealthcare.length ? latestNumberscare : 1,
            relationsList: relationsList,
            })
            setForm({ ...form, ListofHealthcare: form.ListofHealthcare })
            break
        case "removeListofHealthcare":
            const modifiedListscare = form.ListofHealthcare.find(
            (each, idx) => idx === key
            )
            handleRemoveHealthcare(modifiedListscare?.ghl_id || 0, key)
            break
        case "healthcare":
          setForm({
            ...form,
            ListofHealthcare: form.ListofHealthcare.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: value,
                }
              }
  
              return each
            }),
          })
          break     
          case "ListOfAssests":
            let assets =form.ListofSelectedAssets;
            if(assets.indexOf(key) > - 1) {
              assets = _.remove(assets,(i) => i == key);
            }else{
              assets = [...assets, key];
            }
            setForm({
              ...form,
              ListofSelectedAssets: assets
            });
          break;
          case "witnessInfo":
            setForm({ ...form, [name]: value })
        break

       default:
          null
      }
    }  
    

    const handleFormSubmit = async () => {
      switch (activeTab) {
        case 1:
            try {
              setApiStatus({ ...apiStatus, inProgress: true })
              const url = id
                ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_asset_information`
                : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_generare_asset_info`
  
  
              const data = {              
                gal_id: id || GId,
                gal_uid: userId,
                assets: form.ListofSelectedAssets,
              }
  
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
  
              setApiStatus({ ...apiStatus, inProgress: true })
  
              const response = await fetch(url, options, {})
  
              const addOrUpdateAssetInformation = await response.json()
  
              if (addOrUpdateAssetInformation?.success === true) {
                setApiStatus({ ...apiStatus, inProgress: false })
                setactiveTab(2)
                localStorage.setItem("tab", 2)
                localStorage.setItem(
                  "GId",
                  addOrUpdateAssetInformation.GId
                )
                showMessage(addOrUpdateAssetInformation.message, "success")
              } else {
                showMessage(addOrUpdateAssetInformation?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
              }
            } catch (error) {}
          break
        case 2:  
        try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_generate_executor_info`
    
            const data = {
              gcil_uid: userId,
              gal_id: id || GId,
              ListofExecutor: form.ListofExecutor,
            }
    
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
    
            const updateExecutorList = await response.json()
    
            if (updateExecutorList?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
             
              showMessage(updateExecutorList.message, "success")
              setTimeout(() => {
                setForm({ ...form, ListofExecutor: [] })
              }, 500)
            } else {
              showMessage(updateExecutorList?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}      
            break
        case 3:
        try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_generate_guardian_info`
    
            const data = {
                gcil_uid: userId,
                gal_id: id || GId,
                ListofGuardian: form.ListofGuardian,
            }
    
            const options = {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
    
            const updateGuardianList = await response.json()
    
            if (updateGuardianList?.success === true) {
                setApiStatus({ ...apiStatus, inProgress: false })
                setactiveTab(4)
                localStorage.setItem("tab", 4)
               
                showMessage(updateGuardianList.message, "success")
                setTimeout(() => {
                setForm({ ...form, ListofGuardian: [] })
                }, 500)
            } else {
                showMessage(updateGuardianList?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
            }
            } catch (error) {}
            break 
        case 4:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_special_request`
            
            const data = {
              gal_id: id || GId,
              uid: userId,
              ListofSpecialRequests: form.ListofSpecialRequests.map(
                eachSpecialRequests => {
                  return {
                    gsrl_id: eachSpecialRequests.gsrl_id || 0,
                    gsrl_requests: eachSpecialRequests.gsrl_requests || "",                    
                  }
                }
              ),
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            const response = await fetch(url, options, {})
  
            const addOrUpdateListOfSpecialRequests = await response.json()
  
            if (addOrUpdateListOfSpecialRequests?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              showMessage(addOrUpdateListOfSpecialRequests.message, "success")
              // setTimeout(() => {
              //   history("/asset-wallet")
              // }, 3000)
              setactiveTab(activeTab + 1)
              localStorage.setItem("tab", activeTab + 1)
            } else {
              showMessage(addOrUpdateListOfSpecialRequests?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break
        case 5:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_wishe`
            
            const data = {
              gal_id: id || GId,
              uid: userId,
              ListofWishes: form.ListofWishes.map(
                eachWishes => {
                  return {
                    gf_id: eachWishes.gf_id || 0,
                    gf_wishes: eachWishes.gf_wishes || "",                    
                  }
                }
              ),
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            const response = await fetch(url, options, {})
  
            const addOrUpdateListOfWishes = await response.json()
  
            if (addOrUpdateListOfWishes?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              showMessage(addOrUpdateListOfWishes.message, "success")
              // setTimeout(() => {
              //   history("/asset-wallet")
              // }, 3000)
              setactiveTab(activeTab + 1)
              localStorage.setItem("tab", activeTab + 1)
            } else {
              showMessage(addOrUpdateListOfWishes?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break
        case 6:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_healthcare`
  
            const data = {
              gal_id: id || GId,
              uid: userId,
              ListofHealthcare: form.ListofHealthcare.map(eachDocument => {
                return {
                  ghl_id: eachDocument.ghl_id || 0,
                  ghl_fullname: eachDocument.ghl_fullname || "",
                  ghl_address: eachDocument.ghl_address || "",
                  ghl_contact: eachDocument.ghl_contact || "",
                  ghl_instructions: eachDocument.ghl_instructions || "",
                }
              }),
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            const response = await fetch(url, options, {})
  
            const addOrUpdateListofHealthcare = await response.json()
  
            if (addOrUpdateListofHealthcare?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              showMessage(addOrUpdateListofHealthcare.message, "success")
              // setTimeout(() => {
              //   history("/asset-wallet")
              // }, 3000)
              setactiveTab(activeTab + 1)
              localStorage.setItem("tab", activeTab + 1)
            } else {
              showMessage(addOrUpdateListofHealthcare?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break
        case 7:
          try {
          
            const url = id
              ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_generatewitness`
              : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_generatewitness`  
            
            const data = {              
              gal_id: id || GId,
              uid: userId,
              fullname1: form.fullname1,
              address1: form.address1,
              City1: form.City1,              
              State1: form.State1,
              contact1: form.contact1,
              email1: form.email1, 
              fullname2: form.fullname2,
              address2: form.address2,
              City2: form.City2,              
              State2: form.State2,
              contact2: form.contact2,
              email2: form.email2,             
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            setApiStatus({ ...apiStatus, inProgress: true })
  
            const response = await fetch(url, options, {})
  
            const addOrUpdatewitnessInformation = await response.json()
  
            if (addOrUpdatewitnessInformation?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })                         
              showMessage(addOrUpdatewitnessInformation.message, "success")
              setTimeout(() => {
                history("/generated_will_documet_list")
              }, 3000)
            } else {
              showMessage(addOrUpdatewitnessInformation?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break
        default:
          break
      }
  
      if (!(id || GId) && activeTab != 1) {
        setactiveTab(1)
        localStorage.setItem("tab", 1)
      }
    }
  
    const handleBlur = e => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value?.trim(),
        errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
      })
    }
    useEffect(() => {
      if (changed) {
        setErrors(prev => {
          const copy = JSON.parse(JSON.stringify(prev))
          delete copy[changed]
          return copy
        })
      }
    }, [changed])  // changed

    async function fetchSpecialRequestsa() {
        try {
          const url = `${
            process.env.REACT_APP_DOMAIN_URL
          }api/v1/customer/services/specialrequest_list?gal_id=${id || GId}&uid=${userId}`
    
          const response = await fetch(url)
          const data = await response.json()
          const decryptedData = data?.data?.map((each, idx) => {
            return {
              ...each,
              number: idx + 1,
              gsrl_requests: each.gsrl_requests || "",              
            }
          })
    
          setForm({
            ...form,
            ListofSpecialRequests: decryptedData || [],            
          })
        } catch (err) {}
      } 
      
      const handleRemoveSpecialRequests = async (gsrl_id, idx) => {
        try {
          if (gsrl_id) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_special_request`
            const data = {
              gsrl_id: gsrl_id,
            }
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
            const removeData = await response.json()
    
            if (removeData?.success === true) {
              showMessage(removeData.message, "success")
              setForm({
                ...form,
                ListofSpecialRequests: form.ListofSpecialRequests.filter(
                  each => each.gsrl_id !== gsrl_id
                ),
              })
            } else {
              showMessage(removeData.message, "error", false)
            }
          } else if (idx >= 0) {
            setForm({
              ...form,
              ListofSpecialRequests: form.ListofSpecialRequests.filter(
                (each, index) => index != idx
              ),
            })
          }
        } catch (error) {
          showMessage(removeData?.message || "Something went wrong", "error", false)
        }
      }

      useEffect(() => {
        if (activeTab == 4) {
          fetchSpecialRequestsa()
        }
      }, [])
    
      async function fetchListOfWishes() {
        try {
          const url = `${
            process.env.REACT_APP_DOMAIN_URL
          }api/v1/customer/services/wishe_list?gal_id=${id || GId}&uid=${userId}`
    
          const response = await fetch(url)
          const data = await response.json()
          const decryptedData = data?.data;
          setForm({
            ...form,
            ListofWishes: decryptedData || [],
          })
        } catch (err) {}
      }
    
      const handleRemoveWishes = async (gf_id, idx) => {
        try {
          if (gf_id) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_wishe`
            const data = {
              gf_id: gf_id,
            }
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
            const removeData = await response.json()
    
            if (removeData?.success === true) {
              showMessage(removeData.message, "success")
              setForm({
                ...form,
                ListofWishes: form.ListofWishes.filter(
                  each => each.gf_id !== gf_id
                ),
              })
            } else {
              showMessage(removeData.message, "error", false)
            }
          } else if (idx >= 0) {
            setForm({
              ...form,
              ListofWishes: form.ListofWishes.filter(
                (each, index) => index != idx
              ),
            })
          }
        } catch (error) {
          showMessage(removeData?.message || "Something went wrong", "error", false)
        }
      }

      useEffect(() => {
        if (activeTab == 5) {
          fetchListOfWishes()
        }
      }, [])
    

      async function fetchListOfHealthcare() {
        try {
          const url = `${
            process.env.REACT_APP_DOMAIN_URL
          }api/v1/customer/services/healthcare_list?gal_id=${id || GId}&uid=${userId}`
    
          const response = await fetch(url)
          const data = await response.json()
          const decryptedData = data?.data;
          setForm({
            ...form,
            ListofHealthcare: decryptedData || [],
          })
        } catch (err) {}
      }
    
      const handleRemoveHealthcare = async (ghl_id, idx) => {
        try {
          if (ghl_id) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_helath_care`
            const data = {
              ghl_id: ghl_id,
            }
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
            const removeHCData = await response.json()
    
            if (removeHCData?.success === true) {
              showMessage(removeHCData.message, "success")
              setForm({
                ...form,
                ListofHealthcare: form.ListofHealthcare.filter(
                  each => each.ghl_id !== ghl_id
                ),
              })
            } else {
              showMessage(removeHCData.message, "error", false)
            }
          } else if (ghl_id >= 0) {
            setForm({
              ...form,
              ListofHealthcare: form.ListofHealthcare.filter(
                (each, index) => index != idx
              ),
            })
          }
        } catch (error) {
          showMessage(removeHCData?.message || "Something went wrong", "error", false)
        }
      }

      useEffect(() => {
        if (activeTab == 6) {
          fetchListOfHealthcare()
        }
      }, [])
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">Generate Will</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Services</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/asset-wallet">Add Generate Will</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                                localStorage.setItem("tab", 1)
                              }}
                            >
                              <span className="number">1.</span> Asset List
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                 setactiveTab(2)
                                localStorage.setItem("tab", 2)
                              }}
                            >
                              <span className="number">2.</span> Executor Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                 setactiveTab(3)
                                localStorage.setItem("tab", 3)
                              }}
                            >
                              <span className="number">3.</span>
                              Guardian for kids
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={async () => {
                                setactiveTab(4)
                                localStorage.setItem("tab", 4)
                                await fetchSpecialRequestsa()
                              }}
                            >
                              <span className="number">4.</span>
                              Special Requests
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 5 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 5 })}
                              onClick={async () => {
                                setactiveTab(5)
                                localStorage.setItem("tab", 5)
                                await fetchListOfWishes()
                              }}
                            >
                              <span className="number">5.</span>
                              Funeral & Burial Wishes
                            </NavLink>
                          </NavItem>
  
                          <NavItem
                            className={classnames({ current: activeTab === 6 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 6 })}
                              onClick={async () => {
                                setactiveTab(6)
                                localStorage.setItem("tab", 6)
                                await fetchListOfHealthcare()
                              }}
                            >
                              <span className="number">6.</span>
                              Health Directive /Healthcare Proxy
                            </NavLink>
                          </NavItem>
  
                          <NavItem
                            className={classnames({ current: activeTab === 7 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 7 })}
                              onClick={() => {
                                 setactiveTab(7)
                                localStorage.setItem("tab", 7)
                              }}
                            >
                              <span className="number">7.</span>
                              Witness
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {/**4,5,6 dynamic forms and tables */}
                          <TabPane tabId={1}>
                            <Form>
                              
                              <h4 className="page-title-box">
                                Asset Wallet list
                              </h4>
                               <div className="card">
                                    <GenerateListofAssets
                                        form={form}
                                        handleForm={handleForm}
                                        activeTab={activeTab}
                                        />
                                  
                                </div>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                                <Suspense
                                    fallback={
                                        <div className="text-center m-4">Loading...</div>
                                    }
                                    >
                                    <h4 className="page-title-box">List of Executor Names</h4>
        
                                    <ListofExecutor
                                        form={form}
                                        handleForm={handleForm}                                       
                                        GId={id || GId}
                                        activeTab={activeTab}
                                    />
                                </Suspense>
                          </TabPane>
                          <TabPane tabId={3}>
                            <Suspense
                                fallback={
                                    <div className="text-center m-4">Loading...</div>
                                }
                                >
                                <h4 className="page-title-box">List of Guardian for kids</h4>
    
                                <ListofGuardian
                                    form={form}
                                    handleForm={handleForm}                                    
                                    galid={id || GId}
                                    activeTab={activeTab}
                                />
                            </Suspense>
                          </TabPane>
                          <TabPane tabId={4}>
                                <Suspense
                                    fallback={
                                        <div className="text-center m-4">Loading...</div>
                                    }
                                    >
                                    <h4 className="page-title-box">List of Special Requests</h4>
        
                                    <ListofSpecialRequests
                                        form={form}
                                        handleForm={handleForm}                                        
                                        gal_id={id || GId}
                                        activeTab={activeTab}
                                    />
                                </Suspense>
                          </TabPane>
  
                          <TabPane tabId={5}>
                               <Suspense
                                    fallback={
                                        <div className="text-center m-4">Loading...</div>
                                    }
                                    >
                                    <h4 className="page-title-box">Instructions</h4>
        
                                    <ListofWishes
                                        form={form}
                                        handleForm={handleForm}                                        
                                        gal_id={id || GId}
                                        activeTab={activeTab}
                                    />
                                </Suspense>
                          </TabPane>
  
                          <TabPane tabId={6}>
                               <Suspense
                                    fallback={
                                        <div className="text-center m-4">Loading...</div>
                                    }
                                    >
                                    <h4 className="page-title-box">Health Directive /Healthcare Proxy</h4>
        
                                    <ListofHealthcare
                                        form={form}
                                        handleForm={handleForm}                                        
                                        gal_id={id || GId}
                                        activeTab={activeTab}
                                    />
                                </Suspense>
                          </TabPane>
  
                          <TabPane tabId={7}>

                          <Form>
                              
                              <h4 className="page-title-box">
                              Witness list
                              </h4>
                              <div className="row"> 

                              <h5 className="page-title-box">
                              Witness 1#
                              </h5>
                              

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Full Name                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fullname1"
                                      id="validationTooltip02"
                                      placeholder="Full Name"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.fullname}
                                    />                                 
                                  </div>
                                </div>   

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Address                                     
                                    </label>
                                    <textarea
                                        id="validationTooltip01"
                                        name="address1"
                                        rows="3"
                                        className="form-control"                        
                                        onChange={e =>
                                          handleForm(e, "witnessInfo")
                                        }
                                        value={form.address}
                                    ></textarea>                                                                    
                                  </div>
                                </div> 

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      City                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="City1"
                                      id="validationTooltip02"
                                      placeholder="City"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.City}
                                    />                                 
                                  </div>
                                </div> 

                                 <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      State                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="State1"
                                      id="validationTooltip02"
                                      placeholder="State"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.State}
                                    />                                 
                                  </div>
                                </div> 

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Contact                                      
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="contact1"
                                      id="validationTooltip02"
                                      placeholder="contact"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.contact}
                                    />                                 
                                  </div>
                                </div> 

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Email                                      
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email1"
                                      id="validationTooltip02"
                                      placeholder="email"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.email}
                                    />                                 
                                  </div>
                                </div>     




                              <h5 className="page-title-box">
                              Witness 2#
                              </h5>
                              

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Full Name                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fullname2"
                                      id="validationTooltip02"
                                      placeholder="Full Name"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.fullname}
                                    />                                 
                                  </div>
                                </div>   

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Address                                     
                                    </label>
                                    <textarea
                                        id="validationTooltip01"
                                        name="address2"
                                        rows="3"
                                        className="form-control"                        
                                        onChange={e =>
                                          handleForm(e, "witnessInfo")
                                        }
                                        value={form.address}
                                    ></textarea>                                                                    
                                  </div>
                                </div> 

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      City                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="City2"
                                      id="validationTooltip02"
                                      placeholder="City"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.City}
                                    />                                 
                                  </div>
                                </div> 

                                 <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      State                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="State2"
                                      id="validationTooltip02"
                                      placeholder="State"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.State}
                                    />                                 
                                  </div>
                                </div> 

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Contact                                      
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="contact2"
                                      id="validationTooltip02"
                                      placeholder="contact"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.contact}
                                    />                                 
                                  </div>
                                </div> 

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Email                                      
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email2"
                                      id="validationTooltip02"
                                      placeholder="email"
                                      required
                                      onChange={e =>
                                        handleForm(e, "witnessInfo")
                                      }
                                      value={form.email}
                                    />                                 
                                  </div>
                                </div>            

                              


                                    
                                  
                              </div>
                            </Form>
                            
                            
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              type="button"
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                              onClick={() => {
                               // activeTab != handleFormSubmit()
                                toggleTab(activeTab - 1)
                              }}
                              disabled={activeTab === 1}
                            >
                              Previous
                            </Button>
                          </li>
                          <li
                            className={activeTab === 7 ? "next disabled" : "next"}
                          >
                            <Button
                              disabled={activeTab === 7}
                              type="button"
                              onClick={() => {
                                activeTab != handleFormSubmit()
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {GId || id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={"/will-list"}
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        localStorage.removeItem("GId")
                        localStorage.removeItem("tab")
                      }}
                    >
                      Cancel
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddGenerateWill
  