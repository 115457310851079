import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import "../../../../src//styles/errorfield.scss"
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { randomId } from "helpers/randomGen"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
  const ListOfOwners = lazy(() =>
    import("components/Customers/BusinessPartner/ListOfOwners")
  )

  const ListOfService = lazy(() =>
    import("components/Customers/BusinessPartner/ListOfService")
  )
  
  
  const initialFormDetails = {
    uid: "",
    Bus_Name: "",
    EIN_Number: "",
    Bus_Website: "",
    Bus_Leg_Struc: "",
    BusType: "",
    BusKey: "",
    EmailID: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Country: "", 
    Region: "", 
    Zip: "",
    bp_logo: "",
               
    scode: "",
    sname: "",
    ServiceType: "",       
    descrip: "",
    amount: "",
    sofamount: "",
    subtype: "",
    Features: "",
    PackageDetails: "",
    chargecustomer: "",
    Currency: "",

    RegionList: [],

    user_fname: "",
    user_mname: "",
    user_lname: "",
    user_email: "",
    user_phone: "",   
    CDesignation: "",
    user_country: "",
    user_zip: "",    
    regionsList: [],
    addres1: "",
    addres2: "",
    city: "",
    region: "",    
  
      
    ListOfOwners: [],
    ListOfPrice: [],
    ListOfStep: [],
    ListofDocuments: [],

    ListOfService: [],
    
  
    BpListOfExistingOwners: [],
    BpListOfExistingService: [],
   

   // BusKey: `LIF${randomId(6)}`,
          
  }
  
  const AddBusinessPartner = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const relationsList = JSON.parse(localStorage.getItem("relationsList"))
  
    const validateForm = () => {
      var isValid = true
      const newErrors = {}
  
      // Validation logic for each input
      if (!form.user_fname.trim()) {
        newErrors.user_fname = "First name is required"
        isValid = false
      }
  
      if (!form.user_lname.trim()) {
        newErrors.user_lname = "Last name is required"
        isValid = false
      }
  
      setErrors(newErrors)
      // console.log(errors);
      // console.log(isValid);
      return isValid
    }
  
   
  
    async function fetchRegionsList(countryName, type) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        )
  
        const data = await response.json()
        if (type != 1) {
          setForm({
            ...form,
            RegionList: data?.data || [],
            Region: data.data[0]?.region || "",
            Country: countryName,
          })
        }
  
        return data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  
    
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
    const bpIdString = localStorage.getItem("bpId");
    const bpId = bpIdString ? parseInt(bpIdString) : undefined;
  
    async function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 6) {
          setactiveTab(tab)
          localStorage.setItem("tab", tab)
  
         
        }
      }
    }
 
  
   
  
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const decryptedData = data?.data[0]
            if (decryptedData) {
              
              const assetInformation = {
                ...decryptedData,
                Bus_Name: decryptedData.Bus_Name,
                EIN_Number: decryptedData.EIN_Number,
                Bus_Website: decryptedData.Bus_Website,
                Bus_Leg_Struc: decryptedData.Bus_Leg_Struc,
                BusType: decryptedData.BusType,
                BusKey: decryptedData.BusKey,
                EmailID: decryptedData.EmailID,
                Phone: decryptedData.Phone,
                Street1: decryptedData.Street1,
                Street2: decryptedData.Street2,
                City: decryptedData.City,
                Country: decryptedData.Country,
                Region: decryptedData.Region,
                Zip: decryptedData.Zip,
                bp_logo: decryptedData.bp_logo,
               
              }
              setForm({
                ...form,
                ...assetInformation,
                RegionList: await fetchRegionsList(
                  assetInformation.Country,
                  1
                ),
                
              })
            }
          }
  
          return true
        }
        if (bpId && !id) {
          try {
            const fetchData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_business_info?vil_id=${bpId}`
              )
  
              const data = await response.json()
              await setFormData(data)
            }
  
            fetchData()
          } catch (e) {}
        }
  
        if (id) {
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_business_info?vil_id=${id}`
              )
  
              const data = await response.json()
              setFormData(data)
            }
  
            fetchDataEditData()
          } catch (e) {}
        }
      }
    }, [activeTab])

    const getBusinessKey = (businessType) => {
      const randoomKey = randomId(2);
      if (businessType == 'Attorney') {
        return `ATTCT${randoomKey}`;
      }
      if (businessType == 'Financial Advisor') {
        return `FADCT${randoomKey}`;
      }
      if (businessType == 'Insurance Agent') {
        return `IAGCT${randoomKey}`;
      }
      if (businessType == 'Laywer') {
        return `LAWCT${randoomKey}`;
      }
      return `RLTCT${randoomKey}`;
    }
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "businessInfo":
            if (name === "Country") {
                fetchRegionsList(value)
              } else if( name === "BusType") {
                setForm({
                  ...form,
                  [name]: value,
                  ['BusKey']: getBusinessKey(value)
                })
              } else{

                setForm({
                  ...form,
                  [name]: value,
                })
              }
  
          break
        
       
        case "addListOfOwner":
          let latestNumber = 2
          if (form.ListOfOwners.length) {
            let numberList = form.ListOfOwners.map(each => each.number)
  
            latestNumber = Math.max(...numberList) + 1
          }
          form.ListOfOwners.push({
            number: form.ListOfOwners.length ? latestNumber : 1,
            relationsList: relationsList,
          })
          setForm({ ...form, ListOfOwners: form.ListOfOwners })
          break
        case "ownersList":
          const modfiedListOfOwners = []
  
          for (let i = 0; i < form.ListOfOwners.length; i++) {
            const owner = {
              ...form.ListOfOwners[i],
            }
  
            if (i === key) {
              let regionsList = owner.regionsList || []
              if (name === "pol_country") {
                regionsList = await fetchRegionsList(value)
                owner.pol_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfOwners.push({
                ...owner,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfOwners.push(owner)
            }
          }
  
          setForm({
            ...form,
            ListOfOwners: modfiedListOfOwners,
          })
          break
        case "removeListOfOwners":
          const modifiedList = form.ListOfOwners.filter(
            (each, idx) => idx !== key
          )
          setForm({ ...form, ListOfOwners: modifiedList })
          break
       
       
        case "addListOfService":
          let latestNumber1 = 2
          if (form.ListOfService.length) {
            let numberList = form.ListOfService.map(each => each.number)
  
            latestNumber1 = Math.max(...numberList) + 1
          }
          form.ListOfService.push({
            number: form.ListOfService.length ? latestNumber1 : 1,
            relationsList: relationsList,
          })
          setForm({ ...form, ListOfService: form.ListOfService })
          break
        case "vendorService":
          const modfiedListOfService = []
  
          for (let i = 0; i < form.ListOfService.length; i++) {
            const owner = {
              ...form.ListOfService[i],
            }
  
            if (i === key) {
              let regionsList = owner.regionsList || []
              if (name === "pol_country") {
                regionsList = await fetchRegionsList(value)
                owner.pol_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfService.push({
                ...owner,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfService.push(owner)
            }
          }
  
          setForm({
            ...form,
            ListOfService: modfiedListOfService,
          })
          break
        case "removeListOfService":
          const modifiedList1 = form.ListOfService.filter(
            (each, idx) => idx !== key
          )
          setForm({ ...form, ListOfService: modifiedList1 })
          break    
  
       
        default:
          null
      }
    }
  
      // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, bp_logo: result.files?.[0]?.url })
  }
    
    const handleFormSubmit = async () => {
      switch (activeTab) {
        case 1:
          if (form.Bus_Name) {
  
            
            try {
              setApiStatus({ ...apiStatus, inProgress: true })
              const url = id
                ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_business_partner`
                : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/business_partner`
  
              let haserrors = false
              
              if (!form.EIN_Number) {
                haserrors = true
                setErrors(prev => {
                  return {
                    ...prev,
                    EIN_Number: "Please enter Number"
                  }
                })
              }
  
              if (!form.BusType) {
                haserrors = true
                setErrors(prev => {
                  return {
                    ...prev,
                    BusType: "Please select Business Type"
                  }
                })
                
              }
    
              if (haserrors) {
                return
              }
  
                
              const data = {              
                vil_id: id || bpId,                
                Bus_Name: form.Bus_Name,
                BusKey: form.BusKey,
                BusType: form.BusType,                
                EIN_Number: form.EIN_Number,
                Bus_Leg_Struc: form.Bus_Leg_Struc,
                EmailID: form.EmailID,
                Phone: form.Phone,
                Bus_Website: form.Bus_Website,
                Street1: form.Street1,
                Street2: form.Street2,
                City: form.City,
                Country: form.Country,
                Region: form.Region,
                Zip: form.Zip,
                bp_logo: form.bp_logo,
              }
  
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
  
              setApiStatus({ ...apiStatus, inProgress: true })
  
              const response = await fetch(url, options, {})
  
              const addOrUpdateBusinessInformation = await response.json()
  
              if (addOrUpdateBusinessInformation?.success === true) {
                setApiStatus({ ...apiStatus, inProgress: false })
                setactiveTab(2)
                localStorage.setItem("tab", 2)
                localStorage.setItem(
                  "bpId",
                  addOrUpdateBusinessInformation.bpId
                )
                showMessage(addOrUpdateBusinessInformation.message, "success")
              } else {
                showMessage(addOrUpdateBusinessInformation?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
              }
            } catch (error) {}
          }
          else {
            setErrors(prev => {
              return {
                ...prev,
                Bus_Name: "Please enter Business Name"
              }
            })
          }
          break
        case 2:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/bp_update_owners_info`
    
            const data = {              
              vil_vid: id || bpId,
              ListOfOwners: form.ListOfOwners,
            }
    
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
    
            const updateOwnersList = await response.json()
    
            if (updateOwnersList?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              //await fetchBeneficiaryData()
              showMessage(updateOwnersList.message, "success")
              setTimeout(() => {
                setForm({ ...form, ListOfOwners: [] })
              }, 500)
            } else {
              showMessage(updateOwnersList?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break 
        case 3:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_business_sevice`
    
            const data = {              
              vil_vid: id || bpId,
              ListOfService: form.ListOfService,
            }
    
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
    
            const updateServiceList = await response.json()
    
            if (updateServiceList?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              //await fetchBeneficiaryData()
              showMessage(updateServiceList.message, "success")
              setTimeout(() => {
                setForm({ ...form, ListOfService: [] })
              }, 500)
            } else {
              showMessage(updateServiceList?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}

         
          break
             
      }
  
      if (!(id || bpId) && activeTab != 1) {
        setactiveTab(1)
        localStorage.setItem("tab", 1)
      }
    }
  
    const handleBlur = e => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value?.trim(),
        errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
      })
    }
    useEffect(() => {
      if (changed) {
        setErrors(prev => {
          const copy = JSON.parse(JSON.stringify(prev))
          delete copy[changed]
          return copy
        })
      }
    }, [changed])  // changed
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">BUSINESS PARTNER</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Operations</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/asset-wallet">Business Partner</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                activeTab > 1 && setactiveTab(1)
                                localStorage.setItem("tab", 1)
                              }}
                            >
                              <span className="number">1.</span> Business Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                activeTab > 2 && setactiveTab(2)
                                localStorage.setItem("tab", 2)
                              }}
                            >
                              <span className="number">2.</span> Contact Persons
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                activeTab > 3 && setactiveTab(3)
                                localStorage.setItem("tab", 3)
                              }}
                            >
                              <span className="number">3.</span>
                              Vendor Service List
                            </NavLink>
                          </NavItem>
                      
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {/**4,5,6 dynamic forms and tables */}
                          <TabPane tabId={1}>
                            <Form>
                              <h4 className="page-title-box">
                              Business Information
                              </h4>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="Bus_Name"
                                      className="form-label"
                                    >
                                      Business Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        errors.Bus_Name
                                          ? "invalid-inputfield form-control"
                                          : "Bus_Name form-control"
                                      }
                                      name="Bus_Name"
                                      id="Bus_Name"
                                      placeholder="Business Name"
                                      required
                                      onChange={e => {
                                        setChanged("Bus_Name")
                                        handleForm(e, "businessInfo")
                                      }}
                                      value={form.Bus_Name}
                                      onBlur={handleBlur}
                                    />
                                    {errors.Bus_Name && (
                                      <div className="invalid-input">
                                        {errors.Bus_Name}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="EIN_Number"
                                      className="form-label"
                                    >
                                      EIN Number<span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        errors.Bus_Name
                                          ? "invalid-inputfield form-control"
                                          : "EIN_Number form-control"
                                      }
                                      name="EIN_Number"
                                      id="EIN_Number"
                                      required
                                      placeholder="EIN Number"
                                      value={form.EIN_Number}
                                      onChange={e =>
                                        handleForm(e, "businessInfo")
                                      }                                      
                                    />
                                     {errors.EIN_Number && (
                                      <div className="invalid-input">
                                        {errors.EIN_Number}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-2">
                                    <img
                                    id="output_image"
                                    className="rounded avatar-lg card-img img-thumbnail border border-2 p-0 d-inline-block"
                                    alt="Your Image"
                                    width="100"
                                    height="80"
                                    src={form.bp_logo || "default-profile.png"}
                                    />                                    
                                </div>

                                <div className="col-md-4">
                                    <div className="mb-3 position-relative">
                                    <label
                                        htmlFor="ac_img"
                                        className="form-label"
                                    >
                                        Business Logo
                                    </label>
                                    <div className="input-group">
                                        <input
                                        type="file"
                                        name="bp_logo"
                                        accept="image/*"
                                        className="upload"
                                        id="ac_img"
                                        style={{ display: "none" }}
                                        onChange={e => handleImage(e, "businessInfo")}                                                                                
                                        />
                                        <label htmlFor="ac_img">
                                        <p className="btn btn-info">Upload Image </p>
                                        </label>

                                    </div>
                                    </div>
                                </div>

                                 
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="Bus_Leg_Struc"
                                      className="form-label"
                                    >
                                      Legal Business Structure{" "}
                                      
                                    </label>
                                    <select
                                      className="form-select"
                                      id="Bus_Leg_Struc"
                                      name="Bus_Leg_Struc"                                      
                                      onChange={e => {
                                        setChanged("Bus_Leg_Struc")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.Bus_Leg_Struc}
                                    >    
                                    <option value="" selected>Select Legal Business Structure</option>
                                    <option value="Benefit Corporation">Benefit Corporation</option>
                                    <option value="Corporation LLC">Corporation LLC</option>
                                    <option value="General Partnership">General Partnership</option>
                                    <option value="Joint venture">Joint Venture</option> 
                                    <option value="Limited Liability Company (LLC)">Limited Liability Company (LLC)</option>                                    
                                    <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option> 
                                    <option value="Limited Partnership">Limited Partnership</option>
                                    <option value="Nonprofit">Nonprofit</option> 
                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                    <option value="S Corporation">S Corporation</option> 
                                    </select>                                    
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="BusType"
                                      className="form-label"
                                    >
                                      Business Type{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                     className={
                                        errors.Bus_Name
                                          ? "invalid-inputfield form-control"
                                          : "BusType form-control"
                                      }
                                      id="BusType"
                                      name="BusType"
                                      required
                                      onChange={e => {
                                        setChanged("BusType")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.BusType}
                                    >                                       
                                    <option value="" selected="">Select Business Type</option>                                    
                                    <option value="Attorney/Lawyers">Attorney/Lawyers</option> 
                                    <option value="Consulting Services">Consulting Services</option>
                                    <option value="Financial Advisor">Financial Advisor</option>
                                    <option value="Insurance Agent">Insurance Agent</option>                                   
                                    <option value="Realtor">Realtor</option>                                                                                       
                                    </select>
                                    {errors.BusType && <div className="invalid-input">
                                      {errors.BusType}
                                    </div>}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                     BusKey{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="BusKey"
                                      id="validationTooltip02"
                                      disabled                                      
                                      value={form.BusKey}
                                    /> 
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Business Email{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="EmailID"
                                      id="validationTooltip02"
                                      placeholder="Business Email"
                                      
                                      onChange={e => {
                                        setChanged("EmailID")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.EmailID}
                                    />                                    
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Business Phone{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="Phone"
                                      id="validationTooltip02"
                                      placeholder="Business Phone"
                                      
                                      onChange={e => {
                                        setChanged("Phone")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.Phone}
                                    />                                   
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Business Website{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="Bus_Website"
                                      id="validationTooltip02"
                                      placeholder="Business Website"
                                      
                                      onChange={e => {
                                        setChanged("Bus_Website")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.Bus_Website}
                                    />                                    
                                  </div>
                                </div>
                                
                                <h4 className="card-title">Address</h4>  

                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom05"
                                        className="form-label"
                                      >
                                        Street 1
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Street1"
                                        id="validationCustom05"
                                        placeholder="Street 1"
                                        value={form.Street1}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom06"
                                        className="form-label"
                                      >
                                        Street 2
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Street2"
                                        id="validationCustom06"
                                        placeholder="Street 2"
                                        value={form.Street2}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom07"
                                        className="form-label"
                                      >
                                        City
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="City"
                                        id="validationCustom07"
                                        placeholder="City"
                                        value={form.City}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="country"
                                        className="form-label"
                                      >
                                        Country
                                      </label>
                                      <select
                                        className="form-select"
                                        name="Country"
                                        id="Country"
                                        value={form.Country}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                      >
                                        <option value="" selected="">Select Country</option>
                                        <option value="USA">USA</option>
                                        <option value="INDIA">INDIA</option>
                                      </select>
                                    </div>
                                  </div>
  
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="region"
                                        className="form-label"
                                      >
                                        Region
                                      </label>
                                      <select
                                        className="form-select"
                                        name="Region"
                                        id="region"
                                        value={form.Region}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                      >
                                        {form.RegionList.length ===
                                        0 ? (
                                          <option value="" selected="">
                                            Select Region
                                          </option>
                                        ) : (
                                          <>
                                          <option value="" defaultValue>
                                            Select Region
                                          </option>
                                          {  form.RegionList.map(each => (
                                            <option
                                              value={each.region}
                                              key={each.region}
                                            >
                                              {each.region}
                                            </option>
                                          )) }
                                          </>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom07"
                                        className="form-label"
                                      >
                                        Zip
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="Zip"
                                        oninput="validateZIP()"
                                        id="zipCode"
                                        placeholder="Zip"
                                        value={form.Zip}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                      />
                                      <div id="zipError"></div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>

                          <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >
                              <h4 className="page-title-box">Contact Persons</h4>
  
                              <ListOfOwners
                                form={form}
                                handleForm={handleForm}
                                handleImage={handleImage}
                                bpId={id || bpId}
                                activeTab={activeTab}
                              />
                          </Suspense>
                          </TabPane>
                          <TabPane tabId={3}>                          
                            <Suspense
                                fallback={
                                  <div className="text-center m-4">Loading...</div>
                                }
                              >
                                <h4 className="page-title-box">Vendor Service List</h4>
    
                                <ListOfService
                                  form={form}
                                  handleForm={handleForm}
                                  handleImage={handleImage}
                                  bpId={id || bpId}
                                  activeTab={activeTab}
                                />
                            </Suspense>                            
                          </TabPane>                         
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              type="button"
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                              onClick={() => {
                                // activeTab != handleFormSubmit()
                                toggleTab(activeTab - 1)
                              }}
                              disabled={activeTab === 1}
                            >
                              Previous
                            </Button>
                          </li>
                          <li
                            className={activeTab === 3 ? "next disabled" : "next"}
                          >
                            <Button
                              disabled={activeTab === 3}
                              type="button"
                              onClick={() => {
                                activeTab != handleFormSubmit()
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {bpId || id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={"/business_partners_list"}
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        localStorage.removeItem("bpId")
                        localStorage.removeItem("tab")
                      }}
                    >
                      Cancel
                    </Link>                    
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddBusinessPartner
  