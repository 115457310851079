import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import React, { Suspense, lazy, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"

import {
  Spinner,
  Col,
  Container,
  Form,
  input,
  NavItem,
  NavLink,
  Row,
  Button,
  TabContent,
  TabPane,
  Modal, ModalBody, ModalHeader,
} from "reactstrap"
import { userId , loginUserData} from "helpers/userId"
import { randomId } from "helpers/randomGen"
import { FileUpload } from "helpers/file_uploads/upload_handler"

const ListofBeneficiaries = lazy(() =>
  import("components/Customers/LifeInsurance/ListofBeneficiaries")
)

const initialFormDetails = {
  policy_number: "",
  face_amount: "",
  policy_status: "",
  expiration_date: "",
  effective_date: "",
  policy_term: "",
  insured_name: "",
  insured_age_at_issue: "",
  broker_name: "",
  broker_phone: "",
  billing_frequency: "",
  policy_paid_to: "",
  billing_amount: "",
  next_bill_date: "",
  billing_currency: "",
  company_name: "",
  customer_care_phone: "",
  company_address: "",
  company_email: "",
  company_website: "",
  pbl_name: "",
  pbl_relitionship: "",
  pbl_persant: "",
  ListofBeneficiaries: [],
  relationsList: [],

  ListOfExistingBeneficiary: [],
  document: "",
  Policy_Key: `LIF${randomId(6)}`,

  errors: {
    policy_number: "",
    face_amount: "",
    policy_status: "",   
  },
}

const AddLifeInsurancePolicy = () => {
  const [activeTab, setactiveTab] = useState(
    (localStorage.getItem("tab") &&
      parseInt(JSON.parse(localStorage.getItem("tab")))) ||
      1
  )
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [errors, setErrors] = useState({})
  const [changed, setChanged] = useState(undefined)

  const relationsList = JSON.parse(localStorage.getItem("relationsList"))


  console.log("Documenrts FOrm", form)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [isUserValidated, setIsUserValidated] = useState(false)
  const [userKayVal, setUserKayVal] = useState("")
  const [displayImg, setDisplayImg] = useState({})

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }
  const tog_small = () => {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const tog_xlarge = () => {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  const handleUserKay = () => {
    if (userKayVal && userKayVal === loginUserData.user_key) {
      setIsUserValidated(false)
      tog_xlarge()
      tog_small()
    } else {
      setIsUserValidated(true)
    }
    setUserKayVal("")
  }

  const displayModel = (title, url) => {
    tog_small()
    const fileType = /[^.]+$/.exec(url)
    const isImage = ["jpg", "jpeg", "png"].some(ext => {
      return fileType.some(ext2 => {
        return ext === ext2
      })
    })
    
    
    console.log(url, fileType)
    let path =
      fileType[0] === "doc" || fileType[0] === "docx"
        ? `https://docs.google.com/gview?url=${url}&embedded=true`
        : url
    setDisplayImg({
      path: path,
      title: title,
      isImage: isImage,
      show: true,
      fileType: fileType,
    })
  }

 


  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  const plId = parseInt(JSON.parse(localStorage.getItem("plId")))

  async function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        localStorage.setItem("tab", tab)

        if (activeTab == 3) {
          await fetchBeneficiaryData()
        }
      }
    }
  }


  async function fetchBeneficiaryData() {
    try {
      const url = `${
        process.env.REACT_APP_DOMAIN_URL
      }api/v1/customer/services/life_beneficiaries_list?pl_id=${
        id || plId
      }&pl_uid=${userId}`

      const response = await fetch(url)

      const data = await response.json()

      const decryptedData = data?.data?.map((each, idx) => {
        return {
          ...each,
          number: idx + 1,
          pbl_name: each.pbl_name || "",
          pbl_relitionship: each.pbl_relitionship || "",
          pbl_persant: each.pbl_persant || "",
          relationsList: relationsList || [],
        }
      })

      setTimeout(() => {
        setForm(prev => {
          return {
            ...prev,
            ListofBeneficiaries: decryptedData || [],
          }
        })
      }, [500])
    } catch (err) {}
  }

  const handleRemoveBeneficiary = async (pbl_id, idx) => {
    try {
      if (pbl_id) {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_life_beneficiaries`
        const data = {
          pbl_id: pbl_id,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})
        const removeData = await response.json()

        if (removeData?.success === true) {
          showMessage(removeData.message, "success")
          setForm(prev => {
            return {
              ...prev,
              ListofBeneficiaries: form.ListofBeneficiaries.filter(
                each => each.pbl_id !== pbl_id
              ),
            }
          })
        } else {
          showMessage(removeData.message, "error", false)
        }
      } else if (idx >= 0) {
        setForm({
          ...form,
          ListofBeneficiaries: form.ListofBeneficiaries.filter(
            (each, index) => index != idx
          ),
        })
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  useEffect(() => {
    if (activeTab == 3) {
      fetchBeneficiaryData()
    }
  }, [])

  // -- Fetch relationships list start -- //
  async function fetchRelationsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
      )
      const data = await response.json()
      setForm({ ...form, relationsList: data?.data || [] })

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchRelationsList()    
  }, [])


  const fetchData = async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/get_life_insurance?pl_id=${
          id || plId
        }&user_id=${userId}`
      )
      const data = await response.json()

      if (data?.success) {
        const acData = data?.data[0]

        setForm(prev => {
          return {
            ...prev,
            ...acData,
            
            insured_age_at_issue: acData.insured_age_at_issue,
            broker_name: acData.broker_name,
            broker_phone: acData.broker_phone,
            billing_frequency: acData.billing_frequency,
            policy_paid_to: acData.policy_paid_to,
            billing_amount: acData.billing_amount,
            next_bill_date: acData.next_bill_date,
            billing_currency: acData.billing_currency,
            Policy_Key: acData.Policy_Key,
            face_amount: acData.face_amount,

            // n
            company_name: acData.company_name,
            customer_care_phone: acData.customer_care_phone,
            company_address: acData.company_address,
            company_email: acData.company_email,
            company_website: acData.company_website,
            policy_term: acData.policy_term,
          }
        })
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (id || plId) {
      fetchData()
    }
  }, []) 

  useEffect(() => {
    if (activeTab) {
      async function setFormData(data) {
        if (data.success) {
          const decryptedData = data?.data[0]
          if (decryptedData) {
           
            const assetInformation = {
              ...decryptedData,
              insured_age_at_issue: decryptedData.insured_age_at_issue,
            broker_name: decryptedData.broker_name,
            broker_phone: decryptedData.broker_phone,
            billing_frequency: decryptedData.billing_frequency,
            policy_paid_to: decryptedData.policy_paid_to,
            billing_amount: decryptedData.billing_amount,
            next_bill_date: decryptedData.next_bill_date,
            billing_currency: decryptedData.billing_currency,
            Policy_Key: decryptedData.Policy_Key,
            face_amount: decryptedData.face_amount,

            // n
            company_name: decryptedData.company_name,
            customer_care_phone: decryptedData.customer_care_phone,
            company_address: decryptedData.company_address,
            company_email: decryptedData.company_email,
            company_website: decryptedData.company_website,
            policy_term: decryptedData.policy_term,

            }           
            if (activeTab) {
              const response = await fetch(
                `${
                  process.env.REACT_APP_DOMAIN_URL
                }api/v1/customer/services/get_life_insurance?pl_id=${
                 id || plId
                 }&user_id=${userId}`
              )
              const data = await response.json()             
            }
          }
        }
        return true
      }      
    }
  }, [activeTab])


  // -- Fetch family information list end -- //

  const handleForm = (e, action, key) => {
    let { name, value } = e?.target || {}

    switch (action) {
      case "lifeInsurance":
        setForm({ ...form, [name]: value })
        break
      case "billinginfo":
        setForm({
          ...form,
          [name]: value,
        })
        break
      case "insurancecompany":
        setForm({
          ...form,
          [name]: value,
        })
        break
      case "addListofBeneficiaries":
        let latestNumbers = 2
        if (form.ListofBeneficiaries.length) {
          let numberList = form.ListofBeneficiaries.map(each => each.number)

          latestNumbers = Math.max(...numberList) + 1
        }
        form.ListofBeneficiaries.push({
          number: form.ListofBeneficiaries.length ? latestNumbers : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, ListofBeneficiaries: form.ListofBeneficiaries })
        break
      case "removeListofBeneficiaries":
        const modifiedLists = form.ListofBeneficiaries.find(
          (each, idx) => idx === key
        )
        handleRemoveBeneficiary(modifiedLists?.pbl_id || 0, key)
        break
      case "beneficiariesList":
        setForm({
          ...form,
          ListofBeneficiaries: form.ListofBeneficiaries.map((each, idx) => {
            if (idx == key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break
      default:
        null
    }
  }

  // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, [name]: result.files?.[0]?.url })
  }
 

  const handleFormSubmit = async () => {
    switch (activeTab) {
      case 1:
       
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_life_insurance`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/life-insurance-list`


            let haserrors = false            

            if (!form.policy_number) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  policy_number: "Please enter Plocy Number"
                }
              })
            }
            
            if (!form.face_amount) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  face_amount: "Please enter Face Amount"
                }
              })
            }

            if (!form.policy_status) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  policy_status: "Please Select Policy Status"
                }
              })              
            }           
            if (haserrors) {
              return
            }


          const data = {
            user_id: userId,
            pro_asset_name: form.pro_asset_name,
            policy_number: form.policy_number,
            face_amount: form.face_amount,
            policy_status: form.policy_status,
            expiration_date: form.expiration_date,
            effective_date: form.effective_date,
            policy_term: form.policy_term,
            insured_name: form.insured_name,
            insured_age_at_issue: form.insured_age_at_issue,
            broker_name: form.broker_name,
            broker_phone: form.broker_phone,
            billing_frequency: form.billing_frequency,
            policy_paid_to: form.policy_paid_to,
            billing_amount: form.billing_amount,
            next_bill_date: form.next_bill_date,
            billing_currency: form.billing_currency,
            company_name: form.company_name,
            customer_care_phone: form.customer_care_phone,
            company_address: form.company_address,
            company_email: form.company_email,
            company_website: form.company_website,
            pbl_name: form.pbl_name,
            pbl_relitionship: form.pbl_relitionship,
            pbl_persant: form.pbl_persant,
            document: form.document,
            Policy_Key: form.Policy_Key
          }

          if (id) {
            data.pl_id = id
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateLifeInsurance = await response.json()

          if (addOrUpdateLifeInsurance?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(2)
            localStorage.setItem("tab", 2)
            !id && localStorage.setItem("plId", addOrUpdateLifeInsurance.plId)
            showMessage(addOrUpdateLifeInsurance.message, "success")
          } else {
            showMessage(addOrUpdateLifeInsurance?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}    
        break
      case 2:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_billing_information`

          const data = {
            pl_id: plId || id,
            billing_frequency: form.billing_frequency,
            policy_paid_to: form.policy_paid_to,
            billing_amount: form.billing_amount,
            next_bill_date: form.next_bill_date,
            billing_currency: form.billing_currency,
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateLifeInsurance = await response.json()

          if (addOrUpdateLifeInsurance?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(3)
            localStorage.setItem("tab", 3)
            showMessage(addOrUpdateLifeInsurance.message, "success")
          } else {
            showMessage(addOrUpdateLifeInsurance?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 3:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_policybeneficaiaries`

          const data = {
            pl_id: id || plId,
            pl_uid: userId,
            listOfBeneficiaries: form.ListofBeneficiaries.map(
              eachBeneficiary => {
                return {
                  pbl_id: eachBeneficiary.pbl_id || 0,
                  pbl_name: eachBeneficiary.pbl_name || "",
                  pbl_relitionship: eachBeneficiary.pbl_relitionship || "",
                  pbl_persant: eachBeneficiary.pbl_persant || "",
                }
              }
            ),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateListOfBeneficiaries = await response.json()

          if (addOrUpdateListOfBeneficiaries?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage(addOrUpdateListOfBeneficiaries.message, "success")
            // setTimeout(() => {
            //   history("/asset-wallet")
            // }, 3000)
            setactiveTab(activeTab + 1)
            localStorage.setItem("tab", activeTab + 1)
          } else {
            showMessage(addOrUpdateListOfBeneficiaries?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 4:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_life_insurance_company_details`

          const data = {
            user_id: userId,
            pl_id: id || plId,
            company_name: form.company_name || "",
            customer_care_phone: form.customer_care_phone || "",
            company_address: form.company_address || "",
            company_email: form.company_email || "",
            company_website: form.company_website || "",
            document: form.document || ""
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateLifeInsurance = await response.json()

          if (addOrUpdateLifeInsurance?.success === true) {
            showMessage(addOrUpdateLifeInsurance.message, "success")
             setTimeout(() => {
                history("/life-insurance")
              }, 3000)
          } else {
            showMessage(addOrUpdateLifeInsurance?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {
          showMessage(error, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
        break
      default:
        break
    }

    if (!(id || plId) && activeTab != 1) {
      setactiveTab(1)
      localStorage.setItem("tab", 1)
    }
  }

  const handleBlur = e => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value?.trim(),
      errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
    })
  }

  useEffect(() => {
    if (changed) {
      setErrors(prev => {
        const copy = JSON.parse(JSON.stringify(prev))
        delete copy[changed]
        return copy
      })
    }
  }, [changed])

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
       { /* {console.log(form)} */} 
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">POLICY</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Services</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/asset-wallet">Add Policy</Link>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              activeTab > 1 && setactiveTab(1)
                              localStorage.setItem("tab", 1)
                            }}
                          >
                            <span className="number">1.</span> Policy
                            Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              activeTab > 2 && setactiveTab(2)
                              localStorage.setItem("tab", 2)
                            }}
                          >
                            <span className="number">2.</span> Billing
                            Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              activeTab > 3 && setactiveTab(3)
                              localStorage.setItem("tab", 3)
                            }}
                          >
                            <span className="number">3.</span>
                            Beneficiary Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              activeTab > 4 && setactiveTab(4)
                              localStorage.setItem("tab", 4)
                            }}
                          >
                            <span className="number">4.</span>
                            Insurance Company
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                     <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <h4 className="page-title-box">
                              Policy Information
                            </h4>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="policy_number"
                                    className="form-label"
                                  >
                                    Policy Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.policy_number
                                        ? "invalid-inputfield form-control"
                                        : "policy_number form-control"
                                    }
                                    name="policy_number"
                                    id="policy_number"
                                    placeholder="Policy Number"
                                    required
                                    onChange={e => {
                                      setChanged("policy_number")
                                      handleForm(e, "lifeInsurance")
                                    }
                                    }

                                    value={form.policy_number}
                                  />
                                  {errors.policy_number && <div className="invalid-input">
                                    {errors.policy_number}
                                  </div>}

                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="face_amount"
                                    className="form-label"
                                  >
                                    Face Amount <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.face_amount
                                        ? "invalid-inputfield form-control"
                                        : "face_amount form-control"
                                    }                                   
                                    name="face_amount"
                                    id="face_amount"
                                    placeholder="Face Amount"
                                    value={form.face_amount}
                                    onChange={e => {
                                      setChanged("face_amount")
                                      handleForm(e, "lifeInsurance")
                                    }
                                    }
                                  />
                                   {errors.face_amount && <div className="invalid-input">
                                    {errors.face_amount}
                                  </div>}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="policy_status"
                                    className="form-label"
                                  >
                                    Policy Status{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select                                    
                                    className="form-select"
                                    id="policy_status"  
                                    name="policy_status"                                  
                                    required
                                    onChange={e => {
                                      setChanged("policy_status")
                                      handleForm(e, "lifeInsurance")
                                    }
                                    }
                                    value={form.policy_status}
                                  >
                                    <option value="" selected="">
                                      Select Policy Status
                                    </option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                  </select>
                                  {errors.policy_status && <div className="invalid-input">
                                    {errors.policy_status}
                                  </div>}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Expiration Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="expiration_date"
                                    id="validationTooltip02"
                                    placeholder="Expiration Date"
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }                                    
                                    value={form.expiration_date}
                                  />
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Effective Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="effective_date"
                                    id="validationTooltip02"
                                    placeholder="Effective Date"
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }
                                    value={form.effective_date}
                                  />
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Policy Term
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="policy_term"
                                    id="validationTooltip02"
                                    placeholder="Policy Term"
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }
                                    value={form.policy_term}
                                  />
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="Policy_Key"
                                    className="form-label"
                                  >
                                    Policy Key{" "}
                                    
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Policy_Key"
                                    id="Policy_Key"
                                    placeholder="Policy Key"
                                    readOnly
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }
                                    value={form.Policy_Key}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="insured_name"
                                    className="form-label"
                                  >
                                    Insured Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="insured_name"
                                    id="insured_name"
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }
                                    value={form.insured_name}
                                  />
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="insured_age_at_issue"
                                    className="form-label"
                                  >
                                    Insured Age at issue
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="insured_age_at_issue"
                                    id="insured_age_at_issue"
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }
                                    value={form.insured_age_at_issue}
                                  />
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="broker_name"
                                    className="form-label"
                                  >
                                    Broker Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="broker_name"
                                    id="broker_name"
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }
                                    value={form.broker_name}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="broker_phone"
                                    className="form-label"
                                  >
                                    Broker Phone
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="broker_phone"
                                    id="broker_phone"
                                    onChange={e =>
                                      handleForm(e, "lifeInsurance")
                                    }
                                    value={form.broker_phone}
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <h4 className="page-title-box">
                              Billing Information
                            </h4>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="billing_frequency"
                                    className="form-label"
                                  >
                                    Billing Frequency
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="billing_frequency"
                                    id="billing_frequency"
                                    placeholder="Billing Frequency"
                                    onChange={e => handleForm(e, "billinginfo")}
                                    value={form.billing_frequency}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="billing_frequency"
                                    className="form-label"
                                  >
                                    Policy Paid To
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="policy_paid_to"
                                    id="policy_paid_to"
                                    placeholder=" Policy Paid To"
                                    onChange={e => handleForm(e, "billinginfo")}
                                    value={form.policy_paid_to}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="billing_amount"
                                    className="form-label"
                                  >
                                    Billing Amount
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="billing_amount"
                                    id="billing_amount"
                                    placeholder="Billing Amount"
                                    onChange={e => handleForm(e, "billinginfo")}
                                    value={form.billing_amount}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="next_bill_date"
                                    className="form-label"
                                  >
                                    Next Bill Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="next_bill_date"
                                    id="next_bill_date"
                                    placeholder="Next Bill Date"
                                    onChange={e => handleForm(e, "billinginfo")}
                                    value={form.next_bill_date}
                                  />
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip03"
                                    className="form-label"
                                  >
                                    Billing Currency
                                  </label>
                                  <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="billing_currency"
                                    required
                                    onChange={e => handleForm(e, "billinginfo")}
                                    value={form.billing_currency}
                                  >
                                    <option value="" selected="">
                                      Select Billing Currency
                                    </option>
                                    <option value="INR">INR</option>
                                    <option value="USD">USD</option>
                                    <option value="EURO">EURO</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">
                              List Of Beneficiaries
                            </h4>

                            <ListofBeneficiaries
                              form={form}
                              handleForm={handleForm}
                              handleImage={handleImage}
                            />
                          </Suspense>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Form>
                            <h4 className="page-title-box">
                              Insurance Company
                            </h4>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="company_name"
                                    className="form-label"
                                  >
                                    Insurance Company Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="company_name"
                                    id="company_name"
                                    placeholder="Insurance Company Name"
                                    onChange={e =>
                                      handleForm(e, "insurancecompany")
                                    }
                                    value={form.company_name}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="customer_care_phone"
                                    className="form-label"
                                  >
                                    Customer Care Phone
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="customer_care_phone"
                                    id="customer_care_phone"
                                    placeholder="Customer Care Phone"
                                    onChange={e =>
                                      handleForm(e, "insurancecompany")
                                    }
                                    value={form.customer_care_phone}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip03"
                                    className="form-label"
                                  >
                                    Address
                                  </label>
                                  <textarea
                                    id="validationTooltip03"
                                    name="company_address"
                                    rows="3"
                                    className="form-control"
                                    placeholder="Financier Address"
                                    required
                                    onChange={e =>
                                      handleForm(e, "insurancecompany")
                                    }
                                    value={form.company_address}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="company_email"
                                    className="form-label"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="company_email"
                                    id="company_email"
                                    placeholder="Email"
                                    onChange={e =>
                                      handleForm(e, "insurancecompany")
                                    }
                                    value={form.company_email}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="company_website"
                                    className="form-label"
                                  >
                                    Website
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="company_website"
                                    id="company_website"
                                    placeholder="Website"
                                    onChange={e =>
                                      handleForm(e, "insurancecompany")
                                    }
                                    value={form.company_website}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">
                                  Upload Policy Document
                                </h4>
                                <div className="row">
                                  <div className="col-md-4">
                                    <input
                                      type="file"
                                      name="document"
                                      accept="image/*"
                                      className="upload"
                                      id="document"
                                      // style={{ display: "none" }}
                                      onChange={handleImage}
                                    />
                                   
                                   
                                  </div>
                                </div>

                                
                              </div>
                            </div>                          
                            
                      
                              <Button
                                color="primary"
                                onClick={() =>
                                  displayModel(form.policy_number, form.document)
                                  
                                }
                              >
                                View document
                              </Button>
                              <Modal isOpen={modal_small} toggle={tog_small} size="sm">
                                <ModalHeader className="mt-0" toggle={tog_small}>
                                  Please enter "User Key" to open file
                                </ModalHeader>
                                <ModalBody>
                                  <p>
                                    Note: Please find your "User Key" in personal
                                    information section
                                  </p>
                                  <p>
                                    <input
                                      type="userKay"
                                      className="form-control"
                                      name="userKay"
                                      id="userKay"
                                      value={userKayVal}
                                      onChange={e => setUserKayVal(e.target.value)}
                                      onBlur={e => setIsUserValidated(false)}
                                    />
                                  </p>
                                  <p>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleUserKay}
                                    >
                                      Verify User Key
                                    </button>
                                  </p>
                                  {isUserValidated && (
                                    <p className="alert alert-danger ">
                                      Please enter valid "User Key" value.
                                    </p>
                                  )}
                                </ModalBody>
                              </Modal>
                              {displayImg.show && (
                                <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                                  <ModalHeader className="mt-0" toggle={tog_xlarge}>
                                    {displayImg.title}
                                  </ModalHeader>
                                  <ModalBody>
                                    {console.log(displayImg)}
                                    {displayImg.isImage ? (
                                      <img src={displayImg.path} width="100%"></img>
                                    ) : (
                                      <iFrame
                                        src={displayImg.path}
                                        width="100%"
                                        height="650px"
                                      ></iFrame>
                                    )}
                                  </ModalBody>
                                </Modal>
                              )}

                           
                            
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Button
                            type="button"
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                            onClick={() => {
                              // activeTab != handleFormSubmit()
                              toggleTab(activeTab - 1)
                            }}
                            disabled={activeTab === 1}
                          >
                            Previous
                          </Button>
                        </li>
                        <li
                          className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Button
                            disabled={activeTab === 4}
                            type="button"
                            onClick={() => {
                              activeTab != handleFormSubmit()
                              toggleTab(activeTab + 1)
                            }}
                          >
                            Next
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {id || plId ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={"/life-insurance"}
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                      localStorage.removeItem("plId")
                      localStorage.removeItem("tab")
                    }}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddLifeInsurancePolicy
