
import { Container } from "reactstrap"
import React, { useEffect, useState } from "react"



const UserMembershipExpire = () => {

  const [form, setForm] = useState()

  const handleForm = e => {
    let { name, value } = e.target 
      setForm({ ...form, [name]: value })  
  }
  
  const handleFormSubmit = async () => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="page-title-box">
            <h4 className="font-size-18">Membership Expire</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/"></a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Membership Expire</a>
              </li>
            </ol>
          </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">				

                            				
                            
                        </div>                                            
                    </div>
                </div>
            </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserMembershipExpire
