import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { Link } from "react-router-dom";

// Custom Scrollbar
import SimpleBar from "simplebar-react";
import { daysLeft } from 'pages/Utility/date-helper';



//i18n
import { withTranslation } from "react-i18next";

const Dashboard = props => {
  const [menu, setMenu] = useState(false);
  const [summary, setSummary] = useState();
  const toggle = () => {
    setMenu(!menu);
  };

  const userData = JSON.parse(localStorage.getItem("userData")) || ""
  let user_role = -1
  let user_id = 0

  if (userData) {
    user_role =
      userData.user.user_role === 0 ? 0 : userData.user.user_role || -1
    user_id = userData.user.user_id
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_customer-asset-summary?user_id=${user_id}`
      )

      const data = await response.json()
      setSummary(data.data);
      console.log(data.data);
    }
    fetchData()
  }, [])

  document.title = "Dashboard | Sof - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            Welcome            
          </div>


          
          {user_role === 0 && (
            <>
          <Row>
          <div className="col-xl-3 col-md-6">
            <div className="card" style={{ height: "240px" }}>
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <p className="text-truncate font-size-14 mb-2">Total Asset Value</p>
                            <h4 className="mb-2">
                            </h4>                                               
                        </div>                    
                        <div className="avatar-bm">                                                
                            <a href="/asset-wallet" className="btn btn-danger waves-light">Details</a>                                                
                        </div>
                    </div> 
                        <div className="">
                            <table className="table mb-0">        
                                <thead>
                                    <tr>                                                            
                                        <th>Country</th>
                                        <th>Amount</th>
                                        <th>Currency</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summary?.assets?.map(asset => 
                                        {
                                            return <tr>
                                                    <td>{asset.country}</td>
                                                    <td>{asset.totalAmt}</td>
                                                    <td>{asset.currrency}</td>
                                                </tr>
                                            }
                                    ) 
                                }
                              </tbody>  
                            </table>
                        </div>
                </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
                <div className="card" style={{ height: "240px" }}>
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-truncate font-size-14 mb-2">Bank Sync</p>
                                <h4 className="mb-2"></h4>                                                
                            </div>
                            <div className="avatar-sm">
                                <span className="avatar-title bg-light text-success rounded-3">
                                    <i className="mdi mdi-currency-usd font-size-24"></i>  
                                </span>
                            </div>                                            
                        </div>                                            
                    </div>
                </div>
          </div>
          <div className="col-xl-3 col-md-6">
              <div className="card" style={{ height: "240px" }}>
                  <div className="card-body">
                      <div className="d-flex">
                          <div className="flex-grow-1">
                              <p className="text-truncate font-size-14 mb-2">Total Insurance</p>
                              <h4 className="mb-2"></h4>                              
                          </div>
                          <div className="avatar-bm">                                                
                          <a href="/life-insurance" className="btn btn-danger waves-light">Details</a>                                                   
                          </div>
                      </div>
                      <div className="">
                              <table className="table mb-0">        
                                  <thead>
                                      <tr>                                          
                                          <th>Name</th>
                                          <th>Amount</th>
                                          <th>Currency</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                  {summary?.policies?.map(policy => 
                                    {
                                        return <tr>
                                                <td>{policy.insured_name}</td>
                                                <td>{policy.face_amount}</td>
                                                <td>{policy.billing_currency}</td>
                                            </tr>
                                        }
                                    ) 
                                }
                                                                              
                                  </tbody>
                              </table>
                          </div>                                              
                  </div>
              </div>
          </div>
          <div className="col-xl-3 col-md-6">
              <div className="card" style={{ height: "240px" }}>
                  <div className="card-body">
                      <div className="d-flex">
                          <div className="flex-grow-1">
                              <p className="text-truncate font-size-14 mb-2">Membership</p>
                          </div>
                          <div className="avatar-sm">
                          <a href="/membership" className="btn btn-danger waves-light">Pay</a>    
                          </div>
                      </div>										
                          <div className="">
                              <table className="table">        
                                  <thead>
                                      <tr>                                                            
                                          <th>Type</th>                                                            
                                          <th>Valid To</th>
                                      </tr>
                                  </thead>
                                  <tbody>                                                    
                                      <tr>                                                            
                                          <td>{summary?.memberInfo?.usmsg}</td>
                                          <td>{summary?.memberInfo?.End_Date}</td>
                                      </tr> 
                                </tbody>  
                              </table>
                              {`Your membership expires in ${daysLeft(summary?.memberInfo?.End_Date)} day${daysLeft(summary?.memberInfo?.End_Date) > 1 ? 's' :'' }`}
                          </div>
                  </div>
              </div>
          </div>           
          </Row>
          </>
          )}


        
        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
