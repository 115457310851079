import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Container } from "reactstrap"
import { updateNotificationView } from "./UpdateNotificationView"
import { userId } from "helpers/userId"
import { showMessage } from "components/alertmessages/Alertmessages"

const initialFormData = {
  date: "",
  notify_type: "",
  notify_name: "",
}

const ServiceRequests = () => {
  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  const [form, setForm] = useState(initialFormData)

  useEffect(() => {
    if (id) {
      updateNotificationView(id)
    }
  }, [id])

  async function fetchNotificationData() {
    try {
      const notificationsData = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/notifications/get_notifications?user_id=${userId}&id=${id}`
      )

      const resultData = await notificationsData.json()
      if (resultData.success && resultData?.data?.length) {
        const data = resultData.data[0]
        setForm({
          ...form,
          date: data.notify_date,
         // content: data.content,
          notify_type: data.notify_type,
          notification_id: data.id,
          notify_view: data.notify_view,
          notify_status: data.notify_status,
          notify_name: data.notify_name,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      await fetchNotificationData()
    }

    fetchData()
  }, [])

  const handleSubmitRequest = async () => {
    if (form.notification_id && form.notify_status == "No") {
      try {
        const data = {
          notification_id: form.notification_id,
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/bp_service_request_accept`,
          options
        )

        const resultData = await response.json()

        if (resultData.success) {
          showMessage(resultData.message, "success")
            setTimeout(() => {
              history("/notifications")
            }, 3000) 
        } else {
          showMessage(resultData.message, "error", false)
        }
      } catch (error) {
        showMessage(error.message, "error", false)
      }
    }
  }

  const handleRejectRequest = async () => {
    if (form.notification_id && form.notify_status == "No") {
      try {
        const data = {
          notification_id: form.notification_id,
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/bp_service_request_reject`,
          options
        )

        const resultData = await response.json()

        if (resultData.success) {
          showMessage(resultData.message, "success")
              setTimeout(() => {
              history("/notifications")
            }, 3000) 
        } else {
          showMessage(resultData.message, "error", false)
        }
      } catch (error) {
        showMessage(error.message, "error", false)
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="page-title-box">
            <h4 className="font-size-18">ASSET REQUEST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Asset Request</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Notifications View </h4>
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>View</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Date of Notification</th>
                      <td>{form.date || "NA"}</td>
                    </tr>
                    <tr>
                      <th scope="row">Type of Information</th>
                      <td>{form.notify_type}</td>
                    </tr>

                    <tr>
                      <th scope="row">Content</th>
                      <td>{form.notify_name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Action Type</th>
                      <td>
                        <input
                          type="submit"
                          className={`btn ${
                            form.notify_status === "Yes"
                              ? "btn-secondary"
                              : "btn-primary"
                          } waves-effect mx-4`}
                          name=""
                          value={form.notify_status === "Yes"? "Accepted Service Request": "Accept Service Request"}
                          onClick={handleSubmitRequest}
                        />
                        <input
                          type="submit"
                          className={`btn btn-secondary waves-effect mx-4`}
                          name="Ignore_Relation"
                          value="Reject Service Request"                          
                          onClick={handleRejectRequest}
                          disabled={form.notify_status === "Yes"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceRequests
