import { userId } from "helpers/userId"
import React, { useEffect, useState } from "react"
import ReactFamilyTree from "react-family-tree"
import { Container } from "reactstrap"
import FamilyNode from "./FamilyNode"
import nodesData from "./data.json"

const FamilyTree = () => {
  const [familyInformation, setFamilyInformation] = useState({ data: [] })
  const [nodes, setNodes] = useState([])

  const WIDTH = 260
  const HEIGHT = 280
  const rootId = "0"
  

  useEffect(() => {
    const fetchData = async () => {
      // const user_id =
      //   JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${userId}`
      )
      const data = await response.json()
      setFamilyInformation({ ...familyInformation, data: data?.data || [] })
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (familyInformation?.data.length > 0) {
      mapFamilyTree()
    }
  }, [familyInformation])

  console.log("familyInformation", familyInformation)

  const mapFamilyTree = () => {
    // setNodes([{
    //   id: userId,
    //   gender: "",
    //   profilePic: loginUserData.profile_pic,
    //   displayName: `${loginUserData.user_fname} ${loginUserData.user_lname}`,
    //   associated:'Yes',
    //   parents: [],
    //   siblings: [],
    //   spouses: [],
    //   children: []
    // }])

    setNodes([
      {
        id: "1",
        gender: "male",
        parents: [],
        siblings: [],
        spouses: [
          {
            id: "1",
            type: "married",
          },
        ],
        children: [],
      },
      {
        id: "2",
        gender: "female",
        parents: [],
        siblings: [],
        spouses: [
          {
            id: "1",
            type: "married",
          },
        ],
        children: [],
      },
    ])
    // const fTree = familyInformation.data.map((person) => {
    //   return
    // })
  }

  const mapParents = userId => {}

  const mapsiblings = userId => {}

  const mapSpouses = userId => {}

  const mapChildern = userId => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">FAMILY TREE</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Family Tree</a>
              </li>
            </ol>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="font-size-18">Family Tree</h4>
                <div class="col-12 d-flex"><div><a class="btn btn-danger my-2" type="button" href="/extended-family">Extened Family Tree</a></div></div>
                {nodesData.length > 0 && (
                  <ReactFamilyTree
                    nodes={nodesData}
                    rootId={rootId}
                    width={WIDTH}
                    height={HEIGHT}
                    canvasClassName="'tree'"
                    renderNode={node => (
                      <FamilyNode
                        key={node.id}
                        node={node}
                        isRoot={node.id === rootId}
                        style={{
                          width: WIDTH,
                          height: HEIGHT,
                          transform: `translate(${node.left * (WIDTH / 2)}px, ${
                            node.top * (HEIGHT / 2)
                          }px)`,
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FamilyTree
