import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import React, { useEffect, useState } from "react"
  import { Link, useNavigate } from "react-router-dom"
  import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"

const Changepassword = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <div className="page-title-box">
                <h4 className="font-size-18">Change Password</h4>
                <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                    <a href="/">Account Info</a>
                </li>
                <li className="breadcrumb-item">
                    <a href="/">Change Password</a>
                </li>
                </ol>
            </div>
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="mb-3 position-relative">
                                <label htmlFor="oldpassowrd" className="form-label">
                                Old Password
                                </label>
                                <input
                                type="password"
                                className="form-control"
                                name="user_old_pass"
                                id="oldpassowrd"
                                placeholder="Old Password"
                                required=""
                            
                                />
                            </div>
                            </div>
                            <div className="col-md-12">
                            <div className="mb-3 position-relative">
                                <label htmlFor="user_newpass" className="form-label">
                                New Password
                                </label>
                                <input
                                type="password"
                                className="form-control"
                                name="user_newpass"
                                id="user_newpass"
                                placeholder="New Password"
                                required=""
                            
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>  

             <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light me-1"
                    
                  >
                    Submit
                  </button>
                  &nbsp; &nbsp;
                  <Link
                    to="/personal-information"
                    className="btn btn-secondary waves-effect"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>         
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Changepassword
