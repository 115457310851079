import React from "react"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"

// Dashboard


//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Customer access
import PersonalInformation from "pages/AccountInfo/PersonalInformation"
import Changepassword from "pages/AccountInfo/ChangePassword"
import Verifications from "pages/AccountInfo/Verifications"
import FamilyInformation from "pages/AccountInfo/FamilyInformation"
import SendFamilyRequest from "pages/AccountInfo/FamilyInformation/send_family_request"
import FriendsInformation from "pages/AccountInfo/FriendsInformation"
import SendFriendRequest from "pages/AccountInfo/FriendsInformation/send_friend_request"
import FamilyTree from "pages/AccountInfo/FamilyTree"
import ExtendedFamily from "pages/AccountInfo/FamilyTree/ExtendedFamily"
import FamilyRelations from "pages/AccountInfo/FamilyTree/FamilyRelations"
import UserMembership from "pages/payment"
import UserMembershipExpire from "pages/payment/membership_expire"


// Asset Wallet
import AssetWallet from "pages/Services/AssetWallet"
import AddAssetWallet from "pages/Services/AssetWallet/AddAssetWallet"
import GetSofAsset from "pages/Services/AssetWallet/GetSofAsset"
import EditOwner from "pages/Services/AssetWallet/EditOwner"
import ShareAssetWallet from "pages/Services/AssetWallet/ShareAssetWallet"


// Life Insurance
import LifeInsurance from "pages/Services/LifeInsurance"
import AddLifeInsurancePolicy from "pages/Services/LifeInsurance/AddLifeInsurancePolicy"

// Will
import WillList from "pages/Services/Will"
import AddWill from "pages/Services/Will/AddWillInfo"
import GetSofWillTestator from "pages/Services/Will/GetSofWillTestator"
import GetSofWillExecutor from "pages/Services/Will/GetSofWillExecutor"
import GetSofWillBeneficiary from "pages/Services/Will/GetSofWillBeneficiary"
import EditWillContact from "pages/Services/Will/EditWillContact"

// GENERATE A WILL
import AddGenerateWill from "pages/Services/GenerateWill/AddGenerateWill"
import EditGenerateContact from "pages/Services/GenerateWill/EditGenerateContact"
import GetSofGeneratedExecutor from "pages/Services/GenerateWill/GetSofGeneratedExecutor"
import GetSofGeneratedGuardian from "pages/Services/GenerateWill/GetSofGeneratedGuardian"
import GeneratedWillDocumentList from "pages/Services/GenerateWill/GeneratedWillDocumentList"



import GetDocumentVault from "pages/Services/DocumentVault"
import AddDocumentVault from "pages/Services/DocumentVault/AddDocumentVault"



// Business Partner
import BusinessPartnersList from "pages/Services/BusinessPartner/BusinessPartner"
import AddBusinessPartner from "pages/Services/BusinessPartner/AddBusinessPartner"
import EditBusinessContact from "pages/Services/BusinessPartner/EditBusinessContact"
import EditBusinessService from "pages/Services/BusinessPartner/EditBusinessService"

import VendorRequestServiceList from "pages/Workspace/Operations"
import EditVendorRequestService from "pages/Workspace/Operations/EditVendorRequestService"
import GetBusinessSof from "pages/Workspace/Operations/GetBusinessSof"

import DocumentRepositoryList from "pages/Operations/DocumentRepository"
import AddDocumentRepository from "pages/Operations/AddDocumentRepository"



// Business Partner Userside

import BusinessPartnersInfo from "pages/BusinessPartner/BusinessPartnersInfo"
import BusinessPartnersContactInfo from "pages/BusinessPartner/BusinessPartnersContactInfo"
import BusinessPartnersServiceInfo from "pages/BusinessPartner/BusinessPartnersServiceInfo"
import ViewBPContact from "pages/BusinessPartner/ViewBPContact"
import ViewBPService from "pages/BusinessPartner/ViewBPService"
import BPServiceRequestList from "pages/BusinessPartner"
import EditBPServiceRequest from "pages/BusinessPartner/EditBPServiceRequest"








import Dashboard from "pages/Dashboard"
import ResetPassword from "pages/Authentication/ResetPassword"
import VerifyOtp from "pages/Authentication/VerifyOtp"

// Admin Access Links

import Region from "pages/Admin/Region/Region"
import AddRegion from "pages/Admin/Region/AddRegion"
import TypeofServices from "pages/Admin/TypeofService/TypeofService"
import AddTypeofServices from "pages/Admin/TypeofService/AddTypeofServices"
import RelationList from "pages/Admin/Relations/Relations"
import AddRelation from "pages/Admin/Relations/AddRelations"
import RoleList from "pages/UserRoles/RoleList"
import AddRole from "pages/UserRoles/AddRole"
import RoleCollection from "pages/UserRoles/RoleCollection"
import AddRoleCollection from "pages/UserRoles/AddRoleCollection"
import UniversalData from "pages/Admin/UniversalData/UniversalData"
import AddUniversalData from "pages/Admin/UniversalData/AddUniversalData"
import CustomersList from "pages/Admin/Customer/CustomerList"
import AddCustomerInfo from "pages/Admin/Customer/AddCustomerInfo"
import EditCustomerInfo from "pages/Admin/Customer/EditCustomerInfo"
import ApplicationList from "pages/Admin/Applications"
import AddApplication from "pages/Admin/Applications/AddApplication"





import RegisterSuccess from "pages/Authentication/RegisterSuccess"
import AddFamilyInformation from "pages/AccountInfo/FamilyInformation/add_family_information"
import ViewFamilyInformation from "pages/AccountInfo/FamilyInformation/view_family_information"
import AssetRequest from "pages/Notifications/AssetRequest"
import ServiceRequest from "pages/Notifications/ServiceRequest"

import FamilyRequest from "pages/Notifications/FamilyRequest"
import FriendsRequest from "pages/Notifications/FriendsRequest"
import Notifications from "pages/Notifications"
import GenerateWillDocumentsList from "pages/Services/Will/GenerateWillDocumentsList"


import AddRequestService from "pages/Workspace/ServiceRequest/AddRequestService"
import EditRequestService from "pages/Workspace/ServiceRequest/EditRequestService"
import RequestService from "pages/Workspace/ServiceRequest/RequestService"
import CouponList from "pages/Admin/Coupon"
import AddCoupon from "pages/Admin/Coupon/AddCoupon"
import AssetQuestionList from "pages/Admin/AssetQuestion"
import AddAssetQuestion from "pages/Admin/AssetQuestion/AddAssetQuestion"
import WillQuestionList from "pages/Admin/WillQuestion"
import AddWillQuestion from "pages/Admin/WillQuestion/AddWillQuestion"




const userRoutes = [
  // this route should be at the end of all other routes
  { path: "/personal-information", component: <PersonalInformation />, forUser: [0,1,2,3] },
  { path: "/changepassword", component: <Changepassword />, forUser: [0] },
  { path: "/verifications", component: <Verifications /> , forUser: [0] },
  { path: "/family-information", component: <FamilyInformation /> , forUser: [0] },
  { path: "/add-family-information", component: <AddFamilyInformation /> , forUser: [0] },
  { path: "/view_family_information", component: <ViewFamilyInformation /> , forUser: [0] },
  { path: "/send-family-request", component: <SendFamilyRequest /> , forUser: [0] },
  { path: "/friends-information", component: <FriendsInformation /> , forUser: [0] },
  { path: "/send-friend-request", component: <SendFriendRequest /> , forUser: [0] },
  { path: "/family-tree", component: <FamilyTree /> , forUser: [0] },
  { path: "/extended-family", component: <ExtendedFamily /> , forUser: [0] },
  { path: "/family-relations", component: <FamilyRelations /> , forUser: [0] },
  { path: "/membership", component: <UserMembership /> , forUser: [0] },

 // { path: "/success", component: <MembershipSuccess /> , forUser: [0] },
 // { path: "/cancel", component: <MembershipCancel /> , forUser: [0] },
  
  

  { path: "/asset-wallet", component: <AssetWallet /> , forUser: [0] },
  { path: "/add-asset-wallet", component: <AddAssetWallet /> , forUser: [0] },
  { path: "/edit-owner", component: <EditOwner /> , forUser: [0] },
  { path: "/get_sof_asset-wallet", component: <GetSofAsset /> , forUser: [0] },
  { path: "/shate_asset-wallet", component: <ShareAssetWallet /> , forUser: [0] },

  { path: "/life-insurance", component: <LifeInsurance /> , forUser: [0] },
  { path: "/add-life-insurance", component: <AddLifeInsurancePolicy /> , forUser: [0] },

  { path: "/will-list", component: <WillList /> , forUser: [0] },
  { path: "/add-will", component: <AddWill /> , forUser: [0] },
  { path: "/get_sof_will_testator", component: <GetSofWillTestator /> , forUser: [0] },
  { path: "/get_sof_will_executor", component: <GetSofWillExecutor /> , forUser: [0] },
  { path: "/get_sof_will_beneficiary", component: <GetSofWillBeneficiary /> , forUser: [0] },
  { path: "/edit_will_contact", component: <EditWillContact /> , forUser: [0] },  
  { path: "/generate-will-document-list", component: <GenerateWillDocumentsList /> , forUser: [0] },  

  

  { path: "/add_generate_will", component: <AddGenerateWill /> , forUser: [0] },
  { path: "/edit_generate_contact", component: <EditGenerateContact /> , forUser: [0] },
  { path: "/get_sof_generated_executor", component: <GetSofGeneratedExecutor /> , forUser: [0] },
  { path: "/get_sof_generated_guardian", component: <GetSofGeneratedGuardian /> , forUser: [0] },
  { path: "/generated_will_documet_list", component: <GeneratedWillDocumentList /> , forUser: [0] },


  { path: "/document_vault_list", component: <GetDocumentVault /> , forUser: [0] },
  { path: "/add_document_vault", component: <AddDocumentVault /> , forUser: [0] },
  

 
  { path: "/notifications", component: <Notifications /> , forUser: [0,1,2,3] },

  { path: "/notifications/services_request", component: <ServiceRequest /> , forUser: [0,1,2,3] },  
  { path: "/notifications/assets_request", component: <AssetRequest /> , forUser: [0,1,2,3] },  
  { path: "/notifications/family_requests", component: <FamilyRequest /> , forUser: [0,1,2,3] },
  { path: "/notifications/friends_requests", component: <FriendsRequest /> , forUser: [0,1,2,3] },

  { path: "/request_services", component: <RequestService /> , forUser: [0] },
  { path: "/add_request_service", component: <AddRequestService />, forUser: [0]  },
  { path: "/edit_request_service", component: <EditRequestService />, forUser: [0]  },


  // operations team

  { path: "/business_partners_list", component: <BusinessPartnersList />, forUser: [1,2] },
  { path: "/add_business_partner", component: <AddBusinessPartner />, forUser: [1,2] },
  { path: "/edit-business-contact", component: <EditBusinessContact />, forUser: [1,2] },
  { path: "/edit-business-service", component: <EditBusinessService />, forUser: [1,2] },

  { path: "/vendor_request_service_list", component: <VendorRequestServiceList /> , forUser: [1,2] },
  { path: "/edit_vendor_service_request", component: <EditVendorRequestService /> , forUser: [1,2] },
  { path: "/business_from_sof", component: <GetBusinessSof /> , forUser: [1,2] },


  { path: "/document_repository_list", component: <DocumentRepositoryList /> , forUser: [1,2] },
  { path: "/add-document_repository", component: <AddDocumentRepository /> , forUser: [1,2] },
  

   // business partners Userside

   { path: "/business_partners_info", component: <BusinessPartnersInfo />, forUser: [3] },
   { path: "/business_partners_contact_info", component: <BusinessPartnersContactInfo />, forUser: [3] },
   { path: "/business_partners_service_info", component: <BusinessPartnersServiceInfo />, forUser: [3] },
   { path: "/view-bp-contact", component: <ViewBPContact />, forUser: [3] },
   { path: "/view-bp-service", component: <ViewBPService />, forUser: [3] },
   { path: "/bp_service_request_list", component: <BPServiceRequestList />, forUser: [3] },
   { path: "/edit_bp_service_request", component: <EditBPServiceRequest />, forUser: [3] },

   

   
  
 

  { path: "/dashboard", component: <Dashboard /> , forUser: [0,1,2,3] },
  

  // Admin Access 

  { path: "/region", component: <Region /> ,forUser: [1]},
  { path: "/add_region", component: <AddRegion /> ,forUser: [1]},
  { path: "/typeofservies", component: <TypeofServices />,forUser: [1] },
  { path: "/add_typeofservies", component: <AddTypeofServices /> ,forUser: [1]},
  { path: "/roll_list", component: <RoleList /> ,forUser: [1]},
  { path: "/add_role", component: <AddRole /> ,forUser: [1]},
  { path: "/relation_list", component: <RelationList /> ,forUser: [1]},
  { path: "/add_relation", component: <AddRelation /> ,forUser: [1]},  
  { path: "/users_roll_collection", component: <RoleCollection /> ,forUser: [1]},
  { path: "/add_role_collection", component: <AddRoleCollection /> ,forUser: [1]},
  { path: "/universal_data_list", component: <UniversalData /> ,forUser: [1]},
  { path: "/add_universal_data", component: <AddUniversalData /> ,forUser: [1]},  
  { path: "/customers_list", component: <CustomersList /> ,forUser: [1]},
  { path: "/add-user-information", component: <AddCustomerInfo /> ,forUser: [1]},
  { path: "/edit_users_info", component: <EditCustomerInfo /> ,forUser: [1]},

  { path: "/application_list", component: <ApplicationList /> ,forUser: [1]},
  { path: "/add_application", component: <AddApplication /> ,forUser: [1]},

  { path: "/coupon_list", component: <CouponList /> ,forUser: [1]},
  { path: "/add_coupon", component: <AddCoupon /> ,forUser: [1]},

  { path: "/assetquestion_list", component: <AssetQuestionList /> ,forUser: [1]},
  { path: "/add_assetquestion", component: <AddAssetQuestion /> ,forUser: [1]},

  { path: "/willquestion_list", component: <WillQuestionList /> ,forUser: [1]},
  { path: "/add_willquestion", component: <AddWillQuestion /> ,forUser: [1]},
  
  
  
  
  
  
  { path: "/", component: <Dashboard /> , forUser: [0,1,2,3] },
]

const authRoutesWithoutSidebar = [
  { path: "/verify_otp", component: <VerifyOtp /> },
  { path: "/membership_expire", component: <UserMembershipExpire /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/sof/email_verify", component: <Login /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register-success", component: <RegisterSuccess /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
]

export { userRoutes, authRoutes, authRoutesWithoutSidebar }
