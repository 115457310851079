import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import "../../../src//styles/errorfield.scss"
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { randomId } from "helpers/randomGen"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
  const ServiceListofSteps = lazy(() =>
    import("components/Customers/Operations/ServiceListofSteps")
  )
  
  const initialFormDetails = {
                 
    scode: "",
    sname: "",
    ServiceType: "",       
    descrip: "",
    amount: "",
    sofamount: "",
    subtype: "",
    Features: "",
    PackageDetails: "",
    chargecustomer: "",
    Currency: "",  
    Reqservice_type:"",
    ReqDate:"",
    srv_id:"",
    bp_attachment:"",

    ServiceListofSteps: [],

    

  }
  
  const EditVendorRequestService = () => {
    const [activeTab, setactiveTab] = useState(
      parseInt(JSON.parse(localStorage.getItem("tab"))) || 1
    )
    const [form, setForm] = useState(initialFormDetails);
    const [cusFeedback, setCusFeedback] = useState(null);
   
    const [typeofservicelist, setTypeofservicelist] = useState([]);
    const [apiStatus, setApiStatus] = useState({ inProgress: false })

  
    
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const Reqid = searchParams.get("Reqid")
    const type = searchParams.get("type")    
    
    async function fetchNotesData() {
      try {
        const url = `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/get_notes_and_invoice_info?Reqid=${Reqid}`
  
        const response = await fetch(url)
        const data = await response.json()
        console.log(data)
        return data?.data[0]
         
      } catch (err) {}
    }   
  
    useEffect(async() => {
      if (activeTab == 1) {
        const res = await fetchNotesData()
        console.log(res)
        setCusFeedback(res)
      }
    }, [])
   
  
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const echo = data?.data;                        
              
              const vendorInformation = {
                ...echo,
                //Reqid: echo.Reqid,
                ReadStatus: echo.ReadStatus,
                ReqStatus: echo.ReqStatus,                 
                Reqservice_type: echo.Reqservice_type,
                ReqSubject: echo.ReqSubject,
                ReqMessage: echo.ReqMessage,

                
                BusKey: echo.BusKey,
                Bus_Name: echo.Bus_Name,
                Phone: echo.Phone,
                EmailID: echo.EmailID,
                RepName: echo.RepName,

                SrvType: echo.SrvType,
                SrvCode: echo.SrvCode,
                SrvName: echo.SrvName,
                SrvCusCharge: echo.SrvCusCharge,
                Currency: echo.Currency,

                State_rep_code: echo.State_rep_code,
                ResMessage: echo.ResMessage,
                ReqStatus: echo.ReqStatus,
                ReqNo: echo.ReqNo,
                         
              }
              setForm({
                ...form,
                Reqservice_type: echo.Reqservice_type,     
                Reqid: echo.Reqid, 
                srv_id: echo.srv_id,   
                ...vendorInformation,    
                ...echo          
              })
            
          }
  
          return true
        }   
  
        if (Reqid) {
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_notes_and_invoice_info?Reqid=${Reqid}`
              )
  
              const data = await response.json()
              await setFormData(data);
              try {
                const fetchDataEditData = async () => {
                  const response = await fetch(
                    `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_notes_and_invoice_info?Reqid=${Reqid}`
                  )
      
                  const data = await response.json()
                  setFormData(data)
                }
      
                fetchDataEditData(data?.data)
              } catch (e) {}
            }
  
            fetchDataEditData()
          } catch (e) {}
        }

      

      }
    }, [activeTab]) 
   
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "vendorService":           
              setForm({
                ...form,
                [name]: value,
              })
          break 
          case "stepList":
        setForm({
          ...form,
          ServiceListofSteps: form.ServiceListofSteps.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break       
         default:
          null
      }
    }
  
      // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)
  
    setForm({ ...form, [name]: result.files?.[0]?.url })
  }
  
        
      const handleFormSubmit = async () => {
        switch (activeTab) {
           case 1:           
            try {
              setApiStatus({ ...apiStatus, inProgress: true })
               
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_notes_invoice_bp`;
                
              const data = {              
                Reqid: Reqid, 
                BPReqStatus: form.BPReqStatus,                      
                ReqNotes: form.ReqNotes,
                DeliveryDate: form.DeliveryDate, 
                Delivery_Notes: form.Delivery_Notes,
                InvoiceDate: form.InvoiceDate, 
                InvoiceNotes: form.InvoiceNotes,   
                bp_attachment: form.bp_attachment,     
                                  
              }
  
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
                
              setApiStatus({ ...apiStatus, inProgress: true })
  
              const response = await fetch(url, options, {})
  
              const addOrUpdatenotesandinvoiceInformation = await response.json()
  
              if (addOrUpdatenotesandinvoiceInformation?.success === true) {
                setApiStatus({ ...apiStatus, inProgress: false })
                setactiveTab(4)
                localStorage.setItem("tab", 4)                
                showMessage(addOrUpdatenotesandinvoiceInformation.message, "success")
              } else {
                showMessage(addOrUpdatenotesandinvoiceInformation?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
              }
            } catch (error) {}    
          break
           
        }    
      }
    
  
   
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
            <h4 className="font-size-18">EDIT REQUEST SERVICE</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Operations</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Edit Request Service</a>
              </li>
            </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                        
                          <TabPane tabId={1}>
                          <Form className="mb-4"> 
                                <div className="row"> 

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Bus_Name"
                                            className="form-label"
                                        >
                                            Req #
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Bus_Name"
                                            id="Bus_Name"
                                            placeholder="Req #"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.ReqNo}
                                            disabled

                                        />
                                        
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Phone"
                                            className="form-label"
                                        >
                                            BP Req Date
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="Phone"
                                            id="Phone"
                                            placeholder="Business Phone"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.BPReq_Date}
                                            disabled

                                        />
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">                                        
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="SrvType"
                                            className="form-label"
                                        >
                                            Service Type
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="SrvType"
                                            id="SrvType"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.SrvType}
                                            disabled
                                        />
                                        </div>
                                    </div> 
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="SrvCode"
                                            className="form-label"
                                        >
                                            Service Code
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="SrvCode"
                                            id="SrvCode"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.SrvCode}
                                            disabled
                                        />
                                        </div>
                                    </div> 
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="SrvName"
                                            className="form-label"
                                        >
                                            Service Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="SrvName"
                                            id="SrvName"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.SrvName}
                                            disabled
                                        />
                                        </div>
                                    </div>    

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Bus_Name"
                                            className="form-label"
                                        >
                                           Customer First Name
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Bus_Name"
                                            id="Bus_Name"
                                            placeholder="Customer First Name"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.Bus_Name}
                                            disabled
                                        />
                                        
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Phone"
                                            className="form-label"
                                        >
                                            Customer Middle Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Phone"
                                            id="Phone"
                                            placeholder="Customer Middle Name"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.Phone}
                                            disabled
                                        />
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="EmailID"
                                            className="form-label"
                                        >
                                            Customer Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="EmailID"
                                            id="EmailID"
                                            placeholder="Customer Last Name"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.EmailID}
                                            disabled
                                        />
                                        </div>
                                    </div> 


                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="EmailID"
                                            className="form-label"
                                        >
                                            Customer Email Address
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="EmailID"
                                            id="EmailID"
                                            placeholder="Customer Email Address"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.CustomerEmailID}
                                            disabled
                                        />
                                        </div>
                                    </div> 

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="EmailID"
                                            className="form-label"
                                        >
                                            Customer Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="EmailID"
                                            id="EmailID"
                                            placeholder="Customer Phone Number"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.CustomerPhone}
                                            disabled
                                        />
                                        </div>
                                    </div>  

                                     

                                    <div className="col-md-4">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="BPReqStatus" className="form-label">
                                            Request Status
                                            </label>
                                            <select
                                                className="form-select"
                                                id="BPReqStatus"
                                                name="BPReqStatus"                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.BPReqStatus}                      > 
                                                <option value="In Processing" >In Processing</option> 
                                                <option value="Delivered">Delivered</option> 
                                                <option value="Invoiced" >Invoiced</option>                      
                                            </select>                     
                                            </div>
                                    </div> 

                                    <div className="col-md-12">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="ReqMessage" className="form-label">
                                            Message
                                            </label>
                                            <textarea
                                            className="form-control"
                                            rows="5"
                                            for="validationTooltip16"
                                            name="ReqMessage"
                                            onkeypress="check_length(this.form);"
                                            onkeydown="check_length(this.form);"
                                            onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.ReqMessage} 
                                                disabled  
                                            cols="30"
                                            ></textarea>
                                            <span className="help-inline col-xs-12 col-sm-7"></span>
                                            </div>
                                    </div>                           


                                    <div className="col-md-12">
                                      <label
                                          htmlFor="ReqNotes"
                                          className="form-label"
                                      >
                                          Request Notes
                                          
                                      </label>
                                      <textarea
                                          id="ReqNotes"
                                          name="ReqNotes"
                                          rows="3"
                                          className="form-control"
                                          placeholder="Request Notes"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={cusFeedback?.ReqNotes}
                                          
                                      ></textarea>
                                          
                                    </div>
                                    

                                    <div className="col-md-4">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="DeliveryDate" className="form-label">
                                            Delivery Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="DeliveryDate"
                                                id="DeliveryDate"                                                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.DeliveryDate}
                                                
                                            />
                                            </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Delivery_Notes"
                                            className="form-label"
                                        >
                                            Delivery Notes
                                            
                                        </label>
                                        <textarea
                                            id="Delivery_Notes"
                                            name="Delivery_Notes"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Delivery Notes"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.Delivery_Notes}
                                            
                                        ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="InvoiceDate" className="form-label">
                                            Invoice Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="InvoiceDate"
                                                id="InvoiceDate"                                                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.InvoiceDate}
                                                
                                            />
                                            </div>
                                    </div>
                                     <div className="col-md-8">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="InvoiceNotes"
                                            className="form-label"
                                        >
                                            Invoice Notes
                                            
                                        </label>
                                        <textarea
                                            id="InvoiceNotes"
                                            name="InvoiceNotes"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Invoice Notes"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.InvoiceNotes}
                                            
                                        ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="sofInvoiceDate" className="form-label">
                                            SOF Invoice Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="sofInvoiceDate"
                                                id="sofInvoiceDate"                                                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.sofInvoiceDate}
                                                disabled
                                                
                                            />
                                            </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="sofInvoiceNotes"
                                            className="form-label"
                                        >
                                            SOF Invoice Notes
                                            
                                        </label>
                                        <textarea
                                            id="PackageDetails"
                                            name="sofInvoiceNotes"
                                            rows="3"
                                            className="form-control"
                                            placeholder="SOF Invoice Notes"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.sofInvoiceNotes}
                                            disabled
                                        ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="CusFeedBack"
                                            className="form-label"
                                        >
                                            Customer Feedback
                                            
                                        </label>
                                        <textarea
                                            id="CusFeedBack"
                                            name="CusFeedBack"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Customer Feedback"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.CusFeedBack}
                                            disabled
                                        ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                          <div className="mb-3 position-relative">
                                          <label htmlFor="CusRating" className="form-label">
                                          Customer CusRating
                                          </label>
                                          <select
                                              className="form-select"
                                              id="CusRating"
                                              name="CusRating"                                        
                                              onChange={e => handleForm(e, "vendorService")}
                                              value={cusFeedback?.CusRating}  
                                              disabled                                                                 >                        
                                              <option value="">Select Rating</option>  
                                              <option value="1" >1</option>
                                              <option value="2" >2</option>
                                              <option value="3" >3</option>
                                              <option value="4" >4</option>
                                              <option value="5" >5</option>
                                                                    
                                          </select>                     
                                          </div>
                                    </div>
                                    

                                    <div className="col-md-4">
                                      <div className="mb-3 position-relative">
                                         <label htmlFor="CusRating" className="form-label">
                                         Upload Attachment
                                          </label>
                                    <input
                                      type="file"
                                      name="bp_attachment"
                                      accept="image/*"
                                      className="upload"
                                      id="bp_attachment"
                                      //style={{ display: "none" }}
                                      onChange={handleImage}
                                    />                                   
                                   </div>
                                  </div>

                                  
                                   <div className="col-md-4">
                                     <div className="mb-3 position-relative">                                         
                                          <Button  color="primary">
                                          Attachment
                                           </Button>                                          
                                      </div>
                                   </div>


                                </div>
                              </Form>
                          </TabPane>  
                        </TabContent>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {Reqid || Reqid ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Button
                        type="button"
                        className="btn btn-secondary waves-effect"
                        onClick={() => {
                          history(-1)
                        }}
                      >
                        Cancel
                      </Button>                 
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default EditVendorRequestService
  