import FamilyInformationTable from "components/DataTables/FamilyInformationTable"


import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"
import ReactFamilyTree from "react-family-tree"
import { Container } from "reactstrap"
import FamilyNode from "./FamilyNode"
import nodesData from "./data.json"

const ExtendedFamily = () => {

  const [familyInformation, setFamilyInformation] = useState({ data: [] })
  const [nodes, setNodes] = useState([])

  const WIDTH = 260
  const HEIGHT = 280
  const rootId = "0"

  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">EXTENDED FAMILY</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Extended Family</a>
              </li>
            </ol>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div>
                  <Link
                    to="/family-tree"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Back to Family Tree
                  </Link>
                </div>
                <h4 className="font-size-18">Extended Family Tree</h4>
                {nodesData.length > 0 && (
                  <ReactFamilyTree
                    nodes={nodesData}
                    rootId={rootId}
                    width={WIDTH}
                    height={HEIGHT}
                    canvasClassName="'tree'"
                    renderNode={node => (
                      <FamilyNode
                        key={node.id}
                        node={node}
                        isRoot={node.id === rootId}
                        style={{
                          width: WIDTH,
                          height: HEIGHT,
                          transform: `translate(${node.left * (WIDTH / 2)}px, ${
                            node.top * (HEIGHT / 2)
                          }px)`,
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExtendedFamily
