import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Container } from "reactstrap"
import "../../../../src//styles/errorfield.scss"
import { FileUpload } from "helpers/file_uploads/upload_handler"


const initialFormDetails = {
  user_fname: "",
  user_mname: "",
  user_lname: "",
  gender: "",
  user_email: "",
  user_phone: "",
  user_address: "",
  user_country: "",
  user_zip: "",
  DOB: "",
  regionsList1: [],
  regionsList2: [],
  addres1: "",
  addres2: "",
  city: "",
  region: "",
  ac_st1: "",
  ac_st2: "",
  ac_city: "",
  ac_country: "",
  ac_region: "",
  ac_zip: "",
}

const PersonalInformation = () => {
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })
  const history = useNavigate()
  const [errors, setErrors] = useState({})

  async function fetchRegionsList(name, countryName, isFetch) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
      )

      const data = await response.json()
      if (isFetch) {
        return data?.data?.length ? data.data : []
      }

      if (name === "user_country") {
        setForm({
          ...form,
          regionsList1: data?.data?.length ? data.data : [],
          region: data.data[0]?.region || "",
          [name]: countryName,
        })
      } else {
        setForm({
          ...form,
          regionsList2: data?.data?.length ? data.data : [],
          ac_region: data.data[0]?.region || "",
          [name]: countryName,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // --- fetch personal information --- //

  async function fetchPersonalInfo() {
    try {
      const userData = JSON.parse(localStorage.getItem("userData")).user
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userData.user_id}`
      )

      const data = await response.json()
      
      if (data?.success) {
        const personalInfo = data.data
        userData.user = personalInfo;
        localStorage.setItem("userData", JSON.stringify(userData));
        const regionsList1 = personalInfo.country
          ? await fetchRegionsList("user_country", personalInfo.country, true)
          : []
        const regionsList2 = personalInfo.ac_country
          ? await fetchRegionsList("ac_country", personalInfo.ac_country, true)
          : []

        const decryptDob = personalInfo.dob;
        const decryptedUserName = personalInfo.user_name;

        setForm({
          ...form,
          user_id: personalInfo.user_id,
          user_key: personalInfo.user_key,
          user_name: decryptedUserName,
          user_fname: personalInfo.user_fname,
          user_mname: personalInfo.user_mname,
          user_lname: personalInfo.user_lname,
          gender: personalInfo.gender,
          user_email: personalInfo.user_email,
          user_phone: personalInfo.user_phone,
          user_country: personalInfo.country,
          regionsList1: regionsList1,
          regionsList2: regionsList2,
          user_zip: personalInfo.zip,
          DOB: decryptDob,
          profile_pic: personalInfo.profile_pic,
          addres1: personalInfo.addres1,
          addres2: personalInfo.addres2,
          city: personalInfo.city,
          region: personalInfo.region,
          ac_st1: personalInfo.ac_st1,
          ac_st2: personalInfo.ac_st2,
          ac_city: personalInfo.ac_city,
          ac_country: personalInfo.ac_country,
          ac_region: personalInfo.ac_region,
          ac_zip: personalInfo.ac_zip,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPersonalInfo()
  }, [])
  // --- fetch relationships list end here --- //

  const handleForm = e => {
    let { name, value } = e.target

    if (name === "user_country") {
      fetchRegionsList(name, value)
    } else if (name === "ac_country") {
      fetchRegionsList(name, value)
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  const handleImage = async e => {
    const { files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, profile_pic: result.files?.[0]?.url });
  }

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    if (!form.user_fname.trim()) {
      newErrors.user_fname = "First Name is required"
      isValid = false
    }

    if (!form.user_lname.trim()) {
      newErrors.user_lname = "Last Name is required"
      isValid = false
    }

    if (!form.DOB.trim()) {
      newErrors.DOB = "Date of Birth is required"
      isValid = false
    }

    if (!form.user_phone.trim()) {
      newErrors.user_phone = "Phone number is required"
      isValid = false
    } else if (!/^\d{10}$/.test(form.user_phone.trim())) {
      newErrors.user_phone = "Invalid phone number"
      isValid = false
    }

    if (!form.addres1.trim()) {
      newErrors.addres1 = "Address  is required"
      isValid = false
    }

    if (!form.city.trim()) {
      newErrors.city = "City is required"
      isValid = false
    }

    if (form.user_country === "") {
      newErrors.user_country = "Country is required"
      isValid = false
    }

    if (form.region === "") {
      newErrors.region = "Region is required"
      isValid = false
    }

    if (form.user_country == "" && (form.user_zip || !form.user_zip)) {
      newErrors.user_zip = "Select the Country"
      isValid = false
    } else if (
      !/^\d{5}(?:[-\s]\d{4})?$/.test(form.user_zip.trim()) &&
      form.user_country == "USA"
    ) {
      newErrors.user_zip = "Invalid Zipcode"
      isValid = false
    } else if (
      !/^[1-9][0-9]{5}$/.test(form.user_zip.trim()) &&
      form.user_country == "INDIA"
    ) {
      newErrors.user_zip = "Invalid Zipcode"
      isValid = false
    }

    setErrors(newErrors)
    // console.log(errors);
    // console.log(isValid);
    return isValid
  }

  const handleFormSubmit = async () => {
    validateForm()
    if (
      !!form.user_fname &&
      !!form.user_lname &&
      !!form.DOB &&
      !!form.user_phone
    ) {
      const data = {
        user_phone: form.user_phone,
        profile_pic: form.profile_pic,
        user_fname: form.user_fname,
        user_mname: form.user_mname,
        user_lname: form.user_lname,
        dob: form.DOB,
        gender: form.gender,
        addres1: form.addres1,
        addres2: form.addres2,
        city: form.city,
        region: form.region,
        country: form.user_country,
        zip: form.user_zip,
        ac_st1: form.ac_st1,
        ac_st2: form.ac_st2,
        ac_city: form.ac_city,
        ac_country: form.ac_country,
        ac_region: form.ac_region,
        ac_zip: form.ac_zip,
      }

      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/update_personal_info`
        data.user_id = JSON.parse(localStorage.getItem("userData")).user.user_id

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const updatePersonalInfo = await response.json()

        if (updatePersonalInfo?.success === true) {
          showMessage(updatePersonalInfo.message, "success")
          fetchPersonalInfo();
        } else {
          showMessage(updatePersonalInfo?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(e, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">PERSONAL INFORMATION</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Personal Information</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="user_fname" className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_fname"
                      id={
                        errors.user_fname ? "invalid-inputfield" : "user_fname"
                      }
                      placeholder="First Name"
                      required
                      onChange={handleForm}
                      value={form.user_fname}
                    />
                    {errors.user_fname && (
                      <div className="invalid-input">{errors.user_fname}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="user_mname" className="form-label">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_mname"
                      id="user_mname"
                      placeholder="Middle Name"
                      onChange={handleForm}
                      value={form.user_mname}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="user_lname" className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_lname"
                      id={
                        errors.user_lname ? "invalid-inputfield" : "user_lname"
                      }
                      placeholder="Last Name"
                      required
                      onChange={handleForm}
                      value={form.user_lname}
                    />
                    {errors.user_fname && (
                      <div className="invalid-input">{errors.user_lname}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip01" className="form-label">
                      Gender
                    </label>
                    <select
                      className="form-select"
                      id="validationTooltip01"
                      name="gender"
                      onChange={handleForm}
                      value={form.gender}
                    >
                      <option value="" defaultValue>
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip02" className="form-label">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="DOB"
                      id={errors.DOB ? "invalid-inputfield" : "DOB"}
                      placeholder="Date of Birth"
                      required
                      onChange={handleForm}
                      value={form.DOB}
                      max={new Date().toISOString().split('T')[0]}
                    />
                    {errors.user_fname && (
                      <div className="invalid-input">{errors.DOB}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip03" className="form-label">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      name="user_phone"
                      id={
                        errors.user_phone ? "invalid-inputfield" : "user_phone"
                      }
                      placeholder="Phone Number"
                      required
                      onChange={handleForm}
                      value={form.user_phone}
                    />
                    {errors.user_fname && (
                      <div className="invalid-input">{errors.user_phone}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="validationTooltipUsername"
                      className="form-label"
                    >
                      Email Address
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          @
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="user_email"
                        id="validationTooltipUsername"
                        placeholder="Email Address"
                        aria-describedby="validationTooltipUsernamePrepend"
                        disabled
                        onChange={handleForm}
                        value={form.user_email}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip23" className="form-label">
                      User Key
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_key"
                      id="validationTooltip23"
                      placeholder="User Key"
                      disabled
                      onChange={handleForm}
                      value={form.user_key}
                    />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip23" className="form-label">
                      User Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_name"
                      id="validationTooltip23"
                      placeholder="User Name"
                      disabled
                      onChange={handleForm}
                      value={form.user_name}
                    />
                  </div>
                </div>

                <div className="col-md-2">
                  <img
                    id="output_image"
                    className="rounded avatar-lg card-img img-thumbnail border border-2 p-0 d-inline-block"
                    alt="Your Image"
                    width="100"
                    height="100"
                    src={form.profile_pic || "default-profile.png"}
                  />
                </div>

                <div className="col-md-2">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="validationTooltipUsername"
                      className="form-label"
                    >
                      User Image
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        name="profile_pic"
                        accept="image/*"
                        className="upload"
                        id="img"
                        style={{ display: "none" }}
                        onChange={handleImage}
                      />
                      <label htmlFor="img">
                        <p className="btn btn-info">Upload Image </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Preferred Address</h4>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom05"
                          className="form-label"
                        >
                          Street 1 <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="addres1"
                          id={errors.addres1 ? "invalid-inputfield" : "addres1"}
                          placeholder="Street 1"
                          required
                          value={form.addres1}
                          onChange={handleForm}
                        />
                        {errors.user_fname && (
                          <div className="invalid-input">{errors.addres1}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom06"
                          className="form-label"
                        >
                          Street 2
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="addres2"
                          id="validationCustom06"
                          placeholder="Street 2"
                          value={form.addres2}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom07"
                          className="form-label"
                        >
                          City <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          id={errors.city ? "invalid-inputfield" : "city"}
                          placeholder="City"
                          required
                          value={form.city}
                          onChange={handleForm}
                        />
                        {errors.city && (
                          <div className="invalid-input">{errors.city}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="user_country" className="form-label">
                          Country <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="user_country"
                          id={
                            errors.user_country
                              ? "invalid-inputfield"
                              : "user_country"
                          }
                          required
                          value={form.user_country}
                          onChange={handleForm}
                        >
                          <option value="" defaultValue>Select Country</option>
                          <option value="USA">
                            USA
                          </option>
                          <option value="INDIA">INDIA</option>
                        </select>
                        {errors.user_country && (
                          <div className="invalid-input">
                            {errors.user_country}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="region" className="form-label">
                          Region <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="region"
                          id={errors.region ? "invalid-inputfield" : "Region"}
                          value={form.region}
                          onChange={handleForm}
                        >
                          {form.regionsList1.length === 0 ? (
                            <option value="" defaultValue>
                              Select Region
                            </option>
                          ) : (
                            
                            <>
                            <option value="" defaultValue>
                              Select Region
                            </option>
                            { form.regionsList1.map(each => (                              
                              <option value={each.region} key={each.region}>
                                {each.region}
                              </option>
                            )) }
                            </>
                          )}
                        </select>
                        {errors.region && (
                          <div className="invalid-input">{errors.region}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom07"
                          className="form-label"
                        >
                          Zip <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="user_zip"
                          id={errors.user_zip ? "invalid-inputfield" : "Zip"}
                          placeholder="Zip"
                          value={form.user_zip}
                          onChange={handleForm}
                        />
                        {errors.user_zip && (
                          <div className="invalid-input">{errors.user_zip}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Secondary Address</h4>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom09"
                          className="form-label"
                        >
                          Street 1
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ac_st1"
                          id="validationCustom09"
                          placeholder="Street 1"
                          value={form.ac_st1}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom10"
                          className="form-label"
                        >
                          Street 2
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ac_st2"
                          id="validationCustom10"
                          placeholder="Street 2"
                          value={form.ac_st2}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom011"
                          className="form-label"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ac_city"
                          id="validationCustom011"
                          placeholder="City"
                          value={form.ac_city}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="ac_country" className="form-label">
                          Country
                        </label>
                        <select
                          className="form-select"
                          name="ac_country"
                          id="ac_country"
                          value={form.ac_country}
                          onChange={handleForm}
                        >
                          <option value="">Select Country</option>
                          <option value="USA">USA</option>
                          <option value="INDIA">INDIA</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="ac_region" className="form-label">
                          Region
                        </label>
                        <select
                          className="form-select"
                          name="ac_region"
                          id="ac_region"
                          value={form.ac_region}
                          onChange={handleForm}
                        >
                          {form.regionsList2.length === 0 ? (
                            <option value="" defaultValue>
                              Select Region
                            </option>
                          ) : (
                            <>
                            <option value="" defaultValue>
                              Select Region
                            </option>
                            { form.regionsList2.map(each => (
                              <option value={each.region} key={each.region}>
                                {each.region}
                              </option>
                            ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="validationCustom07"
                          className="form-label"
                        >
                          Zip{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="ac_zip"
                          id="validationCustom07"
                          regex="^[1-9]{1}[0-9]{2}\\s{0, 1}[0-9]{3}$"
                          placeholder="Zip"
                          value={form.ac_zip}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light me-1"
                    onClick={handleFormSubmit}
                  >
                    Save
                  </button>
                  &nbsp; &nbsp;
                  <Link to="/" className="btn btn-secondary waves-effect">
                    Cancel
                  </Link>
                  &nbsp; &nbsp;
                  <Link
                    to="/changepassword"
                    className="btn btn-primary waves-effect waves-light me-1"
                  >
                    Change Password
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PersonalInformation
