import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import "../../../../src//styles/errorfield.scss"
  import RequestServiceFilesTable from "components/DataTables/RequestServiceFilesTable"
  import ServiceListofStepsTable from "components/DataTables/ServiceListofStepsTable"
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { randomId } from "helpers/randomGen"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
  const ServiceListofSteps = lazy(() =>
    import("components/Customers/Operations/ServiceListofSteps")
  )
  
  const initialFormDetails = {
                 
    scode: "",
    sname: "",
    ServiceType: "",       
    descrip: "",
    amount: "",
    sofamount: "",
    subtype: "",
    Features: "",
    PackageDetails: "",
    chargecustomer: "",
    Currency: "",  
    Reqservice_type:"",
    ReqDate:"",
    srv_id:"",

    ServiceListofSteps: [],    

  }
  
  const EditVendorRequestService = () => {
    const [activeTab, setactiveTab] = useState(
      parseInt(JSON.parse(localStorage.getItem("tab"))) || 1
    )
    const [form, setForm] = useState(initialFormDetails);
    const [cusFeedback, setCusFeedback] = useState(null);
   
    const [typeofservicelist, setTypeofservicelist] = useState([]);
    const [apiStatus, setApiStatus] = useState({ inProgress: false })

    // const typeofservicelist = JSON.parse(localStorage.getItem("typeofservicelist"))
  


    async function fetchStepListData() {
      try {
        const url = `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/service_steps_list?vsl_id=${vslid}`
  
        const response = await fetch(url)
  
        const data = await response.json()
  
        const decryptedData = data?.data?.map((each, idx) => {
          return {
            ...each,
            number: idx + 1,
            step_name: each.step_name || "",
            ss_sdesc: each.ss_sdesc || "",  
          }
        })
  
        setTimeout(() => {
          setForm(prev => {
            return {
              ...prev,
              ServiceListofSteps: decryptedData || [],
            }
          })
        }, [500])
      } catch (err) {}
    }   
  
    useEffect(() => {
      if (activeTab == 3) {
        fetchStepListData()
      }
    }, [])


    async function fetchNotesData() {
      try {
        const url = `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/get_notes_and_invoice_info?Reqid=${Reqid}`
  
        const response = await fetch(url)
        const data = await response.json()
        console.log(data)
        return data?.data[0]
         
      } catch (err) {}
    }   
  
    useEffect(async() => {
      if (activeTab == 4) {
        const res = await fetchNotesData()
        console.log(res)
        setCusFeedback(res)
      }
    }, [])

      
  // -- Fetch Type of Service list start -- //
  async function fetchTypeofService() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/typeofservice_list`
      )
      const data = await response.json()
      return data?.data
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    async function fetchData(){
      const data = await fetchTypeofService();
      setTypeofservicelist(data);
    }
    fetchData();
  }, [])
  
    
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const Reqid = searchParams.get("Reqid")
    const type = searchParams.get("type")    
    
  
   
  
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const echo = data?.data;                        
              
              const vendorInformation = {
                ...echo,
                //Reqid: echo.Reqid,
                ResReadStatus: echo.ResReadStatus,
                ReqStatus: echo.ReqStatus,                 
                Reqservice_type: echo.Reqservice_type,
                ReqSubject: echo.ReqSubject,
                ReqMessage: echo.ReqMessage,

                
                BusKey: echo.BusKey,
                Bus_Name: echo.Bus_Name,
                Phone: echo.Phone,
                EmailID: echo.EmailID,
                RepName: echo.RepName,

                SrvType: echo.SrvType,
                SrvCode: echo.SrvCode,
                SrvName: echo.SrvName,
                SrvCusCharge: echo.SrvCusCharge,
                Currency: echo.Currency,

                State_rep_code: echo.State_rep_code,
                ResMessage: echo.ResMessage,
                ReqStatus: echo.ReqStatus,
                ReqNo: echo.ReqNo,

                         
              }
              setForm({
                ...form,
                Reqservice_type: echo.Reqservice_type,     
                Reqid: echo.Reqid, 
                srv_id: echo.srv_id,   
                ...vendorInformation,    
                ...echo          
              })
            
          }
  
          return true
        }   
  
        if (Reqid) {
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_vendor_request_services_by_reqid?Reqid=${Reqid}`
              )
  
              const data = await response.json()
              await setFormData(data);
              try {
                const fetchDataEditData = async (record) => {
                  const response = await fetch(
                    `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_services_steps_by_reqid?srv_id=${record.srv_id}`
                  )
      
                  const data = await response.json()
                   setFormData(data)
                
                }
      
                fetchDataEditData(data?.data)
              } catch (e) {}
            }
  
            fetchDataEditData()
          } catch (e) {}
        }

        if (Reqid) {          
        }

      }
    }, [activeTab])
  
   
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "vendorService":           
              setForm({
                ...form,
                [name]: value,
              })
          break 
        case "stepList":
        setForm({
          ...form,
          ServiceListofSteps: form.ServiceListofSteps.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break       
         default:
          null
      }
    }
  
      // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)
    sof_attachment
    setForm({ ...form, [name]: result.files?.[0]?.url })
  }
  
        
      const handleFormSubmit = async () => {
        switch (activeTab) {
          case 1:           
              try {
                setApiStatus({ ...apiStatus, inProgress: true })
                 
              const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_request_service`;
                  
                const data = {              
                  Reqid: Reqid,   
                  user_id: userId,             
                  ResReadStatus: form.ResReadStatus,
                  ReqStatus: form.ReqStatus,
                  Reqservice_type: form.Reqservice_type,                
                  ReqSubject: form.ReqSubject,
                  ReqMessage: form.ReqMessage,
                  // ResSubject: form.ResSubject,
                  // ResReadStatus: form.ResReadStatus,
                  
                }
    
                const options = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(data),
                }
                  
                setApiStatus({ ...apiStatus, inProgress: true })
    
                const response = await fetch(url, options, {})
    
                const addOrUpdaterequestInformation = await response.json()
    
                if (addOrUpdaterequestInformation?.success === true) {
                  setApiStatus({ ...apiStatus, inProgress: false })
                  setactiveTab(2)
                  localStorage.setItem("tab", 2)                  
                  showMessage(addOrUpdaterequestInformation.message, "success")
                } else {
                  showMessage(addOrUpdaterequestInformation?.message, "error", false)
                  setApiStatus({ ...apiStatus, inProgress: false })
                }
              } catch (error) {}      
            break  
          case 2:           
            try {
              setApiStatus({ ...apiStatus, inProgress: true })
               
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_vendor_business_details`;
                
              const data = {              
                Reqid: Reqid,    
                user_id: userId, 
                ReqStatus: form.ReqStatus,
                State_rep_code: form.State_rep_code,
                ResMessage: form.ResMessage,
                ReqNo: form.ReqNo,
              }
  
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
                
              setApiStatus({ ...apiStatus, inProgress: true })
  
              const response = await fetch(url, options, {})
  
              const addOrUpdateServiceInformation = await response.json()
  
              if (addOrUpdateServiceInformation?.success === true) {
                setApiStatus({ ...apiStatus, inProgress: false })
                setactiveTab(3)
                localStorage.setItem("tab", 3)                
                showMessage(addOrUpdateServiceInformation.message, "success")
              } else {
                showMessage(addOrUpdateServiceInformation?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
              }
            } catch (error) {}    
          break
          case 4:           
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
             
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_notes_invoice_sof`;
              
            const data = {              
              Reqid: Reqid,                
              sofInvoiceDate: form.sofInvoiceDate,
              sofInvoiceNotes: form.sofInvoiceNotes,                       
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
              
            setApiStatus({ ...apiStatus, inProgress: true })

            const response = await fetch(url, options, {})

            const addOrUpdatenotesandinvoiceInformation = await response.json()

            if (addOrUpdatenotesandinvoiceInformation?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(4)
              localStorage.setItem("tab", 4)                
              showMessage(addOrUpdatenotesandinvoiceInformation.message, "success")
            } else {
              showMessage(addOrUpdatenotesandinvoiceInformation?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}    
        break
        }    
      }
   
      const generateInvoice = async() => {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/generate_customer_invoice`;
              
        const data = {              
          Reqid: Reqid,          
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const addOrUpdatenotesandinvoiceInformation = await response.json()

        if (addOrUpdatenotesandinvoiceInformation?.success === true) {
                       downloadURI(addOrUpdatenotesandinvoiceInformation.url);
          showMessage(addOrUpdatenotesandinvoiceInformation.message, "success")
        } else {
          showMessage(addOrUpdatenotesandinvoiceInformation?.message, "error", false)
          
        }
    
      }

      const downloadURI = (uri) => {
        const link = document.createElement('a');
       link.setAttribute('download', '');
       link.setAttribute('target', '_blank');
       link.href = uri;
       document.body.appendChild(link);
       link.click();
       link.remove();
        };

    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
            <h4 className="font-size-18">EDIT REQUEST SERVICE</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Operations</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Edit Request Service</a>
              </li>
            </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                         
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                                localStorage.setItem("tab", 1)
                              }}
                            >
                              
                              Request info
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                                localStorage.setItem("tab", 2)
                              }}
                            >
                              
                              Business Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={async () => {
                                setactiveTab(3)
                                localStorage.setItem("tab", 3)
                                await fetchStepListData()
                              }}
                            >
                             
                             Package Details
                            </NavLink>
                          </NavItem>
  
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={async () => {
                                setactiveTab(4)
                                localStorage.setItem("tab", 4)                               
                              }}
                            >
                              
                              Notes & Invoice
                            </NavLink>
                          </NavItem>  
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {/**4,5,6 dynamic forms and tables */}
                         
                          <TabPane tabId={1}>     

                            <Form className="mb-4">   
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="user_fname" className="form-label">
                                            Name of the Person
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="user_fname"
                                                id=""
                                                placeholder="First Name"
                                                required
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.userName} 
                                                disabled
                                            />
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="ReqDate" className="form-label">
                                            Date
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="ReqDate"
                                                id="ReqDate"
                                                value={form.ReqDate}                         
                                                onChange={e => handleForm(e, "vendorService")}
                                                disabled
                                            />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="Reqservice_type" className="form-label">
                                            Type of Service <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                id="Reqservice_type"
                                                name="Reqservice_type"
                                                required
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.Reqservice_type}
                                            >
                                                <option value="" selected="">
                                                Select Type of Service
                                                </option>
                                                
                                                {typeofservicelist.map(each => (
                                                <option key={each.type} value={each.type}>
                                                {each.type}
                                                </option> 
                                            ))}
                                            </select>
                                            
                                            </div>
                                        </div> 


                                        <div className="col-md-6">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="ReqNo" className="form-label">
                                            Request #
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="ReqNo"
                                                id="ReqNo"
                                                placeholder=""
                                                required
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.ReqNo}
                                                disabled
                                            />
                                            
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="ResReadStatus" className="form-label">
                                            Read Status
                                            </label>
                                            <select
                                                className="form-select"
                                                id="ResReadStatus"
                                                name="ResReadStatus"                                                
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.ResReadStatus}                      >                        
                                                <option value="Read" >Read</option>
                                                <option value="Unread" >Unread</option>                        
                                            </select>                     
                                            </div>
                                        </div> 

                                        <div className="col-md-6">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="ReqStatus" className="form-label">
                                            Request Status
                                            </label>
                                            <select
                                                className="form-select"
                                                id="ReqStatus"
                                                name="ReqStatus"                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.ReqStatus}                      >                        
                                                <option value="Open" >Open</option>  
                                                <option value="Business Assigned" >Business Assigned</option>
                                                <option value="Customer Rejected" >Customer Rejected</option>
                                                <option value="Amount Paid" >Amount Paid</option>
                                                <option value="Business Accepted" >Business Accepted</option>
                                                <option value="Business Rejected">Business Rejected</option>
                                                <option value="Business Reassigned" >Business Reassigned</option> 
                                                <option value="In Processing" >In Processing</option> 
                                                <option value="Delivered">Delivered</option> 
                                                <option value="Invoiced" >Invoiced</option>                      
                                            </select>                     
                                            </div>
                                        </div> 

                                        <div className="col-md-6">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="ReqSubject" className="form-label">
                                            Subject
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="ReqSubject"
                                                id="validationTooltip02"
                                                placeholder="Subject"
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.ReqSubject}                        
                                            />
                                            <span className="help-inline col-xs-12 col-sm-7"></span>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="Pay_status" className="form-label">
                                            Payment Status
                                            </label>
                                            <select
                                                className="form-select"
                                                id="Pay_status"
                                                name="Pay_status"                                                                                                
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.Pay_status}                      >                        
                                                <option value="Yes" >Yes</option>
                                                <option value="No" selected>No</option>                        
                                            </select>                     
                                            </div>
                                        </div> 


                                        <div className="col-md-12">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="ReqMessage" className="form-label">
                                            Message
                                            </label>
                                            <textarea
                                            className="form-control"
                                            rows="5"
                                            for="validationTooltip16"
                                            name="ReqMessage"
                                            onkeypress="check_length(this.form);"
                                            onkeydown="check_length(this.form);"
                                            onChange={e => handleForm(e, "vendorService")}
                                                value={form.ReqMessage}   
                                            cols="30"
                                            ></textarea>
                                            <span className="help-inline col-xs-12 col-sm-7"></span>
                                            </div>
                                        </div>                                                                                
                                            <div className="card">
                                            <RequestServiceFilesTable />
                                            </div>                                              
                                      </div> 
                                    
                            </Form>                      
                                                      
                          </TabPane>
                          <TabPane tabId={2}>
                              <Form className="mb-4">   
                                <div className="row">                         

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="BusKey"
                                            className="form-label"
                                        >
                                            BusKey
                                            
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="BusKey"
                                            id="BusKey"
                                            placeholder="Business Key"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.BusKey}
                                        />
                                        
  
                                        </div>
                                    </div>

                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Bus_Name"
                                            className="form-label"
                                        >
                                            Business Name
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Bus_Name"
                                            id="Bus_Name"
                                            placeholder="Business Name"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.Bus_Name}
                                        />
                                        
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Phone"
                                            className="form-label"
                                        >
                                            Business Phone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Phone"
                                            id="Phone"
                                            placeholder="Business Phone"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.Phone}
                                        />
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="EmailID"
                                            className="form-label"
                                        >
                                            Business Email
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="EmailID"
                                            id="EmailID"
                                            placeholder="Business Email"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.EmailID}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="SrvType"
                                            className="form-label"
                                        >
                                            Service Type
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="SrvType"
                                            id="SrvType"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.SrvType}
                                        />
                                        </div>
                                    </div> 
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="SrvCode"
                                            className="form-label"
                                        >
                                            Service Code
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="SrvCode"
                                            id="SrvCode"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.SrvCode}
                                        />
                                        </div>
                                    </div> 
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="SrvName"
                                            className="form-label"
                                        >
                                            Service Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="SrvName"
                                            id="SrvName"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.SrvName}
                                        />
                                        </div>
                                    </div> 
                                    
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="SrvCusCharge"
                                            className="form-label"
                                        >
                                            Charge to Customer 
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="SrvCusCharge"
                                            id="SrvCusCharge"
                                            
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.SrvCusCharge}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Currency"
                                            className="form-label"
                                        >
                                            Currency
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            id="Currency"
                                            name="Currency"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.Currency}
                                        > 
                                        <option value="" selected="">Select Currency</option>
                                        <option value="INR">INR</option>
                                        <option value="USD">USD</option>                                                            
                                        <option value="EURO">EURO</option> 
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="RepName"
                                            className="form-label"
                                        >
                                            Ops Rep Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="RepName"
                                            id="RepName"                                            
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={`${loginUserData?.user_fname} ${loginUserData?.user_lname}`}
                                            disabled
                                        />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="State_rep_code"
                                            className="form-label"
                                        >
                                            State Rep Code
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="State_rep_code"
                                            id="State_rep_code"
                                            placeholder="State Rep Code"
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.State_rep_code}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="ResMessage"
                                            className="form-label"
                                        >
                                            Response
                                            
                                        </label>
                                        <textarea
                                            id="ResMessage"
                                            name="ResMessage"
                                            rows="3"
                                            className="form-control"
                                            onChange={e => handleForm(e, "vendorService")}  
                                            value={form.ResMessage}
                                        ></textarea>
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <Link
                                              to={`/business_from_sof?id=${Reqid}&serviceType=${form.Reqservice_type}`}
                                              class="btn btn-primary waves-effect waves-light me-1"
                                            >
                                              Get Business from SOF
                                            </Link>
                                        
                                        </div>
                                    </div>


                                </div>
                              </Form> 

                         
                         
                          </TabPane>  
                          <TabPane tabId={3}>
                             <Form className="mb-4">   
                                <div className="row"> 
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Features"
                                            className="form-label"
                                        >
                                            Description of the service
                                            
                                        </label>
                                        <textarea
                                            id="descrip"
                                            name="descrip"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Financier Address"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.descrip}
                                        ></textarea>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Features"
                                            className="form-label"
                                        >
                                            Features
                                            
                                        </label>
                                        <textarea
                                            id="Features"
                                            name="Features"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Features"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.Features}
                                        ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="PackageDetails"
                                            className="form-label"
                                        >
                                            Package Details
                                            
                                        </label>
                                        <textarea
                                            id="PackageDetails"
                                            name="PackageDetails"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Package Details"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.PackageDetails}
                                        ></textarea>
                                        </div>
                                    </div>

                                           
                                </div>
                              </Form>

                                <Suspense
                                  fallback={
                                    <div className="text-center m-4">Loading...</div>
                                  }
                                >
                                  <h4 className="page-title-box">
                                    List Of Steps
                                  </h4>

                                  <ServiceListofStepsTable                                                                                                  
                                    vsl_id={Reqid}                                    
                                  />
                                </Suspense>
                            
                          </TabPane>  
                          <TabPane tabId={4}>
                          <Form className="mb-4">   
                                <div className="row"> 
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="ReqNotes"
                                            className="form-label"
                                        >
                                            Request Notes
                                            
                                        </label>
                                        <textarea
                                            id="ReqNotes"
                                            name="ReqNotes"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Request Notes"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.ReqNotes}
                                            disabled
                                        ></textarea>
                                            
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="DeliveryDate" className="form-label">
                                            Delivery Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="DeliveryDate"
                                                id="DeliveryDate"                                                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.DeliveryDate}
                                                disabled
                                            />
                                            </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="Delivery_Notes"
                                            className="form-label"
                                        >
                                            Delivery Notes
                                            
                                        </label>
                                        <textarea
                                            id="Delivery_Notes"
                                            name="Delivery_Notes"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Delivery Notes"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.Delivery_Notes}
                                            disabled
                                        ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="InvoiceDate" className="form-label">
                                            Invoice Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="InvoiceDate"
                                                id="InvoiceDate"                                                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.InvoiceDate}
                                                disabled
                                            />
                                            </div>
                                    </div>
                                     <div className="col-md-8">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="InvoiceNotes"
                                            className="form-label"
                                        >
                                            Invoice Notes
                                            
                                        </label>
                                        <textarea
                                            id="InvoiceNotes"
                                            name="InvoiceNotes"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Invoice Notes"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.InvoiceNotes}
                                            disabled
                                        ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                            <div className="mb-3 position-relative">
                                            <label htmlFor="sofInvoiceDate" className="form-label">
                                            SOF Invoice Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="sofInvoiceDate"
                                                id="sofInvoiceDate"                                                                        
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={cusFeedback?.sofInvoiceDate}
                                                
                                            />
                                            </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="sofInvoiceNotes"
                                            className="form-label"
                                        >
                                            SOF Invoice Notes
                                            
                                        </label>
                                        <textarea
                                            id="PackageDetails"
                                            name="sofInvoiceNotes"
                                            rows="3"
                                            className="form-control"
                                            placeholder="SOF Invoice Notes"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.sofInvoiceNotes}
                                        ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="CusFeedBack"
                                            className="form-label"
                                        >
                                            Customer Feedback
                                            
                                        </label>
                                        <textarea
                                            id="CusFeedBack"
                                            name="CusFeedBack"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Customer Feedback"
                                            required
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={cusFeedback?.CusFeedBack}
                                            disabled
                                        ></textarea>
                                        </div>

                                    </div>
                                        <div className="col-md-3">
                                          <div className="mb-3 position-relative">
                                          <label htmlFor="CusRating" className="form-label">
                                          Customer CusRating
                                          </label>
                                          <select
                                              className="form-select"
                                              id="CusRating"
                                              name="CusRating"                                        
                                              onChange={e => handleForm(e, "vendorService")}
                                              value={cusFeedback?.CusRating}     
                                              disabled                                                              >                        
                                              <option value="">Select Rating</option>  
                                              <option value="1" >1</option>
                                              <option value="2" >2</option>
                                              <option value="3" >3</option>
                                              <option value="4" >4</option>
                                              <option value="5" >5</option>
                                                                    
                                          </select>                     
                                          </div>
                                  </div>
                                   
                                  <div className="col-md-3">
                                     <div className="mb-3 position-relative"> 
                                          {(form.ReqStatus === 'Invoiced') ? (
                                          <a href={cusFeedback?.bp_attachment || ""} target="_blank" className="btn btn-primary waves-effect waves-light me-1">NLS Invoice</a> 
                                        ) : (
                                          
                                          <b>
                                          <Button                                            
                                            class="btn btn-primary waves-effect waves-light me-1"
                                            disabled={!(form.ReqStatus === 'Invoiced')}
                                          >
                                             NLS Invoice
                                          </Button>                                          
                                         </b>
                                        )}                                                                                   
                                      </div>
                                   </div>

                                   <div className="col-md-3">
                                      <div className="mb-3 position-relative">                                           
                                          <a href={cusFeedback?.sof_attachment || ""} target="_blank" className="btn btn-primary waves-effect waves-light me-1">Download Generated Invoice</a>                                                            
                                      </div>
                                  </div>

                                  
                                   <div className="col-md-3">
                                     <div className="mb-3 position-relative">                                         
                                          <Button  color="primary" onClick={() => generateInvoice()}>
                                          Generate Invoice
                                           </Button>                                          
                                      </div>
                                   </div>
                                   

                                    
                                </div>
                              </Form>
                          </TabPane>  
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              type="button"
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                              onClick={() => {
                                handleFormSubmit(false)
                                //toggleTab(activeTab - 1)
                              }}
                              disabled={activeTab === 1}
                            >
                              Previous
                            </Button>
                          </li>
                          <li
                            className={activeTab === 4 ? "next disabled" : "next"}
                          >
                            <Button
                              disabled={activeTab === 4}
                              type="button"
                              onClick={() => {
                                handleFormSubmit(true)
                              }}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {Reqid || Reqid ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;

                    <Link
                    to={"/vendor_request_service_list"}
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                      localStorage.removeItem("Reqid")
                      localStorage.removeItem("tab")
                    }}
                  >
                    Cancel
                  </Link>
                
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default EditVendorRequestService
  