
import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"


const GeneratedWillDocumentListTable = () => {

  const [generatedDoc, setgeneratedDoc] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_generated_will_info?user_id=${user_id}`
      )
      const data = await response.json()
      setgeneratedDoc({ ...generatedDoc, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_generated_will`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        reg_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setgeneratedDoc({
          ...generatedDoc,
          data: generatedDoc?.data?.filter(each => each.reg_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },      
      {
        label: "Simple Will",
        field: "simgplewill",
        sort: "asc",
        width: 200,
      },
      {
        label: "GDF Power of Attorney",
        field: "gdfattorney",
        sort: "asc",
        width: 100,
      },
      {
        label: "Healthcare Directive",
        field: "healthcare",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: generatedDoc?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        simgplewill:
          (
            <Link
              to={`/simple_will?gal_id=${each.gal_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              Simple Will
            </Link>
          ) || "",
          gdfattorney:
          (
            <Link
              to={`/gdf_attorney?gal_id=${each.gal_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
             GDF Power of Attorney 
            </Link>
          ) || "",
          healthcare:
          (
            <Link
              to={`/healthcare_DOC?gal_id=${each.gal_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
             Healthcare Directive
            </Link>
          ) || "",     

                  
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.reg_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.region}?`
                  ) &&
                  handleRemoveRole(each.reg_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),  
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Generated Will Document List </CardTitle>
              <MDBDataTable responsive bordered data={data} noBottomColumns={true} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GeneratedWillDocumentListTable
