import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { Suspense, lazy, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"
import "../../../../src//styles/errorfield.scss"

import {
  Spinner,
  Container,
  Form,
  NavItem,
  NavLink,
  Button,
  TabContent,
  TabPane,
} from "reactstrap"
import { loginUserData, userId } from "helpers/userId"
import { FileUpload } from "helpers/file_uploads/upload_handler"
import { change } from "redux-form" // changes

const ListofTestator = lazy(() =>
  import("components/Customers/Will/ListofTestator")
)

const ListofExecutor = lazy(() =>
  import("components/Customers/Will/ListofExecutor")
)

const ListofBeneficiaries = lazy(() =>
  import("components/Customers/Will/ListofBeneficiaries")
)

const ListofDocuments = lazy(() =>
  import("components/Customers/Will/ListofDocuments")
)

const ListofAssets = lazy(() =>
  import("components/Customers/Will/ListofAssets")
)



const initialFormDetails = {
 
  wl_name_of_document: "",
  wl_date_of_execution: "",
  wl_type_of_document: "",
  wl_original_document: "",
  wl_full_name: "",
  wl_contact: "",  
  realEstateRegionList: [],  
  relationsList: [],
  regionsList: [],      
  ListofDocuments: [],
  ListofTestator: [],
  ListofExecutor : [],
  ListofBeneficiaries: [],
  ListofAssets: [],
  ListOfExistingTestator: [],
  ListOfExistingExecutor: [],
  ListOfExistingBeneficiaries: [],
  ListOfExistingWillAssets: [],
  questions: [],
  imagesList: [],

  errors: {
    wl_name_of_document: "",
    wl_type_of_document: "",
    wl_full_name: "",    
  },
}

const AddWillInfo = () => {
  const [activeTab, setactiveTab] = useState(
    parseInt(JSON.parse(localStorage.getItem("tab"))) || 1
  )
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const relationsList = JSON.parse(localStorage.getItem("relationsList"))

  

  

  const [errors, setErrors] = useState({})
  const [changed, setChanged] = useState(undefined) // changed

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  // const wIdString = localStorage.getItem("wId");
  // const wId = wIdString ? parseInt(wIdString) : undefined;
  const wId = parseInt(JSON.parse(localStorage.getItem("wId")))

async function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        localStorage.setItem("tab", tab)
      }
    }
  }

  async function fetchRegionsList(countryName, type) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
      )

      const data = await response.json()
      if (type != 1) {
        setForm({
          ...form,
          ListofTestator: data?.data || [],
          wcil_region: data.data[0]?.region || "",
          wcil_country: countryName,
        })
      }

      return data?.data || []
    } catch (error) {
      console.log(error)
    }
  }
    // -- Fetch relationships list start -- //
  async function fetchRelationsList() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
        )
        const data = await response.json()
  
        if (data?.data) {
          localStorage.setItem("relationsList", JSON.stringify(data?.data))
        }
  
        return data?.data
      } catch (error) {
        console.log(error)
      }
    }

  async function fetchDocumentsData() {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_will_document_list?wl_id=${id}&wdl_uid=${userId}`

      const response = await fetch(url)

      const data = await response.json()

      const decryptedData = data?.data?.map((each, idx) => {
        return {
          ...each,
          number: idx + 1,
          wdl_doucment_name: each.wdl_doucment_name || "",
          wdl_type_document: each.wdl_type_document || "",
          wdl_path: each.wdl_path || "",         
        }
      })

      setForm({
        ...form,
        ListofDocuments: decryptedData || [],
      })
    } catch (err) {}
  }

  const handleRemoveDocuments = async (wdl_id, idx) => {
    try {
      if (wdl_id) {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_will_document`
        const data = {
          wdl_id: wdl_id,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})
        const removeData = await response.json()

        if (removeData?.success === true) {
          showMessage(removeData.message, "success")
          setForm({
            ...form,
            ListofDocuments: form.ListofDocuments.filter(
              each => each.wdl_id !== wdl_id
            ),
          })
        } else {
          showMessage(removeData.message, "error", false)
        }
      } else if (idx >= 0) {
        setForm({
          ...form,
          ListofDocuments: form.ListofDocuments.filter(
            (each, index) => index != idx
          ),
        })
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  useEffect(() => {
    fetchDocumentsData()
  }, [activeTab])

  useEffect(() => {
    fetchRelationsList()
  }, [])

  useEffect(() => {
    if (activeTab) {
      async function setFormData(data) {
        if (data.success) {
          const decryptedData = data?.data;

          // this.relationsList = await fetchRelationsList();
          
          if (decryptedData) {
            
            const assetInformation = {
              ...decryptedData,
              wl_name_of_document: decryptedData.wl_name_of_document,
              wl_date_of_execution: decryptedData.wl_date_of_execution,
              wl_type_of_document: decryptedData.wl_type_of_document,
              wl_original_document: decryptedData.wl_original_document,                                   
              wl_full_name: decryptedData.wl_full_name,
              wl_contact: decryptedData.wl_contact, 
            }

            let questions = []

            if (activeTab) {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_will_questions_list?wql_uid=${userId}&wl_id=${ id || wId}`
              )
              const data = await response.json()
              questions = data?.data || []
            }

            setForm({
              ...form,
              ...assetInformation,
              realEstateRegionList: await fetchRegionsList(
                assetInformation.pre_country,

                1
              ),
              questions: questions,
            })
          }
        }

        return true
      }
      if (wId && !id) {
        try {
          const fetchData = async () => {
            const response = await fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_will_user_list?wl_uid=${userId}&wl_id=${wId}`
            )

            const data = await response.json()
            await setFormData(data)
          }

          fetchData()
        } catch (e) {}
      }

      if (id) {
        try {
          const fetchDataEditData = async () => {
            const response = await fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_will_user_list?wl_uid=${userId}&wl_id=${id}`
            )

            const data = await response.json()
            setFormData(data)
          }

          fetchDataEditData()
        } catch (e) {}
      }
     
    }
  }, [activeTab])

 

  
    const handleForm = async (e, action, key) => {
    let { name, value, type, checked } = e?.target || {}
    

    switch (action) {
      case "willInfo":
        setForm({
          ...form,
          [name]: value,
          relationsList: relationsList,
        })
        break       
      case "addListofTestator":
        
        let latestNumber = 2
        if (form.ListofTestator.length) {
          let numberList = form.ListofTestator.map(each => each.number)

          latestNumber = Math.max(...numberList) + 1
        }
        form.ListofTestator.push({
          number: form.ListofTestator.length ? latestNumber : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, ListofTestator: form.ListofTestator })
        break 
      case "removeListofTestator":
        const modifiedList = form.ListofTestator.filter(
          (each, idx) => idx !== key
        )
        setForm({ ...form, ListofTestator: modifiedList })
        break
      case "testatorList":
        const modfiedListOfTestator = []

        for (let i = 0; i < form.ListofTestator.length; i++) {
          const Testator = {
            ...form.ListofTestator[i],
          }

          if (i === key) {
            let regionsList = Testator.regionsList || []
            if (name === "wcil_country") {
              regionsList =  await fetchRegionsList(value) 
              Testator.wcil_region = regionsList[0]?.region || ""
            }

            modfiedListOfTestator.push({
              ...Testator,
              [name]: value,
              regionsList,
            })
          } else {
            modfiedListOfTestator.push(Testator)
          }
        }

        setForm({
          ...form,
          ListofTestator: modfiedListOfTestator,
        })
        break  
      case "addListofExecutor":
        
        let latestNumberassetex = 2
        if (form.ListofExecutor.length) {
          let numberList = form.ListofExecutor.map(each => each.number)

          latestNumberassetex = Math.max(...numberList) + 1
        }
        form.ListofExecutor.push({
          number: form.ListofExecutor.length ? latestNumberassetex : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, ListofExecutor: form.ListofExecutor })
        break 
      case "removeListofExecutor":
        const modifiedListassetex = form.ListofExecutor.filter(
          (each, idx) => idx !== key
        )
        setForm({ ...form, ListofExecutor: modifiedListassetex })
        break
      case "executerList":
        const modfiedListOfExecutor = []

        for (let i = 0; i < form.ListofExecutor.length; i++) {
          const Testator = {
            ...form.ListofExecutor[i],
          }

          if (i === key) {
            let regionsList = Testator.regionsList || []
            if (name === "wcil_country") {
              regionsList =  await fetchRegionsList(value) 
              Testator.wcil_region = regionsList[0]?.region || ""
            }

            modfiedListOfExecutor.push({
              ...Testator,
              [name]: value,
              regionsList,
            })
          } else {
            modfiedListOfExecutor.push(Testator)
          }
        }

        setForm({
          ...form,
          ListofExecutor: modfiedListOfExecutor,
        })
      break  
      case "addListofBeneficiaries":
        let latestNumbers = 2
        if (form.ListofBeneficiaries.length) {
          let numberList = form.ListofBeneficiaries.map(each => each.number)

          latestNumbers = Math.max(...numberList) + 1
        }
        form.ListofBeneficiaries.push({
          number: form.ListofBeneficiaries.length ? latestNumbers : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, ListofBeneficiaries: form.ListofBeneficiaries })
        break
      case "removeListofBeneficiaries":
        const modifiedLists = form.ListofBeneficiaries.find(
          (each, idx) => idx === key
        )
        setForm({ ...form, ListofBeneficiaries: modifiedLists })
        break
      case "beneficiariesList":
          const modfiedListOfBeneficiaries = []
  
          for (let i = 0; i < form.ListofBeneficiaries.length; i++) {
            const Testator = {
              ...form.ListofBeneficiaries[i],
            }
  
            if (i === key) {
              let regionsList = Testator.regionsList || []
              if (name === "wcil_country") {
                regionsList =  await fetchRegionsList(value) 
                Testator.wcil_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfBeneficiaries.push({
                ...Testator,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfBeneficiaries.push(Testator)
            }
          }
  
          setForm({
            ...form,
            ListofBeneficiaries: modfiedListOfBeneficiaries,
          })
        break  
      case "addListofDocuments":
        let latestNumberss = 2
        if (form.ListofDocuments.length) {
          let numberList = form.ListofDocuments.map(each => each.number)

          latestNumberss = Math.max(...numberList) + 1
        }
        form.ListofDocuments.push({
          number: form.ListofDocuments.length ? latestNumberss : 1,
        })
        setForm({ ...form, ListofDocuments: form.ListofDocuments })
        break
      case "removeListofDocuments":
        const modifiedListss = form.ListofDocuments.filter(
          (each, idx) => idx !== key
        )
        handleRemoveDocuments(modifiedListss?.wdl_id || 0, key)
       
        break
      case "addListofAssets":
        
        let latestNumberasset = 2
        if (form.ListofAssets.length) {
          let numberList = form.ListofAssets.map(each => each.number)

          latestNumberasset = Math.max(...numberList) + 1
        }
        form.ListofAssets.push({
          number: form.ListofAssets.length ? latestNumberasset : 1,
          
        })
        setForm({ ...form, ListofAssets: form.ListofAssets })
        break 
      case "removeListofAssets":
        const modifiedListasset = form.ListofAssets.filter(
          (each, idx) => idx !== key
        )
        setForm({ ...form, ListofAssets: modifiedListasset })
        break
      case "assetwillList":
          const modfiedListOfassetwill = []
  
          for (let i = 0; i < form.ListofAssets.length; i++) {
            const Assetwill = {
              ...form.ListofAssets[i],
            }
  
            if (i === key) {
              let regionsList = Assetwill.regionsList || []
              if (name === "wal_country") {
                regionsList =  await fetchRegionsList(value) 
                Assetwill.wal_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfassetwill.push({
                ...Assetwill,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfassetwill.push(Assetwill)
            }
          }
  
          setForm({
            ...form,
            ListofAssets: modfiedListOfassetwill,
          })
        break  
      case "questions":
        setForm({
          ...form,
          questions: form.questions.map((each, idx) => {
            if (idx == key) {
              if (name == "wql_explain") {
                return {
                  ...each,
                  wql_explain: value,
                }
              }
              return {
                ...each,
                wql_type: value,
              }
            }
            return each
          }),
        })
        break
      case "documentList":
        setForm({
          ...form,
          ListofDocuments: form.ListofDocuments.map((each, idx) => {
            if (idx == key) {
              if (name == "wdl_doucment_name") {
                return {
                  ...each,
                  wdl_doucment_name: value,
                }
              }
              return {
                ...each,
                wdl_type_document: value,
              }
            }
            return each
          }),
        })
        break
       default:
        null
    }
  }

  // for image upload
  const handleImage = async (e, action, key) => {
    const { name, files } = e?.target || {}

    switch (action) {
      case "testatorList":
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader()
          reader.readAsDataURL(files[i])
          reader.onload = () => {
            setForm({
              ...form,
              listofTestator: form.listofTestator.map((each, idx) => {
                if (idx === key) {
                  return {
                    ...each,
                    [name]: reader.result,
                  }
                }

                return each
              }),
            })
          }
        }
        break
      case "executerList":
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader()
          reader.readAsDataURL(files[i])
          reader.onload = () => {
            setForm({
              ...form,
              listofExecutor: form.listofExecutor.map((each, idx) => {
                if (idx === key) {
                  return {
                    ...each,
                    [name]: reader.result,
                  }
                }

                return each
              }),
            })
          }
        }
        break
      case "beneficiariesList":
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader()
            reader.readAsDataURL(files[i])
            reader.onload = () => {
              setForm({
                ...form,
                listofBeneficiaries: form.listofBeneficiaries.map((each, idx) => {
                  if (idx === key) {
                    return {
                      ...each,
                      [name]: reader.result,
                    }
                  }
  
                  return each
                }),
              })
            }
          }
          break
      case "assetwillList":
          
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader()
            reader.readAsDataURL(files[i])
            reader.onload = () => {
              setForm({
                ...form,
                listofAssets: form.listofAssets.map((each, idx) => {
                  if (idx === key) {
                    return {
                      ...each,
                      [name]: reader.result,
                    }
                  }
  
                  return each
                }),
              })
            }
          }
          break
      case "documentList": {
            {
              const result = await FileUpload(files)
    
              const urlsList = result.files.map(each => each.url)
    
              setForm({
                ...form,
                ListofDocuments: form.ListofDocuments.map((each, idx) => {
                  if (idx === key) {
                    return {
                      ...each,
                      ['wdl_path']: urlsList.join(","),
                    }
                  }
    
                  return each
                }),
              })
            }
          }
          break;


        setForm({
          ...form,
        })
        break
      default:
        null
    }
  }

  const handleFormSubmit = async () => {
    switch (activeTab) {
      case 1:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_will_information`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_will_list`

            let haserrors = false
            if (!form.wl_name_of_document) {
              setErrors(prev => {
                return {
                  ...prev,
                  wl_name_of_document: "Please enter Name of Document"
                }
              })
            }
            
            if (!form.wl_type_of_document) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  wl_type_of_document: "Please select Type of Document"
                }
              })
            }
  
            if (!form.wl_full_name) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  wl_full_name: "Please enter Full Name"
                }
              })
              
            }  
  
            if (haserrors) {
              return
            }

          
          const data = {
              wl_id: id || wId,
              wl_uid: userId,
              wl_name_of_document: form.wl_name_of_document,
              wl_date_of_execution: form.wl_date_of_execution,
              wl_type_of_document: form.wl_type_of_document,
              wl_original_document: form.wl_original_document,
              wl_full_name: form.wl_full_name,
              wl_contact: form.wl_contact, 
              listofDocuments: form.ListofDocuments.map(
                eachDocument => {
                  return {
                    wdl_doucment_name: eachDocument.wdl_doucment_name || "",
                    wdl_type_document: eachDocument.wdl_type_document || "",
                    wdl_path: eachDocument.wdl_path || "",
                  }
                }
              ),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateWillInformation = await response.json()

          if (addOrUpdateWillInformation?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(2)
            localStorage.setItem("tab", 2)
            localStorage.setItem("wId",addOrUpdateWillInformation.wId)
          
            showMessage(addOrUpdateWillInformation.message, "success")
          } else {
            showMessage(addOrUpdateWillInformation?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 2:      
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_testator_info`

        const data = {
          wcil_uid: userId,
          wl_id: id || wId,
          ListofTestator: form.ListofTestator,
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const updateTestatorList = await response.json()

        if (updateTestatorList?.success === true) {
          setApiStatus({ ...apiStatus, inProgress: false })
          setactiveTab(3)
          localStorage.setItem("tab", 3)
          //await fetchBeneficiaryData()
          showMessage(updateTestatorList.message, "success")
          setTimeout(() => {
            setForm({ ...form, listOfTestator: [] })
          }, 500)
        } else {
          showMessage(updateTestatorList?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (error) {}
      break 
      case 3: 
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_executor_info`

        const data = {
          wcil_uid: userId,
          wl_id: id || wId,
          ListofExecutor: form.ListofExecutor,
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const updateExecutorList = await response.json()

        if (updateExecutorList?.success === true) {
          setApiStatus({ ...apiStatus, inProgress: false })
          setactiveTab(4)
          localStorage.setItem("tab", 4)
          //await fetchBeneficiaryData()
          showMessage(updateExecutorList.message, "success")
          setTimeout(() => {
            setForm({ ...form, listofExecutor: [] })
          }, 500)
        } else {
          showMessage(updateExecutorList?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (error) {}      
        break
      case 4:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_beneficiary_info`
  
          const data = {
            wcil_uid: userId,
            wl_id: id || wId,
            ListofBeneficiaries: form.ListofBeneficiaries,
          }
  
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
  
          const updateBeneficiariesList = await response.json()
  
          if (updateBeneficiariesList?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(5)
            localStorage.setItem("tab", 5)
            //await fetchBeneficiaryData()
            showMessage(updateBeneficiariesList.message, "success")
            setTimeout(() => {
              setForm({ ...form, listofBeneficiaries: [] })
            }, 500)
          } else {
            showMessage(updateBeneficiariesList?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}        
        break      
      case 5:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_willasset_info`
  
          const data = {
            wal_uid: userId,
            wl_id: id || wId,
            ListofAssets: form.ListofAssets,
          }
  
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
  
          const updatewillassetList = await response.json()
  
          if (updatewillassetList?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(6)
            localStorage.setItem("tab", 6)
            //await fetchBeneficiaryData()
            showMessage(updatewillassetList.message, "success")
            setTimeout(() => {
              setForm({ ...form, listofAssets: [] })
            }, 500)
          } else {
            showMessage(updatewillassetList?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 6:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/udpate_will_questions_list`
  
            const data = {
              questions_list: form.questions,
              imagesList: form.imagesList.map(each => {
                return { ...each, wl_id: id, wql_uid: userId }
              }),
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            const response = await fetch(url, options, {})
  
            const questionList = await response.json()
  
            if (questionList?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              showMessage(questionList.message, "success")
              setTimeout(() => {
                history("/will-list")
              }, 3000)
            } else {
              showMessage(questionList?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
        break
      case 7:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_willdocuments`

          const data = {
            wl_id: id || wId,
            wdl_uid: userId,
            listofDocuments: form.ListofDocuments.map(
              eachDocument => {
                return {
                  pdl_id: eachDocument.pdl_id || 0,
                  wdl_doucment_name: eachDocument.wdl_doucment_name || "",
                  wdl_type_document: eachDocument.wdl_type_document || "",
                  wdl_path: eachDocument.wdl_path || "",
                }
              }
            ),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateListofDocuments = await response.json()

          if (addOrUpdateListofDocuments?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage(addOrUpdateListofDocuments.message, "success")
            // setTimeout(() => {
            //   history("/will-list")
            // }, 3000)
            setactiveTab(activeTab + 1)
            localStorage.setItem("tab", activeTab + 1)
          } else {
            showMessage(addOrUpdateListofDocuments?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
     
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">Add WILL</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Services</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/will-list">Add Will</Link>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={async () => {
                              setactiveTab(1)
                              localStorage.setItem("tab", 1)
                              await fetchDocumentsData()
                            }}
                          >
                            <span className="number">1.</span> Will Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                              localStorage.setItem("tab", 2)
                            }}
                          >
                            <span className="number">2.</span> Testator/Testatrix
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                              localStorage.setItem("tab", 3)
                            }}
                          >
                            <span className="number">3.</span>
                            Executor Names
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                              localStorage.setItem("tab", 4)
                            }}
                          >
                            <span className="number">4.</span>
                            Beneficiary Names
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={async () => {
                              setactiveTab(5)
                              localStorage.setItem("tab", 5)                              
                            }}
                          >
                            <span className="number">5.</span>
                            Assets Info
                          </NavLink>
                        </NavItem> 
                        <NavItem
                          className={classnames({ current: activeTab === 6 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 6 })}
                            onClick={() => {
                              setactiveTab(6)
                              localStorage.setItem("tab", 6)
                            }}
                          >
                            <span className="number">6.</span>
                            Questionnaire
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        {/**4,5,6 dynamic forms and tables */}
                        <TabPane tabId={1}>
                          <Form>
                            <h4 className="page-title-box">
                            Will Information
                            </h4>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="wl_name_of_document"
                                    className="form-label"
                                  >
                                    Name of Document{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"                                    
                                    name="wl_name_of_document"
                                    className={
                                      errors.wl_name_of_document
                                        ? "invalid-inputfield form-control"
                                        : "wl_name_of_document form-control"
                                    }
                                    placeholder="Name of Document"
                                    required
                                    onChange={e =>
                                      handleForm(e, "willInfo")
                                    }
                                    value={form.wl_name_of_document}
                                  />
                                  {errors.wl_name_of_document && (
                                    <div className="invalid-input">
                                      {errors.wl_name_of_document}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="wl_date_of_execution"
                                    className="form-label"
                                  >
                                    Date of Execution
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="wl_date_of_execution"
                                    id="wl_date_of_execution"
                                    placeholder="Date of purchase"
                                    value={form.wl_date_of_execution}
                                    onChange={e =>
                                      handleForm(e, "willInfo")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="wl_type_of_document"
                                    className="form-label"
                                  >
                                    Type of Document{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="wl_type_of_document"  
                                    name="wl_type_of_document"        
                                    required
                                    onChange={e =>
                                      handleForm(e, "willInfo")
                                    }
                                    value={form.wl_type_of_document}
                                    defaultValue={'DEFAULT'} 
                                  >
                                    <option value="DEFAULT" selected="">Select Type of Document</option>
                                    <option value="Will">Will </option>
                                    <option value="Trust">Trust</option>
                                     
                                  </select>
                                  {errors.wl_type_of_document && (
                                    <div className="invalid-input">
                                      {errors.wl_type_of_document}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <h4 className="page-title-box">Who has the original document</h4>
                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="wl_full_name"
                                    className="form-label"
                                  >
                                    Full Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.wl_full_name
                                        ? "invalid-inputfield form-control"
                                        : "wl_full_name form-control"
                                    }
                                    name="wl_full_name"
                                    id="wl_full_name"
                                    placeholder="Full Name"
                                    required
                                    onChange={e =>
                                      handleForm(e, "willInfo")
                                    }
                                    value={form.wl_full_name}
                                  />
                                   {errors.wl_full_name && (
                                    <div className="invalid-input">
                                      {errors.wl_full_name}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="wl_contact"
                                    className="form-label"
                                  >
                                    Contact Details
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="wl_contact"
                                    id="wl_contact"
                                    placeholder="Contact Details"
                                    value={form.wl_contact}
                                    onChange={e =>
                                      handleForm(e, "willInfo")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                  <label htmlFor="validationTooltip01" className="form-label">
                                    Relationship
                                  </label>
                                  <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="wl_original_document"
                                    onChange={e =>
                                      handleForm(e, "willInfo")
                                    }
                                    value={form.wl_original_document}
                                  >
                                    <option value="" selected="">
                                      Select Relationship
                                    </option>
                                    
                                    {relationsList?.map(each => (
                                      <option key={each.url_id} value={each.url_name}>
                                        {each.url_name}
                                      </option>
                                    ))}                                    
                                  </select>
                                </div>
                              </div>
                            </div>

                            <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >
                              <h4 className="page-title-box">
                                 List of Documents 
                              </h4>

                              <ListofDocuments
                                form={form}
                                handleForm={handleForm}
                                handleImage={handleImage}
                                wl_id={id || wId}
                                activeTab={activeTab}
                              />
                            </Suspense>




                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                                  <Suspense
                                    fallback={
                                      <div className="text-center m-4">Loading...</div>
                                    }
                                  >
                                    <h4 className="page-title-box">
                                    List of Testator/Testatrix Names
                                    </h4>

                                    <ListofTestator
                                      form={form}
                                      handleForm={handleForm}                                      
                                      wl_id={id || wId}
                                      activeTab={activeTab}
                                    />
                                  </Suspense>
                        </TabPane>
                        <TabPane tabId={3}>
                                <Suspense
                                    fallback={
                                      <div className="text-center m-4">Loading...</div>
                                    }
                                  >
                                    <h4 className="page-title-box">
                                    List of Executor Names
                                    </h4>

                                    <ListofExecutor
                                      form={form}
                                      handleForm={handleForm}
                                      handleImage={handleImage}
                                      wl_id={id || wId}
                                      activeTab={activeTab}
                                    />
                                  </Suspense>
                        </TabPane>
                        <TabPane tabId={4}>

                                 <Suspense
                                    fallback={
                                      <div className="text-center m-4">Loading...</div>
                                    }
                                  >
                                    <h4 className="page-title-box">
                                    List of Beneficiary Names
                                    </h4>

                                    <ListofBeneficiaries
                                      form={form}
                                      handleForm={handleForm}                                      
                                      wl_id={id || wId}
                                      activeTab={activeTab}
                                    />
                                  </Suspense>
                          
                        </TabPane>
                        <TabPane tabId={5}>

                                 <Suspense
                                    fallback={
                                      <div className="text-center m-4">Loading...</div>
                                    }
                                  >
                                    <h4 className="page-title-box">
                                    List of Assets
                                    </h4>

                                    <ListofAssets
                                      form={form}
                                      handleForm={handleForm}                                      
                                      wl_id={id || wId}
                                      activeTab={activeTab}
                                    />
                                  </Suspense>
                        
                          
                        </TabPane>
                        <TabPane tabId={6}>
                        <Form>
                            <h4 className="page-title-box">Questionnaire</h4>
                            {form.questions.map((each, idx) => (
                              <div className="row">
                                <div className="col-md-6">{each.wq_name}</div>
                                <div className="col-md-1">
                                  <input
                                    type="radio"
                                    name={`pq_typ${each.wql_qid}`}
                                    id={`Yes${each.wql_qid}`}
                                    value="Yes"
                                    checked={each.wql_type == "Yes"}
                                    className="form-check-input"
                                    onChange={e =>
                                      handleForm(e, "questions", idx)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customRadioInline2"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="col-md-1">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`pq_typ${each.wql_qid}`}
                                    id={`No${each.wql_qid}`}
                                    value="No"
                                    checked={each.wql_type == "No"}
                                    onChange={e =>
                                      handleForm(e, "questions", idx)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customRadioInline1"
                                  >
                                    No
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      for="validationTooltip41"
                                      className="form-label"
                                    >
                                      Explain
                                    </label>
                                    <textarea
                                      className="form-control"
                                      rows="3"
                                      for="validationTooltip41"
                                      name="wql_explain"
                                      value={each.wql_explain}
                                      onChange={e =>
                                        handleForm(e, "questions", idx)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            ))}
                           
                          </Form>
                          
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Button
                            type="button"
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                            onClick={() => {
                               // activeTab != handleFormSubmit()
                              toggleTab(activeTab - 1)
                            }}
                            disabled={activeTab === 1}
                          >
                            Previous
                          </Button>
                        </li>
                        <li
                          className={activeTab ===6 ? "next disabled" : "next"}
                        >
                          <Button
                            disabled={activeTab ===6 }
                            type="button"
                            onClick={() => {
                              activeTab != handleFormSubmit()
                              toggleTab(activeTab + 1)
                            }}
                          >
                            Next
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      { wId || id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={"/will-list"}
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                      localStorage.removeItem("wId")
                      localStorage.removeItem("tab")
                    }}
                  >
                    Cancel
                  </Link>                 
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddWillInfo
