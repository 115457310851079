import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import "../../../../src//styles/errorfield.scss"
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { randomId } from "helpers/randomGen"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
  const UserRoleCollection = lazy(() =>
    import("components/Admin/Users/UserRoleCollection")
  )

  const USerMembership = lazy(() =>
    import("components/Admin/Users/USerMembership")
  
  )
  
  
  const initialFormDetails = {
   
    regionsList1: [],
    realEstateRegionList: [],
    UserRoleCollection: [],    
    USerMembership: [],
              
  }
  
  const EditCustomerInfo = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const relationsList = JSON.parse(localStorage.getItem("relationsList"))
  
   
  
   
  
    async function fetchRegionsList(countryName, type) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        )
  
        const data = await response.json()
        if (type != 1) {
          setForm({
            ...form,
            realEstateRegionList: data?.data || [],
            Region: data.data[0]?.region || "",
            Country: countryName,
          })
        }
  
        return data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  
  
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
    //const bpIdString = localStorage.getItem("bpId");
    //const bpId = bpIdString ? parseInt(bpIdString) : undefined;
  
    async function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 3) {
          setactiveTab(tab)
          localStorage.setItem("tab", tab)
  
         
        }
      }
    }
  
  
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const decryptedData = data?.data[0]
            if (decryptedData) {
              
              const assetInformation = {
                ...decryptedData,
                Bus_Name: decryptedData.Bus_Name,
                EIN_Number: decryptedData.EIN_Number,
                Bus_Website: decryptedData.Bus_Website,
                Bus_Leg_Struc: decryptedData.Bus_Leg_Struc,
                BusType: decryptedData.BusType,
                BusKey: decryptedData.BusKey,
                EmailID: decryptedData.EmailID,
                Phone: decryptedData.Phone,
                Street1: decryptedData.Street1,
                Street2: decryptedData.Street2,
                City: decryptedData.City,
                Country: decryptedData.Country,
                Region: decryptedData.Region,
                Zip: decryptedData.Zip,               
              }
  
              setForm({
                ...form,
                ...assetInformation,
                realEstateRegionList: await fetchRegionsList(
                  assetInformation.Country,
                  1
                ),
                
              })
            }
          }
  
          return true
        } 
        if (id) {
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${id}`
              )
  
              const data = await response.json()
      
      if (data?.success) {
        const personalInfo = data.data
       // userData.user = personalInfo;
        //localStorage.setItem("userData", JSON.stringify(userData));
        const regionsList1 = personalInfo.country
          ? await fetchRegionsList("user_country", personalInfo.country, true)
          : []
       
        //const decryptDob = personalInfo.dob;
        //const decryptedUserName = personalInfo.user_name;

        setForm({
          ...form,
          user_id: personalInfo.user_id,
          user_role: personalInfo.user_role,
          user_key: personalInfo.user_key,          
          user_fname: personalInfo.user_fname,
          user_mname: personalInfo.user_mname,
          user_lname: personalInfo.user_lname,
          gender: personalInfo.gender,
          user_email: personalInfo.user_email,
          user_phone: personalInfo.user_phone,
          user_country: personalInfo.country,
          regionsList1: regionsList1,          
          user_zip: personalInfo.zip,          
          profile_pic: personalInfo.profile_pic,
          addres1: personalInfo.addres1,
          addres2: personalInfo.addres2,
          city: personalInfo.city,
          region: personalInfo.region,          
        })
      }
            }
  
            fetchDataEditData()
          } catch (e) {}
        }
      }
    }, [activeTab])
  
    async function fetchUserRoleCollectionData() {
        try {
          const url = `${
            process.env.REACT_APP_DOMAIN_URL
          }api/v1/admin/get_user_role_collection_list?user_id=${id}`
    
          const response = await fetch(url)
    
          const data = await response.json()
    
          const decryptedData = data?.data?.map((each, idx) => {
            return {
              ...each,
              number: idx + 1,
              Role_Coll: each.Role_Coll || "",
              Str_Date: each.Str_Date || "",
              End_Date: each.End_Date || "",              
            }
          })
    
          setTimeout(() => {
            setForm(prev => {
              return {
                ...prev,
                UserRoleCollection: decryptedData || [],
              }
            })
          }, [500])
        } catch (err) {}
      }
    
      const handleRemoveRoleCollection = async (us_id, idx) => {
        try {
          if (us_id) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_user_role_collection`
            const data = {
            us_id: us_id,
            }
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
            const removeData = await response.json()
    
            if (removeData?.success === true) {
              showMessage(removeData.message, "success")
              setForm(prev => {
                return {
                  ...prev,
                  UserRoleCollection: form.UserRoleCollection.filter(
                    each => each.us_id !== us_id
                  ),
                }
              })
            } else {
              showMessage(removeData.message, "error", false)
            }
          } else if (idx >= 0) {
            setForm({
              ...form,
              UserRoleCollection: form.UserRoleCollection.filter(
                (each, index) => index != idx
              ),
            })
          }
        } catch (error) {
          showMessage(removeData?.message || "Something went wrong", "error", false)
        }
      }

      useEffect(() => {
        if (activeTab == 2) {
            fetchUserRoleCollectionData()
        }
      }, [])

        
    async function fetchUSerMembershipData() {
        try {
          const url = `${
            process.env.REACT_APP_DOMAIN_URL
          }api/v1/admin/get_user_membership?uid=${id}`
    
          const response = await fetch(url)
    
          const data = await response.json()
    
          const decryptedData = data?.data?.map((each, idx) => {
            return {
              ...each,
              number: idx + 1,
              Intro_By_UserKey: each.Intro_By_UserKey || "",
              Intro_FirstName: each.Intro_FirstName || "",
              Intro_LastName: each.Intro_LastName || "",
              couponCode: each.couponCode || "",
              
            }
          })
    
          setTimeout(() => {
            setForm(prev => {
              return {
                ...prev,
                USerMembership: decryptedData || [],
              }
            })
          }, [500])
        } catch (err) {}
      }
    
      const handleRemoveUSerMembership = async (mc_id, idx) => {
        try {
          if (mc_id) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_user_membership`
            const data = {
                mc_id: mc_id,
            }
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
            const removeData = await response.json()
    
            if (removeData?.success === true) {
              showMessage(removeData.message, "success")
              setForm(prev => {
                return {
                  ...prev,
                  USerMembership: form.USerMembership.filter(
                    each => each.mc_id !== mc_id
                  ),
                }
              })
            } else {
              showMessage(removeData.message, "error", false)
            }
          } else if (idx >= 0) {
            setForm({
              ...form,
              USerMembership: form.USerMembership.filter(
                (each, index) => index != idx
              ),
            })
          }
        } catch (error) {
          showMessage(removeData?.message || "Something went wrong", "error", false)
        }
      }

      useEffect(() => {
        if (activeTab == 3) {
            fetchUSerMembershipData()
        }
      }, [])
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "businessInfo":
            if (name === "Country") {
                fetchRegionsList(value)
              } else {
                setForm({
                  ...form,
                  [name]: value,
                })
              }
  
          break
        case "addUserRoleCollection":
        let latestNumbers = 2
        if (form.UserRoleCollection.length) {
          let numberList = form.UserRoleCollection.map(each => each.number)

          latestNumbers = Math.max(...numberList) + 1
        }
        form.UserRoleCollection.push({
          number: form.UserRoleCollection.length ? latestNumbers : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, UserRoleCollection: form.UserRoleCollection })
        break
        case "removeUserRoleCollection":
        const modifiedLists = form.UserRoleCollection.find(
          (each, idx) => idx === key
        )
        handleRemoveRoleCollection(modifiedLists?.us_id || 0, key)
        break
        case "rolecolllist":
            setForm({
              ...form,
              UserRoleCollection: form.UserRoleCollection.map((each, idx) => {
                if (idx == key) {
                  return {
                    ...each,
                    [name]: value,
                  }
                }
    
                return each
              }),
            })
            break
      
        case "addUSerMembership":
        let latestNumbersmember = 2
        if (form.USerMembership.length) {
          let numberList = form.USerMembership.map(each => each.number)

          latestNumbersmember = Math.max(...numberList) + 1
        }
        form.USerMembership.push({
          number: form.USerMembership.length ? latestNumbersmember : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, USerMembership: form.USerMembership })
        break
        case "removeUSerMembership":
        const modifiedListsmember = form.USerMembership.find(
          (each, idx) => idx === key
        )
        handleRemoveUSerMembership(modifiedListsmember?.mc_id || 0, key)
        break
        case "membershiplist":
            setForm({
              ...form,
              USerMembership: form.USerMembership.map((each, idx) => {
                if (idx == key) {
                  return {
                    ...each,
                    [name]: value,
                  }
                }
    
                return each
              }),
            })
            break
      
        
          default:
          null
      }
    }
  
     
    const handleFormSubmit = async () => {
      switch (activeTab) {
        case 1:
          
            try {
              setApiStatus({ ...apiStatus, inProgress: true })
              const url = id
                ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_user_role`
                : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/business_partner_false`            
  
                
              const data = {              
                user_id: id,                
                user_role: form.user_role,                
              }
  
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
  
              setApiStatus({ ...apiStatus, inProgress: true })
  
              const response = await fetch(url, options, {})
  
              const addOrUpdateuserorleInformation = await response.json()
  
              if (addOrUpdateuserorleInformation?.success === true) {
                setApiStatus({ ...apiStatus, inProgress: false })
                setactiveTab(2)
                localStorage.setItem("tab", 2)
                localStorage.setItem(
                  "id",
                  addOrUpdateuserorleInformation.id
                )
                showMessage(addOrUpdateuserorleInformation.message, "success")
              } else {
                showMessage(addOrUpdateuserorleInformation?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
              }
            } catch (error) {}
       
          break
        case 2:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/add_update_userSecurity_info`
    
            const data = {              
              user_id: id ,
              UserRoleCollection: form.UserRoleCollection,
            }
    
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
    
            const response = await fetch(url, options, {})
    
            const updateOwnersList = await response.json()
    
            if (updateOwnersList?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              await fetchUSerMembershipData()
              showMessage(updateOwnersList.message, "success")
              setTimeout(() => {
                setForm({ ...form, ListOfOwners: [] })
              }, 500)
            } else {
              showMessage(updateOwnersList?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break 
        case 3:
                   
          break        
      }
  
     
    }
  
    const handleBlur = e => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value?.trim(),
        errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
      })
    }
    useEffect(() => {
      if (changed) {
        setErrors(prev => {
          const copy = JSON.parse(JSON.stringify(prev))
          delete copy[changed]
          return copy
        })
      }
    }, [changed])  // changed
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">EDIT USER</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Admin</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/asset-wallet">Edit User</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                                localStorage.setItem("tab", 1)
                              }}
                            >
                              <span className="number">1.</span> User Information
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                                localStorage.setItem("tab", 2)
                              }}
                            >
                              <span className="number">2.</span> User Role Collection
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                                localStorage.setItem("tab", 3)
                              }}
                            >
                              <span className="number">3.</span>
                              Membership Information
                            </NavLink>
                          </NavItem>
                      
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          
                          <TabPane tabId={1}>
                            <Form>
                              <h4 className="page-title-box">
                              User Information
                              </h4>
                              <div className="row">
                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="user_fname" className="form-label">
                                    First Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name="user_fname"                                    
                                    placeholder="First Name"
                                    value={form.user_fname}
                                    disabled
                                    />                                    
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="user_mname" className="form-label">
                                    Middle Name
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name="user_mname"
                                    id="user_mname"
                                    placeholder="Middle Name"                                    
                                    value={form.user_mname}
                                    disabled
                                    />
                                </div>
                                </div>

                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="user_lname" className="form-label">
                                    Last Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name="user_lname"                                    
                                    placeholder="Last Name"                                    
                                    value={form.user_lname}
                                    disabled
                                    />
                                   
                                </div>
                                </div>

                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="validationTooltip01" className="form-label">
                                    Gender
                                    </label>
                                    <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="gender"                                    
                                    value={form.gender}
                                    disabled
                                    >
                                    <option value="" defaultValue>
                                        Select Gender
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    </select>
                                </div>
                                </div>                                
                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="validationTooltip03" className="form-label">
                                    Phone Number <span className="text-danger">*</span>
                                    </label>
                                    <input
                                    type="tel"
                                    className="form-control"
                                    name="user_phone"                                    
                                    placeholder="Phone Number"                                                                       
                                    value={form.user_phone}
                                    disabled
                                    />                                    
                                </div>
                                </div>

                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label
                                    htmlFor="validationTooltipUsername"
                                    className="form-label"
                                    >
                                    Email Address
                                    </label>
                                    <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span
                                        className="input-group-text"
                                        id="validationTooltipUsernamePrepend"
                                        >
                                        @
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="user_email"
                                        id="validationTooltipUsername"
                                        placeholder="Email Address"
                                        aria-describedby="validationTooltipUsernamePrepend"
                                        disabled                                       
                                        value={form.user_email}
                                    />
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="validationTooltip23" className="form-label">
                                    User Key
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name="user_key"
                                    id="validationTooltip23"
                                    placeholder="User Key"
                                    disabled                                    
                                    value={form.user_key}
                                    
                                    />
                                </div>
                                </div>

                                

                                <div className="col-md-4">
                                <img
                                    id="output_image"
                                    className="rounded avatar-lg card-img img-thumbnail border border-2 p-0 d-inline-block"
                                    alt="Your Image"
                                    width="100"
                                    height="100"
                                    src={form.profile_pic || "default-profile.png"}
                                />
                                </div>
                                <div className="col-md-4">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="validationTooltip01" className="form-label">
                                    Role
                                    </label>
                                    <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="user_role"                                    
                                    value={form.user_role}
                                    onChange={e =>
                                        handleForm(e, "businessInfo")
                                      }
                                    >
                                    <option value="" defaultValue>Select Role</option>
                                    <option value="0" selected="">Customer</option>
                                    <option value="1">Admin</option>
                                    <option value="2">Operations</option>
                                    <option value="3">Business Partner</option>
                                    </select>
                                </div>
                                </div> 
                               
                                
                                
                                <h4 className="card-title">Address</h4>  

                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom05"
                                        className="form-label"
                                      >
                                        Street 1
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Street1"
                                        id="validationCustom05"
                                        placeholder="Street 1"
                                        value={form.addres1}        
                                        disabled                                
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom06"
                                        className="form-label"
                                      >
                                        Street 2
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Street2"
                                        id="validationCustom06"
                                        placeholder="Street 2"
                                        value={form.addres2}   
                                        disabled                                    
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom07"
                                        className="form-label"
                                      >
                                        City
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="City"
                                        id="validationCustom07"
                                        placeholder="City"
                                        value={form.city}  
                                        disabled                                      
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="country"
                                        className="form-label"
                                      >
                                        Country
                                      </label>
                                      <select
                                        className="form-select"
                                        name="Country"
                                        id="Country"
                                        value={form.user_country} 
                                        disabled                                       
                                      >
                                        <option value="" selected="">Select Country</option>
                                        <option value="USA">USA</option>
                                        <option value="INDIA">INDIA</option>
                                      </select>
                                    </div>
                                  </div>
  
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="region"
                                        className="form-label"
                                      >
                                        Region
                                      </label>
                                      <select
                                        className="form-select"
                                        name="Region"
                                        id="region"
                                        value={form.region} 
                                        disabled                                       
                                      >
                                        {form.realEstateRegionList.length ===
                                        0 ? (
                                          <option value="" selected="">
                                            Select Region
                                          </option>
                                        ) : (
                                          <>
                                          <option value="" defaultValue>
                                            Select Region
                                          </option>
                                          { form.realEstateRegionList.map(each => (
                                            <option
                                              value={each.region}
                                              key={each.region}
                                            >
                                              {each.region}
                                            </option>
                                          ))}
                                          </>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom07"
                                        className="form-label"
                                      >
                                        zip
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="Zip"
                                        oninput="validateZIP()"
                                        id="zipCode"
                                        placeholder="zip"
                                        value={form.user_zip}                                        
                                      />
                                      <div id="zipError"></div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>

                          <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >
                              <h4 className="page-title-box">User Role Collection</h4>
  
                              <UserRoleCollection
                                form={form}
                                handleForm={handleForm}                               
                                user_id={id}
                                activeTab={activeTab}
                              />
                          </Suspense>
                          </TabPane>
                          <TabPane tabId={3}>                          
                            <Suspense
                                fallback={
                                  <div className="text-center m-4">Loading...</div>
                                }
                              >
                                <h4 className="page-title-box">Membership Info</h4>
    
                                <USerMembership
                                  form={form}
                                  handleForm={handleForm}                                  
                                  uid={id}
                                  activeTab={activeTab}
                                />
                            </Suspense>                            
                          </TabPane>                         
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              type="button"
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                              onClick={() => {
                                activeTab != handleFormSubmit()
                                toggleTab(activeTab - 1)
                              }}
                              disabled={activeTab === 1}
                            >
                              Previous
                            </Button>
                          </li>
                          <li
                            className={activeTab === 3 ? "next disabled" : "next"}
                          >
                            <Button
                              disabled={activeTab === 3}
                              type="button"
                              onClick={() => {
                                activeTab != handleFormSubmit()
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={"/customers_list"}
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        //localStorage.removeItem("bpId")
                        localStorage.removeItem("tab")
                      }}
                    >
                      Cancel
                    </Link>                    
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default EditCustomerInfo
  