import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
  Spinner,
} from "reactstrap"

// Redux
import { connect, useSelector } from "react-redux"
import { Link, useNavigate, redirect } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/soflogo-min.png"
import eyeOpen from "../../assets/images/eye.svg"
import eyeClose from "../../assets/images/eye-slash.svg"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const Login = props => {
  // const dispatch = useDispatch()
  const history = useNavigate()
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  useEffect(() => {
    async function verify() {
      if (props?.router?.location?.pathname === "/sof/email_verify") {
        const splitDetails = props?.router?.location?.search
          ?.replace("?", "")
          ?.split("&")
        if (splitDetails.length) {
          const random = splitDetails[0].split("=")[1]
          const email = splitDetails[1].split("=")[1]

          if (random && email) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/verify`
            const data = {
              random: random,
              email: email,
            }

            try {
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
              const response = await fetch(url, options, {})

              const verifyData = await response.json()

              if (verifyData?.success === true) {
                showMessage("Your account verified successfully", "success")
              } else {
                showMessage("Verification failed", "error", false)
              }
            } catch (err) {
              showMessage(err, "error", false)
            }
          }
        }
      }
    }

    verify()
  }, [props?.router?.location?.pathname])

  const [userLogin, setUserLogin] = useState([])
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const { user } = useSelector(state => ({
    user: state.Account.user,
  }))

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.email,
        password: user.password,
      })
    }
  }, [user])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
      user_role: 0,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      user_role: Yup.number(),
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/login`
      const data = {
        user_name: values.email,
        user_pass: values.password,
        user_role: values.user_role,
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const loginData = await response.json()
        console.log(loginData);
        if (loginData?.success === true) {
            localStorage.setItem(
              "userData",
              JSON.stringify(loginData?.data) || {}
            )
            history("/verify_otp");
        } else {
          showMessage(loginData.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
      // dispatch(loginUser(values, props.router.navigate))
    },
  })

  document.title = "Login | SOF"
  return (
    <React.Fragment>
      <AlertMessage />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50">Sign in to continue to SOF.</p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="60" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form
                      className="mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Username"
                          type="email"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 register-passwordMain">
                        <Label className="form-label" htmlFor="userpassword">
                          Password
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={isPasswordVisible ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.values.password && (
                          <div
                            className="register-passwordVisibility"
                            onClick={() =>
                              setIsPasswordVisible(!isPasswordVisible)
                            }
                          >
                            <img
                              src={isPasswordVisible ? eyeOpen : eyeClose}
                              alt="show"
                            />
                          </div>
                        )}
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 register-passwordMain">
                        <Input
                          name="user_role"
                          className="form-control"
                          type="select"
                          id="user_role"
                          onChange={validation.handleChange}
                          value={validation.values.user_role}
                        >
                          <option value={0}>Customer</option>
                          <option value={1}>Admin</option>
                          <option value={2}>Operations</option>
                          <option value={3}>Bussiness Partner</option>
                        </Input>
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div> */}
                        </div>

                        {apiStatus.inProgress ? (
                          <div className="col-sm-6 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="button"
                            >
                              <Spinner color="light" size="sm">
                                Loading...
                              </Spinner>
                            </button>
                          </div>
                        ) : (
                          <div className="col-sm-6 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} SOF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
