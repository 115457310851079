import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure";
import _ from 'lodash';

const GenerateListofAssets = ({form, handleForm}) => {
  const [assetwallet, setAssetWallet] = useState({ data: [] });

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_asset_wallet`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        pro_uid: userData?.user?.user_id,
        pro_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setAssetWallet({
          ...assetwallet,
          data: assetwallet?.data?.filter(each => each.pro_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Check",
        field: "select",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name of Asset",
        field: "name_of_the_asset",
        sort: "asc",
        width: 270,
      },     
      {
        label: "Country of Asset",
        field: "country_ofasset",
        sort: "asc",
        width: 100,
      },
      {
        label: "City",
        field: "pre_city",
        sort: "asc",
        width: 100,
      }, 
      {
        label: "Region",
        field: "pre_region",
        sort: "asc",
        width: 100,
      },     
    ],
    rows: form.ListOfAssets.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        select:
          (
            <input
              type="checkbox"
              className="form-check-input"
              name={`asset_${each.pro_id}`}
              id={each.pro_id}
              value={each.pro_id}
              checked={form.ListofSelectedAssets.indexOf(each.pro_id) > -1}
              onChange={e =>
                handleForm(e , "ListOfAssests", each.pro_id)
              }
            />
          ) || "",
        name_of_the_asset: each?.pro_asset_name,
        country_ofasset: each?.pro_county_asset || "",
        pre_city: each?.pre_city,
        pre_region: each?.pre_region || "",          
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              
              <div className="col-12 d-flex">
                
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GenerateListofAssets


