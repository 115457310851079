import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Container, Spinner } from "reactstrap"
import "../../../../src//styles/errorfield.scss"
import { FileUpload } from "helpers/file_uploads/upload_handler"


const initialFormDetails = {
  user_fname: "",
  user_mname: "",
  user_lname: "",
  user_email: "",
  user_phone: "",
  user_address: "",
  is_address: "Yes",
  is_alive: "Yes",
  user_country: "",
  user_zip: "",
  user_birthdate: "",
  relationship: "",
  relationsList: [],
  regionsList: [],
  addres1: "",
  addres2: "",
  city: "",
  region: "",
  ac_image: "",
}

const AddFamilyInformation = () => {
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [errors, setErrors] = useState({})

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")

  // -- Fetch relationships list start -- //
  async function fetchRelationsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
      )
      const data = await response.json()
      setForm({ ...form, relationsList: data?.data || [] })

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchRelationsList()
  }, [])

  // --- fetch relationships list end here --- //

  // -- Fetch family information list start -- //
  const fetchData = async () => {
    try {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${user_id}&ac_id=${id}&type=${type}`
      )
      const data = await response.json()

      if (data?.success) {
        const acData = data?.data[0]

        const relationShipData = await fetchRelationsList()
        const regionData = await fetchRegionsList(acData.ac_country)

        const decryptedDob =acData.ac_dob
        setForm({
          ...form,
          user_fname: acData.ac_fname,
          user_gname: acData.ac_mname,
          user_lname: acData.ac_lname,
          user_email: acData.ac_email,
          user_birthdate: decryptedDob,
          user_phone: acData.ac_phone,
          is_address: acData.ac_adds,
          is_alive: acData.Alive,
          relationsList: relationShipData.length ? relationShipData : [],
          regionsList: regionData.length ? regionData : [],
          relationship: acData.ac_reliation,
          addres1: acData.ac_st1,
          addres2: acData.ac_st2,
          city: acData.ac_city,
          user_zip: acData.ac_zip,
          user_country: acData.ac_country,
          region: acData.ac_region,
          ac_image: acData.ac_image,
        })
      } else {
        type == 1
          ? history("/friends-information")
          : history("/family-information")
      }
    } catch (err) {
      type == 1
        ? history("/friends-information")
        : history("/family-information")
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [])

  // -- Fetch family information list end -- //

  async function fetchRegionsList(countryName) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
      )

      const data = await response.json()
      setForm({
        ...form,
        regionsList: data?.data || [],
        region: data.data[0]?.region || "",
        user_country: countryName,
      })

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleForm = e => {
    let { name, value } = e.target

    if (name === "user_country") {
      fetchRegionsList(value)
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, [name]: result.files?.[0]?.url })
  }

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    if (!form.user_fname.trim()) {
      newErrors.user_fname = "First Name is required"
      isValid = false
    }

    if (!form.user_lname.trim()) {
      newErrors.user_lname = "Last Name is required"
      isValid = false
    }

    if (!form.relationship.trim()) {
      newErrors.relationship = "Relationship is required"
      isValid = false
    }

    setErrors(newErrors)
    // console.log(errors);
    // console.log(isValid);
    return isValid
  }

  const handleFormSubmit = async () => {
    validateForm()
    if (!!form.user_fname && !!form.user_lname && !!form.relationship) {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = id
          ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/update_family_information`
          : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/add_family_information`

        const data = {
          user_fname: form.user_fname,
          user_mname: form.user_mname,
          user_lname: form.user_lname,
          user_email: form.user_email,
          user_phone: form.user_phone,
          user_address: form.user_address,
          is_address: form.is_address,
          is_alive: form.is_alive,
          user_birthdate: form.user_birthdate,
          relationship: form.relationship,
          ac_image: form.ac_image,
        }

        data.user_id = JSON.parse(localStorage.getItem("userData")).user.user_id

        if (form.is_address === "No") {
          data.addres1 = form.addres1 || ""
          data.addres2 = form.addres2 || ""
          data.city = form.city || ""
          data.region = form.region || ""
          data.user_country = form.user_country
          data.user_zip = form.user_zip
        }

        if (id) {
          data.ac_id = id
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const addOrUpdateFamilyInformation = await response.json()

        if (addOrUpdateFamilyInformation?.success === true) {
          showMessage(addOrUpdateFamilyInformation.message, "success")
          setTimeout(() => {
            type == 1
              ? history("/friends-information")
              : history("/family-information")
          }, 3000)
        } else {
          showMessage(addOrUpdateFamilyInformation?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(e, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">ADD FAMILY INFORMATION</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/family-information">Account Info</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/family-information">Family Information</Link>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="user_fname" className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_fname"
                      id={
                        errors.user_fname ? "invalid-inputfield" : "user_fname"
                      }
                      placeholder="First Name"
                      required
                      onChange={handleForm}
                      value={form.user_fname}
                    />
                    {errors.user_fname && (
                      <div className="invalid-input">{errors.user_fname}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="user_mname" className="form-label">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_mname"
                      id="ser_mname"
                      placeholder="Middle Name"
                      value={form.user_mname}
                      onChange={handleForm}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="user_lname" className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_lname"
                      id={
                        errors.user_lname ? "invalid-inputfield" : "user_lname"
                      }
                      placeholder="Last Name"
                      required
                      onChange={handleForm}
                      value={form.user_lname}
                    />
                    {errors.user_fname && (
                      <div className="invalid-input">{errors.user_lname}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="validationTooltipUsername"
                      className="form-label"
                    >
                      Email Address
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          @
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="user_email"
                        id="validationTooltipUsername"
                        placeholder="Email Address"
                        aria-describedby="validationTooltipUsernamePrepend"
                        disabled=""
                        onChange={handleForm}
                        value={form.user_email}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip02" className="form-label">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="user_birthdate"
                      id="validationTooltip02"
                      placeholder="Date of Birth"
                      onChange={handleForm}
                      value={form.user_birthdate}
                      max={new Date().toISOString().split('T')[0]}
                    />
                    <span className="help-inline col-xs-12 col-sm-7"></span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip03" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      name="user_phone"
                      id="validationTooltip03"
                      placeholder="Phone Number"
                      onChange={handleForm}
                      value={form.user_phone}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label htmlFor="validationTooltip01" className="form-label">
                      Relationship <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id={
                        errors.relationship
                          ? "invalid-inputfield"
                          : "relationship"
                      }
                      name="relationship"
                      required
                      onChange={handleForm}
                      value={form.relationship}
                    >
                      <option value="" defaultValue>
                        Select Relationship
                      </option>
                      {form.relationsList.map(each => (
                        <option key={each.url_id} value={each.url_name}>
                          {each.url_name}
                        </option>
                      ))}
                    </select>
                    {errors.relationship && (
                      <div className="invalid-input">{errors.relationship}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-2">
                  <img
                    id="output_image"
                    className="rounded avatar-lg card-img img-thumbnail border border-2 p-0 d-inline-block"
                    alt="Your Image"
                    width="100"
                    height="80"
                    src={form.ac_image || "default-profile.png"}
                  />
                </div>

                <div className="col-md-4">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="validationTooltipUsername"
                      className="form-label"
                    >
                      User Image
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        name="ac_image"
                        accept="image/*"
                        className="upload"
                        id="ac_img"
                        style={{ display: "none" }}
                        onChange={handleImage}
                      />
                      <label htmlFor="ac_img">
                        <p className="btn btn-info">Upload Image </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-4">
                  <label className="form-label mb-3 d-flex">Is Alive?</label>

                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name="is_alive"
                      id="Yes"
                      value="Yes"
                      checked={form.is_alive === "Yes"}
                      className="form-check-input"
                      onChange={handleForm}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customRadioInline2"
                    >
                      Yes
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="is_alive"
                      id="No"
                      value="No"
                      checked={form.is_alive === "No"}
                      onChange={handleForm}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customRadioInline1"
                    >
                      No
                    </label>
                  </div>

                 

                </div>

                <div className="col-md-4">
                  <label className="form-label mb-3 d-flex">Address</label>

                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name="is_address"
                      id="Yes"
                      value="Yes"
                      checked={form.is_address === "Yes"}
                      className="form-check-input"
                      onChange={handleForm}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customRadioInline2"
                    >
                      Yes
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="is_address"
                      id="No"
                      value="No"
                      checked={form.is_address === "No"}
                      onChange={handleForm}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customRadioInline1"
                    >
                      No
                    </label>
                  </div>

                  

                </div>
              </div>
            </div>
          </div>
          {form.is_address === "No" ? (
            <div className="row">
              <div className="col-xl-8">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Address</h4>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="validationCustom05"
                            className="form-label"
                          >
                            Street 1
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="addres1"
                            id="validationCustom05"
                            placeholder="Street 1"
                            value={form.addres1}
                            onChange={handleForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="validationCustom06"
                            className="form-label"
                          >
                            Street 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="addres2"
                            id="validationCustom06"
                            placeholder="Street 2"
                            value={form.addres2}
                            onChange={handleForm}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            htmlFor="validationCustom07"
                            className="form-label"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            id="validationCustom07"
                            placeholder="City"
                            value={form.city}
                            onChange={handleForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="country" className="form-label">
                            Country
                          </label>
                          <select
                            className="form-select"
                            name="user_country"
                            id="country"
                            value={form.user_country}
                            onChange={handleForm}
                          >
                            <option value="" defaultValue>Select Country</option>
                            <option value="USA">
                              USA
                            </option>
                            <option value="INDIA">INDIA</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="region" className="form-label">
                            Region
                          </label>
                          <select
                            className="form-select"
                            name="region"
                            id="region"
                            value={form.region}
                            onChange={handleForm}
                          >
                            {form.regionsList.length === 0 ? (
                              <option value="" >
                                Select Region
                              </option>
                            ) : (
                              <>
                            <option value="" defaultValue>
                              Select Region
                            </option>

                             { form.regionsList.map(each => (
                                <option value={each.region} key={each.region}>
                                  {each.region}
                                </option>
                              ))}
                            </>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            htmlFor="validationCustom07"
                            className="form-label"
                          >
                            Zip
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="user_zip"
                            oninput="validateZIP()"
                            id="zipCode"
                            placeholder="Zip"
                            value={form.user_zip}
                            onChange={handleForm}
                          />
                          <div id="zipError"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={
                      type == 1 ? "/friends-information" : "/family-information"
                    }
                    className="btn btn-secondary waves-effect"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddFamilyInformation
