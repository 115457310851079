import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"

const WillListTable = () => {
  const [willlist, setWillList] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_will_list?wl_uid=${userId}`
      )

      const data = await response.json()
      
      setWillList({ ...willlist, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_will_list`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        wl_uid: userData?.user?.wl_uid,
        wl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setWillList({
          ...willlist,
          data: willlist?.data?.filter(each => each.wl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Document Type",
        field: "wl_type_of_document",
        sort: "asc",
        width: 270,
      },
      {
        label: "Document Name",
        field: "wl_name_of_document",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: willlist?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add-will?id=${each.wl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        wl_type_of_document: each?.wl_type_of_document,
        wl_name_of_document: each?.wl_name_of_document,
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.wl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.wl_name_of_document}?`
                  ) &&
                  handleRemoveRole(each.wl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Will List</CardTitle>
              <div className="col-12 d-flex button-toolbar">
                <div>
                  <Link
                    to="/add-will"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Will
                  </Link>&nbsp; &nbsp;
                </div>
                <div>
                  <Link to="/add_generate_will" className="btn btn-danger my-2" type="button">
                    Generate a Will
                  </Link> &nbsp; &nbsp;
                </div>
                
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default WillListTable

   

export const ListOfExistingTestator = ({ wl_id, activeTab }) => {
  const [ListofTestator, setListOfTestator] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_testator_list?wl_id=${wl_id}&wcil_uid=${userId}`

        const response = await fetch(url)
         
        const data = await response.json()
      
        setListOfTestator({ ...ListofTestator, data: data?.data || [] })        
     
      } catch (err) {}
    }

    fetchData()
  }, [wl_id, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_testator`
      const data = {
        wcil_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfTestator({
          ...ListofTestator,
          data: ListofTestator?.data?.filter(each => each.wcil_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofTestator?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit_will_contact?id=${each.wcil_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.wcil_fname} ${each?.wcil_lname}`,
        relationship: each?.wcil_relitionship,       
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.wcil_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.wcil_fname} ${each?.wcil_lname}?`
                  ) &&
                  handleRemoveRole(each.wcil_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ListOfExistingExecutor = ({ wl_id, activeTab }) => {
  const [ListofExecutor, setListOfExecutor] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_executor_list?wl_id=${wl_id}&wcil_uid=${userId}`

        const response = await fetch(url)
         
        const data = await response.json()
      
        setListOfExecutor({ ...ListofExecutor, data: data?.data || [] })        
     
      } catch (err) {}
    }

    fetchData()
  }, [wl_id, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_testator`
      const data = {
        wcil_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfExecutor({
          ...ListofExecutor,
          data: ListofExecutor?.data?.filter(each => each.wcil_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofExecutor?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit_will_contact?id=${each.wcil_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.wcil_fname} ${each?.wcil_lname}`,
        relationship: each?.wcil_relitionship,       
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.wcil_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.wcil_fname} ${each?.wcil_lname}?`
                  ) &&
                  handleRemoveRole(each.wcil_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ListOfExistingBeneficiaries = ({ wl_id, activeTab }) => {
  const [ListofBeneficiaries, setListOfBeneficiaries] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_will_beneficiaries_list?wl_id=${wl_id}&wcil_uid=${userId}`

        const response = await fetch(url)
         
        const data = await response.json()
      
        setListOfBeneficiaries({ ...ListofBeneficiaries, data: data?.data || [] })        
     
      } catch (err) {}
    }

    fetchData()
  }, [wl_id, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_testator`
      const data = {
        wcil_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfBeneficiaries({
          ...ListofBeneficiaries,
          data: ListofBeneficiaries?.data?.filter(each => each.wcil_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofBeneficiaries?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit_will_contact?id=${each.wcil_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.wcil_fname} ${each?.wcil_lname}`,
        relationship: each?.wcil_relitionship,       
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.wcil_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.wcil_fname} ${each?.wcil_lname}?`
                  ) &&
                  handleRemoveRole(each.wcil_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ListOfExistingWillAssets = ({ wl_id, activeTab }) => {
  const [ListofAssets, setListOfAssets] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_willasset_list?wl_id=${wl_id}&wal_uid=${userId}`

        const response = await fetch(url)
         
        const data = await response.json()
      
        setListOfAssets({ ...ListofAssets, data: data?.data || [] })        
     
      } catch (err) {}
    }

    fetchData()
  }, [wl_id, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_asset_will`
      const data = {
        wal_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfAssets({
          ...ListofAssets,
          data: ListofAssets?.data?.filter(each => each.wal_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name of Asset",
        field: "wal_name_of_asset",
        sort: "asc",
        width: 270,
      },
      {
        label: "Number of Sq Feet/Yards",
        field: "wal_no_yards",
        sort: "asc",
        width: 200,
      }, 
      {
        label: "Asset Value",
        field: "wal_asset_value",
        sort: "asc",
        width: 200,
      }, 
      {
        label: "% of Ownership",
        field: "wal_share_per",
        sort: "asc",
        width: 200,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofAssets?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit-owner?id=${each.wal_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        wal_name_of_asset: `${each?.wal_name_of_asset}`,        
        wal_no_yards: each?.wal_no_yards,
        wal_asset_value: each?.wal_asset_value,
        wal_share_per: each?.wal_share_per,       
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.wal_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.wal_name_of_asset} ?`
                  ) &&
                  handleRemoveRole(each.wal_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

{ /* 
export const ListofExistingDocuments = ({ wl_id, activeTab }) => {
  const [ListofDocuments, setListofDocuments] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/document_list?wl_id=${wl_id}&wdl_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()

        const decryptedData = data?.data?.map(each => {
          return {
            ...each,
            pdl_document_name: decryptData(each.pdl_document_name) || "",
            pdl_tyep_document: decryptData(each.pdl_tyep_document) || "",
            pol_relitionship: each.pdl_image || "",
          }
        })

        setListofDocuments({ ...ListofDocuments, data: decryptedData || [] })
      } catch (err) {}
    }

    fetchData()
  }, [wl_id, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document`
      const data = {
        wdl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListofDocuments({
          ...ListofDocuments,
          data: ListofDocuments?.data?.filter(each => each.wdl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name of the document",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Type of Document",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "Images",
        field: "Image",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofDocuments?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add-asset-wallet?id=${each.wdl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pdl_document_name}`,
        relationship: each?.pdl_tyep_document,
        Image: each?.pdl_image || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.wdl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pdl_document_name}?`
                  ) &&
                  handleRemoveRole(each.wdl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
*/}

